import axios from "axios";
import {
    CreateProtocolAction,
    ICreateProtocol,
    IDropdownOption,
    INote,
    IProtocolAction, IProtocolThumbResponse,
    OldKpProtocol,
    OldPpProtocol,
    Protocol
} from "../lib/types/types";
import {UtilService} from "./utilService";
import invoices from "../components/invoices/Invoices";
import protocolInvoiceNumber from "../components/protocols/ProtocolInvoiceNumber";

export class ProtocolService {
    service = 'protocols/';

    async getAllProtocols(periodStart: string, periodEnd: string, clientFirmId?: number) {
        return new Promise<Protocol[]>((resolve, reject) => {
            const body: {startDateTime: string, endDateTime: string, clientFirmId?: number} = {startDateTime: periodStart, endDateTime: periodEnd};
            if(clientFirmId) body.clientFirmId = clientFirmId;
            axios.post<Protocol[]>(this.service + "get", body)
                .then(resp => {
                    resolve(resp.data)
                }).catch()
        })
    }

    async getProtocolById(protocolId: number) {
        return new Promise<Protocol>((resolve, reject) => {
            axios.post<Protocol[]>(this.service + "get", {protocolId})
                .then(resp => {
                    resolve(resp.data[0])
                }).catch()
        })
    }

    async getProtocolNotes(protocolId: number) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.get<INote[]>(this.service + "notes/" + protocolId)
                .then(resp => {
                    if(resp) resolve(resp.data)
                }).catch(reject)
        })
    }

    async addNote(protocolId: number, note: string) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post<INote[]>(this.service + "notes" , {protocolId, note})
                .then(resp => {
                    if(resp) resolve(resp.data)
                }).catch(reject)
        })
    }

    async getAllOldKpProtocols() {
        return new Promise<OldKpProtocol[]>((resolve, reject) => {
            axios.get<OldKpProtocol[]>(this.service + "oldKpProtocols",)
                .then(resp => {
                    resolve(resp.data)
                }).catch()
        })
    }

    async getAllOldPpProtocols() {
        return new Promise<OldPpProtocol[]>((resolve, reject) => {
            axios.get<OldPpProtocol[]>(this.service + "oldPpProtocols",)
                .then(resp => {
                    resolve(resp.data)
                }).catch()
        })
    }
    async createProtocol(data: ICreateProtocol) {
        return new Promise<Protocol>((resolve, reject) => {
            axios.post<Protocol>(this.service + "add", data)
                .then(resp => {
                    if(resp) resolve(resp.data)
                }).catch(reject)
        })
    }

    async markProtocolAsChecked(protocolId: number) {
        return new Promise((resolve, reject) => {
            axios.post(this.service + "markAsChecked/" + protocolId)
                .then(resp => {
                    if(resp) resolve(true)
                }).catch(reject)
        })
    }

    async getProtocolActions() {
        return new Promise<IProtocolAction[]>((resolve, reject) => {
            axios.get<IProtocolAction[]>(this.service + "actions")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getProtocolActionOptions() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            this.getProtocolActions()
                .then(data => {
                    resolve(UtilService.generateDropdownOptionsNewFormatFromData(data, "protocolActionId", "protocolActionName"));
                }).catch(reject)
        })
    }

    async createProtocolAction(data: CreateProtocolAction) {
        return new Promise((resolve, reject) => {
            axios.post(this.service + "actions", data)
                .then(resp => {
                    resolve(true)
                }).catch(reject)
        })
    }

    async getProtocolThumbnails(protocolId: number) {
        return new Promise<IProtocolThumbResponse[]>((resolve, reject) => {
            axios.get<IProtocolThumbResponse[]>(this.service + protocolId + "/thumbs")
                .then(resp => {
                    resolve(resp.data)
                }).catch(reject)
        })
    }

    async downloadProtocolPhoto(protocolImageId: number) {
        return new Promise<BlobPart>((resolve, reject) => {
            axios.get<BlobPart>(this.service + protocolImageId + "/image", {responseType: "arraybuffer"})
                .then(resp => {
                    resolve(resp.data)
                }).catch(reject)
        })
    }

    async uploadFileForProtocol(protocolId: number, file: any) {
        let formData = new FormData();
        formData.append("file", file);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return new Promise<any>((resolve, reject) => {
            axios.post(this.service + protocolId + "/image/upload/false", formData, {headers})
                .then(resp => {
                    resolve(true)
                }).catch(reject)
        })
    }

    async deleteProtocolFile(protocolFileId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete<boolean>(this.service + protocolFileId + "/image")
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async switchProtocolInvoiceStatus(protocolId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + "switchInvoiceStatus/" + protocolId)
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async changeProtocolInvoiceNumber(protocolId: number, invoiceNumber: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + "changeProtocolInvoiceNUmber" , {protocolId, protocolInvoiceNumber: invoiceNumber})
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async downloadProtocol(protocolId: number) {
        return new Promise((resolve, reject) => {
            axios.get(this.service + "generatePdf/" + protocolId, {
                responseType: 'arraybuffer'})
                .then(response => resolve(response.data))
                .catch(reject);
        })
    }


}

