import {FormElementValues} from "@xal3xfx/react-utils";

const invoiceDetailsName: FormElementValues<'text'> = {
    type: "text",
    label: 'invoiceDetailsName',
    props: {},
}

const recieverName: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverName',
    props: {},
}

const recieverAddress: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverAddress',
    props: {},
}

const recieverIdentityCode: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIdentityCode',
    props: {},
}

const recieverIdentityCodeZDDS: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIdentityCodeZDDS',
    props: {},
}

const recieverMol: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverMol',
    props: {},
}

const recieverBank: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverBank',
    props: {},
}

const recieverBic: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverBic',
    props: {},
}

const recieverIban: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIban',
    props: {},
}

const providerName: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverName',
    props: {},
}

const providerAddress: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverAddress',
    props: {},
}

const providerIdentityCode: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIdentityCode',
    props: {},
}

const providerIdentityCodeZDDS: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIdentityCodeZDDS',
    props: {},
}

const providerMol: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverMol',
    props: {},
}

const providerBank: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverBank',
    props: {},
}

const providerBic: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverBic',
    props: {},
}

const providerIban: FormElementValues<'text'> = {
    type: "text",
    label: 'recieverIban',
    props: {},
}


export const formElements = {
    invoiceDetailsName, recieverName, providerName, recieverAddress, providerAddress, recieverIdentityCode, providerIdentityCode, recieverIdentityCodeZDDS, providerIdentityCodeZDDS, recieverMol, providerMol, recieverBank, providerBank, recieverBic, providerBic, recieverIban, providerIban
}
