import * as React from 'react';
import {useContext} from 'react';
import {useIntl} from "react-intl";
import {InternalTasks} from "./internalTasks/InternalTasks";
import {PermissionContext} from "../lib/context/PermissionsContext";
import {Card} from "primereact/card";

interface Props {
}

export const Home: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const {allowAction} = useContext(PermissionContext);

    return <>
        {allowAction(['InternalTasks', 'Employee']) &&
            <Card className={"border-round-3xl"}>
                <InternalTasks/>
            </Card>
        }
    </>
};
