import * as React from 'react';
import {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import CountUp from "react-countup";

interface Props {
    icon?: string;
    iconBackgroundColor?: string;
    header?: string | JSX.Element;
    content: number;
    contentColor?: string;
    footer?: string | JSX.Element;
    className? : string;
    additionalContent?: string;
    style?: React.CSSProperties
}

//TODO: Support JSX.Element for header/content/footer instead of string only.

export const InfoCard: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const [content, setContent] = useState(props.content);
    const [prevContent, setPrevContent] = useState(props.content);

    useEffect(() => {
        setPrevContent(content);
        setContent(props.content);
    }, [props.content]);

    return <>
        <div className={"grid " + props.className || ''} style={props.style}>
            <div className="col-12 md:col-6 lg:col-3 w-full">
                <div className="shadow-2 p-3 border-1 border-50 border-round"
                     style={{border: "1px solid #FAFAFA", borderRadius: "0.3rem", backgroundColor: "var(--secondary-bg-color)"}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block mb-3"
                                  style={{color: "var(--textColor)", fontWeight: "500"}}>{props.header}</span>
                            <div className="font-medium text-xl"
                                 style={{color: "var(--textColor)" || "#212121", fontSize: "1.5rem"}}><CountUp
                                end={content} start={prevContent} duration={2}/> {prevContent !== content && <i
                                style={prevContent < content ? {color: '#419544', fontSize: '1.5rem'} : {color: '#c61a54', fontSize: '1.25rem'}}
                                className={prevContent < content ? 'pi pi-angle-up' : 'pi pi-angle-down'}/>} {props.additionalContent}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center" style={{
                            width: '2.5rem',
                            height: '2.5rem',
                            backgroundColor: props.iconBackgroundColor || '#ffffff',
                            borderRadius: "0.3rem"
                        }}>
                            <i className={`${props.icon} text-xl`} style={{color: "#9E9E9E", fontSize: "1.25rem"}}/>
                        </div>
                    </div>
                    <span style={{color: "var(--textColor)"}}>{props.footer}</span>
                </div>
            </div>
        </div>
    </>
};
