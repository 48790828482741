import * as React from "react";
import {useIntl} from "react-intl";
import {InternalTaskDto} from "../../services/backend-api";
import {InternalTasksColumn} from "./InternalTasksColumn";
import styled from "styled-components";
import {useEffect} from "react";

interface Props {
    tasks: InternalTaskDto[];
    onTaskClicked: (task: InternalTaskDto) => void
}

const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  gap: 2rem;
  margin: 2.5rem 0;
`

export const InternalTasksGrid: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    const tasksByStatus : Record<string, InternalTaskDto[]> = (props.tasks).reduce((acc: any, el, index) => {
        if(!el.statusName)
            return acc;

        if(acc[el.statusName as string]){
            acc[el.statusName].push(el);
        }else{
            acc[el.statusName] = [el];
        }
        return acc;
    }, {});

    return <>
        <Wrapper>
            {Object.keys(tasksByStatus).map(el => <InternalTasksColumn onTaskClicked={props.onTaskClicked} key={el} status={el} tasks={tasksByStatus[el]} maxShownTasksCount={3} />)}
        </Wrapper>
    </>
};

