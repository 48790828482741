import {InputText} from "primereact/inputtext";
import * as React from "react";
import {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
//@ts-ignore
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import {useIntl} from "react-intl";
import {useToast} from "../../lib/hooks/useToast";
import {useFormik} from "formik";
import {ITariff} from "../../lib/types/types";
import {ClientFirmService} from "../../services/clientFirmService";
import {VehicleService} from "../../services/vehicleService";
import {useDialog} from "../../lib/hooks/useDialog";
import {Fieldset} from "primereact/fieldset";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {InputNumber} from "primereact/inputnumber";

type ICreateTariffFormData = Pick<ITariff, 'tarifId' | 'tarifName' | 'tarifTypeId' | 'tarifDescription' | 'januaryPrice' | 'februaryPrice' | 'marchPrice' | 'aprilPrice' | 'mayPrice' | 'junePrice' | 'julyPrice' | 'augustPrice' | 'septemberPrice' | 'octoberPrice' | 'novemberPrice' | 'decemberPrice'>

interface ICreateTariffFormDataErrors {
    tarifName?: string;
    tarifTypeId?: string;
    tarifDescription?: string;
    januaryPrice?: string;
    februaryPrice?: string;
    marchPrice?: string;
    aprilPrice?: string;
    mayPrice?: string;
    junePrice?: string;
    julyPrice?: string;
    augustPrice?: string;
    septemberPrice?: string;
    octoberPrice?: string;
    novemberPrice?: string;
    decemberPrice?: string;
}

interface ICreateTariff {
    januaryPrice: undefined | number;
    februaryPrice?: undefined | number;
    marchPrice?: undefined | number;
    aprilPrice?: undefined | number;
    mayPrice?: undefined | number;
    junePrice?: undefined | number;
    julyPrice?: undefined | number;
    augustPrice?: undefined | number;
    septemberPrice?: undefined | number;
    octoberPrice?: undefined | number;
    novemberPrice?: undefined | number;
    decemberPrice?: undefined | number;
    tarifName?: undefined | string;
    tarifTypeId?: undefined | number;
    tarifDescription?: undefined | string;

}

interface Props {
    edit: boolean;
    clientFirmId?: number;
    vehicleId?: number;
    selectedTariff?: ITariff;
    onSubmitCallback: () => void;
    tariffTemplates?: ITariff[];
}

export const CreateOrEditTariff: React.FC<Props> = (props) => {
    const { formatMessage: f } = useIntl();
    const { showToast } = useToast();
    const { hideDialog } = useDialog();

    const clientFirmService = new ClientFirmService();
    const vehicleService = new VehicleService();

    const [tariffTypeOptions, setTariffTypeOptions] = useState<IDropdownOption[]>([]);
    const [priceForAllMonths, setPriceForAllMonths] = useState(0);

    const initialValues: Partial<ICreateTariffFormData> = {
        tarifId: props.selectedTariff?.tarifId || 0,
        tarifName: props.selectedTariff?.tarifName || '',
        tarifTypeId: props.selectedTariff?.tarifType.tarifTypeId || 0,
        tarifDescription: props.selectedTariff?.tarifDescription || ' ',
        januaryPrice: props.selectedTariff?.januaryPrice || 0,
        februaryPrice: props.selectedTariff?.februaryPrice || 0,
        marchPrice: props.selectedTariff?.marchPrice || 0,
        aprilPrice: props.selectedTariff?.aprilPrice || 0,
        mayPrice: props.selectedTariff?.mayPrice || 0,
        junePrice: props.selectedTariff?.junePrice || 0,
        julyPrice: props.selectedTariff?.julyPrice || 0,
        augustPrice: props.selectedTariff?.augustPrice || 0,
        septemberPrice: props.selectedTariff?.septemberPrice || 0,
        octoberPrice: props.selectedTariff?.octoberPrice || 0,
        novemberPrice: props.selectedTariff?.novemberPrice || 0,
        decemberPrice: props.selectedTariff?.decemberPrice || 0,
    }

    const formik = useFormik({
        //@ts-ignore
        initialValues,
        validate: (data) => {
            let errors: ICreateTariffFormDataErrors = {};
            Object.keys(data).filter(key => key !== 'tarifId').forEach((key: any) => {
                // @ts-ignore
                if (data[key] === undefined || data[key] === null) {
                    // @ts-ignore
                    errors[key] = "*Задължително поле";
                }
            })

            return errors;
        },
        onSubmit: (values: ICreateTariffFormData) => {
            const newTariff: any = { ...values };
            newTariff.tarifType = {
                tarifTypeId: values.tarifTypeId,
                tarifTypeName: tariffTypeOptions.find(el => el.id === values.tarifTypeId)!.description
            }
            if (!props.vehicleId && props.clientFirmId) {
                clientFirmService.createTariff(props.clientFirmId, newTariff).then(res => {
                    showToast("success", f({ id: "done" }), props.edit ? f({ id: "tariffUpdated" }) : f({ id: "tariffCreated" }));
                    props.onSubmitCallback();
                    hideDialog();
                }).catch(err => {

                });
            } else if (props.vehicleId) {
                vehicleService.createTariff(props.vehicleId, newTariff).then(res => {
                    showToast("success", f({ id: "done" }), props.edit ? f({ id: "tariffUpdated" }) : f({ id: "tariffCreated" }));
                    props.onSubmitCallback();
                    hideDialog();
                }).catch(err => {

                });
            }

        },
    });

    const isFormFieldValid = (name: keyof ICreateTariffFormData): boolean => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name: keyof ICreateTariffFormDataErrors) => {
        //@ts-ignore
        return isFormFieldValid(name) &&
            <small className="p-error" style={{ float: "left" }}>{formik.errors[name]}</small>;
    };

    const handleModelChange = (e: any) => {
        formik.handleChange(e);
    }

    const getAllTariffTypes = () => {
        clientFirmService.getAllTariffTypes()
            .then(setTariffTypeOptions);
    }

    const addPriceForAllMonths = () => {
        Object.keys(initialValues).filter(key => key.includes("Price")).forEach(key => formik.setFieldValue(key, priceForAllMonths));
    }

    useEffect(() => {
        getAllTariffTypes();
    }, []);


    const generateTemplateButtons = () => {
        if(props.tariffTemplates) {
            return props.tariffTemplates.map(template => {
                const {januaryPrice, februaryPrice, marchPrice, aprilPrice, mayPrice, junePrice, julyPrice, augustPrice, septemberPrice, octoberPrice, novemberPrice, decemberPrice, tarifName, tarifDescription} = template;
                return (
                    <div className="col-6">
                        <Button className={"w-full"} type="button" label={template.tarifName} onClick={loadTemplate({januaryPrice, februaryPrice, marchPrice, aprilPrice, mayPrice, junePrice, julyPrice, augustPrice, septemberPrice, octoberPrice, novemberPrice, decemberPrice, tarifName, tarifTypeId: template.tarifType.tarifTypeId, tarifDescription})}/>
                    </div>
                )
            })
        }
    }

    const loadTemplate = (data: ICreateTariff) => {
        return () => {
            Object.keys(data).forEach((key:string) => {
                //@ts-ignore
                formik.setFieldValue(key, data[key]);
            })
            // formik.values = {...formik.values, ...data};
        }
    }

    return <>
        <form onSubmit={formik.handleSubmit}>
            <div className={"grid w-full"}>
                <div className="p-field col-4">
                    <InputNumber name="priceForAllMonths" autoFocus value={priceForAllMonths} className={"w-full"} maxFractionDigits={2}
                        onChange={(e: any) => setPriceForAllMonths(+e.value)} />
                </div>
                <div className="p-field col-8">
                    <Button label={f({ id: 'addPriceForAllMonths' })} onClick={addPriceForAllMonths} type={'button'} />
                </div>
                <div className="p-field col-4 mt-3">
                    <span className="p-float-label">
                        <InputText name="tarifName" autoFocus
                            value={formik.values.tarifName} onChange={formik.handleChange}
                            className={classNames({ 'p-error': isFormFieldValid('tarifName') }) + " w-full"} />
                        <label htmlFor="tarifName">{f({ id: "tarifName" })}</label>
                    </span>
                    {getFormErrorMessage('tarifName')}
                </div>
                <div className="p-field col-4 mt-3">
                    <span className="p-float-label">
                        <Dropdown id="tarifTypeId" name="tarifTypeId" value={formik.values.tarifTypeId} optionLabel={'description'} optionValue={'id'}
                            onChange={handleModelChange} options={tariffTypeOptions}
                            className={classNames({ 'p-error': isFormFieldValid('tarifTypeId') }) + " w-full"} />
                        <label htmlFor="tarifTypeId">{f({ id: "tarifTypeId" })}</label>
                    </span>
                    {getFormErrorMessage('tarifTypeId')}
                </div>
                <div className="p-field col-4 mt-3">
                    <span className="p-float-label">
                        <InputText name="tarifDescription"
                            value={formik.values.tarifDescription} onChange={formik.handleChange}
                            className={classNames({ 'p-error': isFormFieldValid('tarifDescription') }) + " w-full"} />
                        <label htmlFor="tarifDescription">{f({ id: "tarifDescription" })}</label>
                    </span>
                    {getFormErrorMessage('tarifDescription')}
                </div>

                {
                    //@ts-ignore
                    Object.keys(initialValues).filter(el => el.includes('Price')).map((key: keyof ICreateTariffFormData, index) => (
                        <div key={index} className="p-field col-4 mt-3">
                            <span className="p-float-label">
                                {/*@ts-ignore*/}
                                <InputText name={key} value={formik.values[key]}
                                    onChange={e => formik.setFieldValue(key, +e.target.value)}
                                    className={classNames({ 'p-error': isFormFieldValid(key) }) + " w-full"} />
                                <label htmlFor={key}>{f({ id: key.replace("Price", '') })}</label>
                            </span>
                            { //@ts-ignore
                                getFormErrorMessage(key)}
                        </div>
                    ))
                }

                <div className={"grid justify-content-between col-12"}>
                    <div className={"col-6"}>
                        <Button
                            className={'mt-4 w-full'}
                            label={props.selectedTariff ? f({ id: "edit" }) : f({ id: 'save' })}
                            type={"submit"}
                            iconPos="right"
                            icon={'fas fa-disk'}
                        />
                    </div>

                    <div className={"col-6"}>
                        <Button
                            className={'mt-4 w-full'}
                            label={f({ id: "clear" })}
                            type={"button"}
                            iconPos="right"
                            icon="fas fa-times"
                            onClick={() => formik.resetForm()}
                        />
                    </div>
                </div>

                {props.tariffTemplates ?
                    <div className={'col-12'}>
                        <div className="col-12">
                            <Fieldset legend={f({id: "templates"})}>
                                <div className="grid justify-content-center w-full">
                                    {generateTemplateButtons()}
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                : null }
            </div>
        </form>
    </>
}
