import {FormElementValues} from "@xal3xfx/react-utils";

const clientFirmId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'clientFirm',
    props: {},
    options: []
}

const startDate: FormElementValues<'calendar'> = {
    type: "calendar",
    label: 'startDate',
    props: {},
}


export const formElements = {
    clientFirmId, startDate
}
