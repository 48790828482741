import React, {createContext, useEffect, useState} from 'react';
import {useIntl} from "react-intl";

export const GlobalEventListenerContext = createContext<any>(null);

export const GlobalEventListenerContextProvider = (props: any) => {
    const {formatMessage : f} = useIntl();
    const [listeners, setListeners] = useState<Record<string, (e: any) => void>>({});

    const subscribe = (componentName : string, listener : (e:any) => void) => {
        setListeners((prevState : any) => {return {...prevState, [componentName] : listener}})
    }

    const unsubscribe = (componentName : string) => {
        setListeners((prevState ) => {
            const newListeners = {...prevState};
            delete newListeners[componentName];
            return newListeners
        })
    }

    useEffect(() => {
        const keyPressListener = (e : any) => {
            // console.log('GlobalEventListener', listeners);
            Object.values(listeners).forEach((el : any) => el(e));
        };
        document.body.addEventListener("keydown", keyPressListener);

        return () => {
            document.body.removeEventListener("keydown", keyPressListener);
        }
    }, [listeners]);

    return <>
        <GlobalEventListenerContext.Provider value={{subscribe, unsubscribe}}>
            {props.children}
        </GlobalEventListenerContext.Provider>
    </>
};
