import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {useDynamicForm} from "../../../lib/hooks/useDynamicForm";
import {CreateProtocolAction} from "../../../lib/types/types";
import {validationSchema} from "./validation";
import {formElements} from "./protocol-action-form";
import {ProtocolService} from "../../../services/protocolService";
import {useToast} from "../../../lib/hooks/useToast";
import {useDialog} from "../../../lib/hooks/useDialog";
import {DeviceService} from "../../../services/deviceService";

interface Props {

}

const initialValues: CreateProtocolAction = {
    deviceTypeId: 0,
    protocolActionName: ""
}

const ProtocolActionForm : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const dynamicForm = useDynamicForm();
    const protocolService = new ProtocolService();
    const deviceService = new DeviceService();
    const {showToast} = useToast();
    const {hideDialog} = useDialog();

    const [formElementsState, setFormElementsState] = useState({...formElements});

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
            const formElementsTemp = {...formElementsState};
            deviceService.getDeviceTypesDropdown()
                .then(resp => {
                    formElementsTemp.deviceTypeId.options = resp;
                    setFormElementsState(formElementsTemp);
                }).catch(console.error)
        }
    }, []);

    const createHandler = (data: CreateProtocolAction) => {
        return new Promise<boolean>((resolve, reject) => {
            protocolService.createProtocolAction(data).then(() => {
                resolve(true);
                showToast("success", f({ id: "done" }), f({ id: "vehicleManufacturerCreated" }));
                hideDialog();
            }).catch(reject);
        });
    }

    const getForm = dynamicForm({
        initialValues: initialValues,
        fieldOrder: ['protocolActionName', 'deviceTypeId'],
        onFormReset: () => 0,
        onCreate: createHandler,
        onUpdate: () => Promise.resolve(true),
        isUpdate: false,
        onCancelUpdate: () => 0,
        validationSchema: validationSchema,
        formElements: formElementsState,
        className: "col-12 md:col-6"
    })

    return <>
        {getForm}
    </>
};

export default ProtocolActionForm
