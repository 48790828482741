import * as React from "react";
import {useIntl} from "react-intl";
import {useDynamicForm} from "../../../lib/hooks/useDynamicForm";
import {formElements} from './client-firm-mail-form'
import {validationSchema} from './validation'
import {IEmailContact, IFirmEmail} from "../../../lib/types/types";
import {useDialog} from "../../../lib/hooks/useDialog";
import {ClientFirmService} from "../../../services/clientFirmService";
import {DynamicForm} from "@xal3xfx/react-utils";

interface Props {
    firmId: number;
    update: boolean;
    createdCb?: (createdEmail: IFirmEmail) => void;
    oneRow?: boolean;
}

const initialValue: IEmailContact = {
    email: "",
    emailOwner: ""
}


export const ClientFirmEmail: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const {hideDialog} = useDialog();
    const clientFirmService = new ClientFirmService();

    const dynamicForm = useDynamicForm();


    const createHandler = (data: IEmailContact) => {
        return new Promise<boolean>((resolve, reject) => {
            clientFirmService.createEmail(props.firmId, data).then(resp => {
                resolve(true);
                if (props.createdCb) props.createdCb(resp[resp.length - 1]);
                else hideDialog();
            }).catch(reject);
        });
    }

    const updateHandler = (data: IEmailContact) => {
        return new Promise<boolean>((resolve, reject) => {
            clientFirmService.updateEmail(props.firmId, data).then(data => {
                resolve(true);
                hideDialog();
            }).catch(reject);
        });
    }

    const getForm = () => {

        return dynamicForm({
            className: props.oneRow ? "col-12" : "col-12 md:col-6",
            fieldOrder: ['emailOwner', 'email'],
            initialValues: initialValue,
            onFormReset: () => 0,
            onCreate: createHandler,
            onUpdate: updateHandler,
            isUpdate: props.update,
            onCancelUpdate: () => 0,
            validationSchema: validationSchema,
            formElements: formElements
        })
    }


    return <>
        {/*{getForm()}*/}
        <DynamicForm formElements={formElements} isUpdate={props.update} onCreate={createHandler} validationSchema={validationSchema}
                     onUpdate={updateHandler} initialValues={initialValue} fieldOrder={['emailOwner', 'email']}
                     rowClassName={props.oneRow ? "col-12" : "col-12 md:col-6"} formButtonsClassName={"md:col-6"}
                     onCancelUpdate={() => 0}/>
    </>
}
