import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from "react-intl";
import {ReactiveTable} from "react-ive-tables";
import {InvoiceService} from "../../services/invoiceService";
import {ExtendedMenuOption, IInvoiceTable} from "../../lib/types/types"
import {OverlayPanel} from "primereact/overlaypanel";
import {PeriodFilter} from "../../lib/templates/PeriodFilter";
import {Button} from "primereact/button";
import {useToast} from "../../lib/hooks/useToast";
import moment from "moment";
import {useDialog} from "../../lib/hooks/useDialog";
import {useLocation} from "react-router-dom";
import {CreateInvoice} from "./createInvoice/CreateInvocie";
import {SpeedDial} from "primereact/speeddial";
import {Tooltip} from "primereact/tooltip";
import {PermissionContext} from "../../lib/context/PermissionsContext";
import Notes from "../../lib/templates/Notes";
import PdfPreview from "../../lib/templates/PdfPreview";
import {UtilService} from "../../services/utilService";
import CreateCreditAndDebitNote from "./creditAndDebitNote/CreateCreditAndDebitNote";
import styled from "styled-components";
import {Dropdown} from "primereact/dropdown";
import { IDropdownOption } from '@xal3xfx/react-utils/dist/util-service';

interface Props {
    clientFirmId?: number
}

interface LocationProps {
    clientFirmId: number
}

const InvoiceTypeWrapper = styled.div<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
    padding: 0.5rem;
    border-radius: 2rem;
    color: white
`

const Invoices: React.FC<Props> = (props) => {
    const [tableData, setTableData] = useState<IInvoiceTable[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceTable>();
    const [dtRefresher, setDtRefresher] = useState(new Date().getTime());
    const [operationsTooltip, setOperationsTooltip] = useState<any>();
    const [selectedOperation, setSelectedOperation] = useState<'delete' | 'addNote' | 'print' | 'markAsPaid' | 'creditNote' | 'debitNote'>();
    const [filteredRowsCount, setFilteredRowsCount] = useState(tableData?.length);

    const {showToast} = useToast();
    const {formatMessage: f} = useIntl();
    const {showDialog, isShown: isSidebarShown} = useDialog();
    const {getMenuOptionsForUser} = useContext(PermissionContext);
    const invoiceService = new InvoiceService();
    const location = useLocation();
    const op = useRef<OverlayPanel>(null);
    const clientFirmIdRef = useRef<number | undefined>(undefined);

    useEffect(() => {
        loadTable();
    }, [dtRefresher])

    useEffect(() => {
        switch (selectedOperation) {
            case 'delete': {
                // deleteClientFirm(undefined);
                break;
            }
            case 'print': {
                downloadInvoice();
                break;
            }
            case 'addNote': {
                showInvoiceNotes();
                break;
            }
            case 'markAsPaid': {
                markAsPaid();
                break;
            }
            case 'creditNote': {
                showCreditNote();
                break;
            }
            case 'debitNote': {
                showDebitNote();
                break;
            }

        }
    }, [selectedOperation]);

    useEffect(() => {
        if (!isSidebarShown) {
            setSelectedOperation(undefined);
            setSelectedInvoice(undefined);
        }
    }, [isSidebarShown]);

    useEffect(() => {
        const state = location.state as LocationProps;
        if (state) {
            clientFirmIdRef.current = state.clientFirmId;
            setDtRefresher(new Date().getTime());
        }
    }, [location])

    useEffect(() => {
        if (!props.clientFirmId) return;

        clientFirmIdRef.current = props.clientFirmId;
        setDtRefresher(new Date().getTime());

    }, [props.clientFirmId])

    const loadTable = () => {
        if (clientFirmIdRef.current) {
            invoiceService.getClientFirmInvoices(clientFirmIdRef.current, true)
                .then(setTableData)
                .catch();
        } else {
            invoiceService.getInvoices(true)
                .then(data => {
                    if (!clientFirmIdRef.current) {
                        setTableData(data);
                    }
                })
                .catch()
        }
    }

    const onDateChange = (dates: Date[]) => {
        if (!dates[0] || !dates[1] || clientFirmIdRef.current) return;
        invoiceService.getInvoicesForPeriod(moment(dates[0]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), moment(dates[1]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), true)
            .then(setTableData)
            .catch()
    }

    const markAsPaid = () => {
        if (!selectedInvoice) return;
        invoiceService.pay(!selectedInvoice.paid, selectedInvoice.invoiceId)
            .then((result: boolean) => {
                if (result) {
                    loadTable();
                    showToast("success", f({id: "done"}), f({id: selectedInvoice.paid ? "invoiceUnpaidSuccessfully" : "invoicePaidSuccessfully"}));
                }
                clearForOperations();
            })
            .catch(err => {
                showToast("error", f({id: "error"}), f({id: "invoicePaidFail"}));
                clearForOperations()
            });
    }

    // const deleteInvoice = (rowData: IInvoiceTable) => {
    //
    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //     showConfirmDialog({body: f({id: 'confirmDeleteInvoice'}, {invoiceNumber: rowData?.invoiceNumber})}).then(answer => {
    //         if (answer) {
    //             invoiceService.deleteInvoice(rowData!.invoiceId)
    //                 .then(() => {
    //                     loadTable()
    //                     showToast("success", f({id: "done"}), f({id: "deleteInvoiceSuccessfully"}));
    //                 })
    //                 .catch(err => {
    //                     showToast("error", f({id: "error"}), f({id: "deleteInvoiceFail"}))
    //                 })
    //         }
    //     })
    // }

    const showCreditNote = () => {
        if (!selectedInvoice || !selectedInvoice.invoiceId) return;
        showDialog({
            newContent: <CreateCreditAndDebitNote invoiceId={selectedInvoice.invoiceId} isCredit={true}/>,
            heading: f({id: 'creditNoteForInvoice'}, {invoiceNumber: selectedInvoice.invoiceNumber}),
            onHideCallback: loadTable
        });
    }

    const showDebitNote = () => {
        if (!selectedInvoice || !selectedInvoice.invoiceId) return;
        showDialog({
            newContent: <CreateCreditAndDebitNote invoiceId={selectedInvoice.invoiceId} isCredit={false}/>,
            heading: f({id: 'debitNoteForInvoice'}, {invoiceNumber: selectedInvoice.invoiceNumber}),
            onHideCallback: loadTable
        });
    }

    const generatePaidColumnTemplate = (rowData: any) => {
        let className = rowData.paid ? 'fas fa-check-circle fa-2x' : 'fas fa-times-circle fa-2x';
        let color = rowData.paid ? '#46b04a' : '#fc0303';

        return <button style={{color, backgroundColor: "transparent", border: "0px"}}>
            <i className={className}></i>
        </button>
    }

    const generateForMonth = async () => {
        showDialog({
            newContent: <CreateInvoice/>,
            heading: f({id: 'generateInvoiceForm'}),
            onHideCallback: loadTable
        });
    }

    const showInvoiceNotes = async () => {
        if (!selectedInvoice?.invoiceId) return;
        const invoiceNotes = await invoiceService.getInvoiceNotes(selectedInvoice?.invoiceId)
        showDialog({
            newContent: <Notes id={selectedInvoice?.invoiceId!} notes={invoiceNotes}
                               handleCreate={(id: number, note: string) => invoiceService.addInvoiceNotes(id, note)}/>,
            heading: f({id: "notes"}),
            onHideCallback: () => setSelectedOperation(undefined)
        })
    }

    const getMenuModel = (rowData: IInvoiceTable) => {
        const menuItems: ExtendedMenuOption[] = [
            {
                label: f({id: "addNote"}),
                icon: 'fas fa-sticky-note',
                command: () => setSelectedOperation("addNote"),
            },
            {
                label: f({id: rowData.paid ? "markAsUnpaid" : "markAsPaid"}),
                icon: rowData.paid ? 'pi pi-times-circle' : "pi pi-check-square",
                command: () => setSelectedOperation("markAsPaid"),
                key: ['InvoiceMarkAsPaid']
            },
            {
                label: f({id: "download"}),
                icon: 'pi pi-download',
                command: () => setSelectedOperation("print"),
                key: ['InvoiceDownload']
            }
            // {
            //     label: f({id: "delete"}),
            //     icon: 'pi pi-trash',
            //     command: () => setSelectedOperation("delete"),
            //     disabled: true
            // },

        ]
        if (rowData.invoiceType === 0) {
            const creditNote: ExtendedMenuOption = {
                label: f({id: "creditNote"}),
                icon: 'fal fa-circle-minus',
                command: () => setSelectedOperation("creditNote")
            }
            const debitNote: ExtendedMenuOption = {
                label: f({id: "debitNote"}),
                icon: 'fal fa-circle-plus',
                command: () => setSelectedOperation("debitNote")
            }
            menuItems.push(creditNote);
            menuItems.push(debitNote);
        }
        return getMenuOptionsForUser(menuItems);
    }

    const clearForOperations = () => {
        setSelectedInvoice(undefined);
        setSelectedOperation(undefined);
    }

    const downloadInvoice = () => {
        invoiceService.downloadInvoice(selectedInvoice!.invoiceId)
            .then(data => {
                const blob = UtilService.arrayBufferToBlob(data as ArrayBuffer);
                const url = URL.createObjectURL(blob);

                showDialog({
                    newContent: <PdfPreview pdfString={url || ""}/>,
                    heading: f({id: "invoicePreview"}),
                    style: {maxWidth: "80vw", minWidth: "55%", minHeight: "95%"},
                    onHideCallback: () => setSelectedOperation(undefined)
                })

            })
            .catch()
    }


    const formatDate = (date: string) => {
        return moment(date).format("YYYY-MM-DD hh:mm:ss");
    }

    const getColumnTemplate = () => {
        return {
            operations: (rowData: IInvoiceTable) => <div className={'speeddial-operations'}>
                <SpeedDial className={'speeddial-right'} model={getMenuModel(rowData)} direction="left" onClick={() => {
                    setSelectedInvoice(rowData)
                    setOperationsTooltip(<Tooltip key={rowData.invoiceId}
                                                  target={'.speeddial-operations .speeddial-right .p-speeddial-action'}
                                                  position={'top'}/>)
                }}/>
            </div>,
            // delete: (rowData: IInvoiceTable) => {
            //     return <Button icon={"pi pi-trash"} className="p-button-danger p-button-rounded"
            //                    style={{backgroundColor: "red"}} onClick={() => deleteInvoice(rowData)}/>
            // },
            paid: generatePaidColumnTemplate,
            invoiceDate: (rowData: IInvoiceTable) => formatDate(rowData.invoiceDate),
            invoiceStartPeriod: (rowData: IInvoiceTable) => formatDate(rowData.invoiceStartPeriod),
            invoiceEndPeriod: (rowData: IInvoiceTable) => formatDate(rowData.invoiceEndPeriod),
            dueDate: (rowData: IInvoiceTable) => formatDate(rowData.dueDate),
            invoiceType: getInvoiceTypeTemplate
        }
    }

    const getInvoiceTypeTemplate = (rowData: IInvoiceTable) => {
        let bgColor = "";
        let text = ""
        switch (rowData.invoiceType) {
            case 0:
                bgColor = "gray";
                text = f({id: "invoice"});
                break;
            case 1:
                bgColor = "#ff6363";
                text = f({id: "creditNote"})
                break;
            case 2:
                bgColor = "#0000ff8a";
                text = f({id: "debitNote"})
                break;
        }
        return <InvoiceTypeWrapper bgColor={bgColor}>
            {text}
        </InvoiceTypeWrapper>
    }

    const getInvoiceTypeTemplateDropdown = (option: IDropdownOption) => {
        if(!option) return f({id: "choose"});
        let bgColor = "";
        let text = ""
        switch (option.id) {
            case 0:
                bgColor = "gray";
                text = f({id: "invoice"});
                break;
            case 1:
                bgColor = "#ff6363";
                text = f({id: "creditNote"})
                break;
            case 2:
                bgColor = "#0000ff8a";
                text = f({id: "debitNote"})
                break;
        }
        return <InvoiceTypeWrapper bgColor={bgColor}>
            {text}
        </InvoiceTypeWrapper>
    }

    const footerTemplate = () => {
        return <div style={{textAlign: "right"}}>
            <p>{f({id: "filteredRecords"}) + filteredRowsCount}</p>
            <p>{f({id: "totalRecords"}) + tableData.length}</p>
        </div>
    }

    const onFilterCb = (filteredData: IInvoiceTable[]) => {
        setFilteredRowsCount(filteredData.length);
    }

    const invoiceTypeOptions = [
        {
            id: 0,
            key: 0,
            description: f({id: "invoice"})
        },
        {
            id: 1,
            key: 1,
            description: f({id: "creditNote"})
        },
        {
            id: 2,
            key: 2,
            description: f({id: "debitNote"})
        },

    ]

    const getSpecialFilters = () => {
        return {
            invoiceType: (options: any) => <Dropdown showClear value={options.value} itemTemplate={getInvoiceTypeTemplateDropdown}
                                                     valueTemplate={getInvoiceTypeTemplateDropdown}
                                                     optionValue={"id"} optionLabel={"description"}
                                                     options={invoiceTypeOptions}
                                                     placeholder={f({id: 'choose'})}
                                                     onChange={(e) => options.filterApplyCallback(e.value)}
                                                     style={{textAlign: "left"}}/>
        }
    }

    // const exportExcel = () => {
    //     import('xlsx').then((xlsx) => {
    //         const worksheet = xlsx.utils.json_to_sheet(tableData);
    //         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, {
    //             bookType: 'xlsx',
    //             type: 'array'
    //         });
    //
    //         saveAsExcelFile(excelBuffer, 'invoices');
    //     });
    // };
    //
    // const saveAsExcelFile = (buffer: any, fileName: any) => {
    //     import('file-saver').then((module) => {
    //         if (module && module.default) {
    //             let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //             let EXCEL_EXTENSION = '.xlsx';
    //             const data = new Blob([buffer], {
    //                 type: EXCEL_TYPE
    //             });
    //
    //             module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //         }
    //     });
    // };


    return <>
        {operationsTooltip}
        <h1 className={"text-center"}>{f({id: "invoices"})}</h1>

        <div className="p-datatable-wrapper mt-3 p-shadow-4" style={{borderRadius: '1rem'}}>
            <Button
                type="button"
                icon="pi pi-caret-down"
                label={f({id: "filterPeriod"})}
                onClick={(e) => op.current?.toggle(e)}
                aria-haspopup
                aria-controls="overlay_panel"
                className="select-product-button"
            />

            <Button
                style={{marginLeft: "1rem"}}
                type="button"
                icon="pi pi-times"
                label={f({id: "clearFilters"})}
                onClick={loadTable}
                className={"p-button-warning"}
            />

            <Button
                style={{marginLeft: "1rem"}}
                type="button"
                icon="pi pi-plus"
                label={f({id: "generateForMonth"})}
                onClick={generateForMonth}
            />

            {/*<Button*/}
            {/*    style={{marginLeft: "1rem"}}*/}
            {/*    type="button"*/}
            {/*    icon="pi pi-xlsx"*/}
            {/*    label={f({id: "exportExcel"})}*/}
            {/*    onClick={exportExcel}*/}
            {/*/>*/}

            <OverlayPanel
                ref={op}
                showCloseIcon
                id="overlay_panel"
                className="overlaypanel-demo"
            >
                <PeriodFilter onDateChange={onDateChange}/>
            </OverlayPanel>


            <ReactiveTable
                data={tableData}
                columnOrder={[
                    "invoiceNumber",
                    "invoiceDate",
                    "invoiceStartPeriod",
                    "invoiceEndPeriod",
                    "clientFirm",
                    "receiver",
                    "provider",
                    "totalCost",
                    "dueDate",
                    "createdBy",
                    "paid",
                    "invoiceType",
                    "operations"
                ]}
                showFilters={true}
                setSelected={(e) => {
                    if (e) setSelectedInvoice(e);
                }}
                columnTemplate={getColumnTemplate()}
                selectionMode={"single"}
                selectionKey={'invoiceNumber'}
                onFilterCb={onFilterCb}
                doubleClick={downloadInvoice}
                showSkeleton={!clientFirmIdRef.current}
                ignoreFilters={["operations"]}
                columnStyle={{operations: {body: {}, header: {width: '100px'}}}}
                frozenColumns={['operations']}
                specialFilters={getSpecialFilters()}
                footerTemplate={footerTemplate}
            />
        </div>
    </>
}

export default Invoices;
