import * as yup from 'yup';
import {ICreateInvoiceGroup} from "../../../lib/types/types";


export const validationSchema: yup.ObjectSchema<Omit<ICreateInvoiceGroup, 'clientFirmId' | 'vehicleIds' | 'invoiceGroupId' | 'emailsToSendIds'>> = yup.object({
    invoiceCyrcle: yup.number().required(),
    invoiceDetailsId: yup.number().required(),
    invoiceGroupDurationInMonths: yup.number().min(1).max(12).required(),
    invoiceGroupName: yup.string().required()
})
