import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import FirmInvoiceGroups from "./FirmInvoiceGroups";
import {Button} from "primereact/button";
import {InvoiceGroup, IVehicle} from "../../../../lib/types/types";
import {Message} from "primereact/message";
import {InvoiceService} from "../../../../services/invoiceService";

interface Props {
    clientFirmId: number;
    selectedVehicles: IVehicle[];
    onSave?: () => void
}

const AssignVehiclesToGroup : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const invoiceService = new InvoiceService();

    const [selectedInvoiceGroup, setSelectedInvoiceGroup] = useState<InvoiceGroup>();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleSave = () => {
        if(!selectedInvoiceGroup || props.selectedVehicles.length === 0) return;
        invoiceService.addVehiclesToInvoiceGroup({invoiceGroupId: selectedInvoiceGroup.invoiceGroupId, vehicleIds: props.selectedVehicles.map(el => el.vehicleId)})
            .then(resp => {
                if(props.onSave) props.onSave();
            })
            .catch(console.error)
    }

    return <>
        <Message text={f({id: "selectedVehicles"}, {lpns: props.selectedVehicles.map(el => el.vehicleLpn).join(", ")})} />
        <h3>{f({id: "selectInvoiceGroup"})}</h3>
        <FirmInvoiceGroups clientFirmId={props.clientFirmId} onSelect={setSelectedInvoiceGroup} />
        <div className={"w-full flex justify-content-center"}>
            <Button className={"w-20rem mt-3"} disabled={!selectedInvoiceGroup} label={f({id: "save"})} onClick={handleSave}/>
        </div>
    </>
};

export default AssignVehiclesToGroup
