import {UtilService as NewUtilService} from "@xal3xfx/react-utils";
import axios from "axios";
import {
    IAccountReports,
    IAddVehicleToAccount,
    IClientFirm,
    ICreateGpsAccount,
    ICreateGpsFirm,
    IDataTrafficResponse,
    IDropdownOption,
    IGPSAccount,
    IGpsAccountSummary,
    IGpsProgramLog,
    INote,
    ITrafficRow,
    IVehicle, IVehicleData,
    IVehicleDataParsed,
    IVehicleDataResponse, MultipleVehicleCopyToAccountResponse,
    RemoteTokenResponse, TcpProxyEntry
} from "../lib/types/types";
import {AllCarData, GPSFirm} from "./backend-api";
import moment from "moment";

export class GpsProgramService {
    service = "gpsprogram/"

    async getAllGPSFirmsDropdown() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IClientFirm[]>(this.service + 'firms')
                .then(response => {
                    resolve(NewUtilService.generateDropdownOptionsFromData<GPSFirm>(response.data, 'firmname', 'firmname'));
                })
                .catch(reject)
        })
    }

    async getAllGPSAccountsDropdown() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IGPSAccount[]>(this.service + 'firms/accounts')
                .then(response => {
                    //@ts-ignore
                    console.log(NewUtilService.generateDropdownOptionsFromData<IGPSAccount>(response.data.flatMap(el => el.gpsAccounts), 'username', 'username'));
                    //@ts-ignore
                    resolve(NewUtilService.generateDropdownOptionsFromData<IGPSAccount>(response.data.flatMap(el => el.gpsAccounts), 'username', 'username'));
                })
                .catch(reject)
        })
    }

    async getAllGPSFirmsAccountsDropdown(firmName: string) {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IGPSAccount[]>(`${this.service}firms/${firmName}/accounts`)
                .then(response => {
                    resolve(NewUtilService.generateDropdownOptionsFromData<IGPSAccount>(response.data, 'username', 'username'));
                })
                .catch(reject)
        })
    }

    async getAllGPSFirmsAccounts(firmName: string) {
        return new Promise<IGpsAccountSummary[]>((resolve, reject) => {
            axios.get<IGPSAccount[]>(`${this.service}firms/${firmName}/accounts`)
                .then(response => {
                    resolve(response.data.map(el => {
                        return {
                            firmName: el.firmname || "",
                            userName: el.username || "",
                            paidToDate: el.paidtodate || new Date(),
                            master_account: el.master_account,
                            freeze: el.freeze
                        }
                    }));
                })
                .catch(reject)
        })
    }

    async getUnAssociatedVehicles(refresh?: boolean) {
        const appendRefresh = refresh ? "/true" : "";
        const result = await axios.get<AllCarData[]>(this.service + 'getallcardata/100000' + appendRefresh);
        return result.data;
    }

    async removeVehicleFromAllAccounts(vehicleTable: string, removeFromFirm: boolean) {
        return new Promise<IVehicle>((resolve, reject) => {
            axios.delete(this.service + "vehicles/removeFromAllAccounts", {
                data: {
                    vehicleId: vehicleTable,
                    removeFromGpsFirm: removeFromFirm
                }
            }).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }


    async generateTable(imei: number) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post(this.service + 'carregister', [imei]).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async createGpsFirm(data: ICreateGpsFirm) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + 'firms', data).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async createGpsAccount(data: ICreateGpsAccount) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + 'accounts', data).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async unfreezeAccount(username: string, paidToDate: Date) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + `accounts/${username}/unfreeze`, paidToDate).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async freezeAccount(username: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + `accounts/${username}/freeze`).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async toggleMasterAccount(username: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + `accounts/${username}/master`).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async generateGpsProgramExport(firmName: string) {
        return new Promise<BlobPart>((resolve, reject) => {
            axios.get<BlobPart>(this.service + "export/account/" + firmName, {responseType: "arraybuffer"})
                .then(resp => resolve(resp.data));
        })
    }

    async generateAdminPassword(masterPassword: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(`${this.service}masterPassword`, masterPassword, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.data) resolve(true);
                    reject(false);
                })
                .catch(reject)
        })
    }

    async getDeviceData(periodStart: string, periodEnd: string) {
        return new Promise<string>((resolve, reject) => {
            axios.get<IDataTrafficResponse>(this.service + "deviceData", {
                params: {
                    startDateTime: periodStart,
                    endDateTime: periodEnd
                }, headers: {skipPreloader: true}
            })
                .then(resp => resolve(resp.data.megaBytes));
        })
    }

    async getDeviceDataDetail(periodStart: string, periodEnd: string, vehicleId?: string) {
        return new Promise<ITrafficRow[]>((resolve, reject) => {
            let body: { startDateTime: string, endDateTime: string, vehicleId?: string } = {
                startDateTime: periodStart,
                endDateTime: periodEnd
            };
            if (vehicleId) body = {...body, vehicleId};
            axios.post<ITrafficRow[]>(this.service + "deviceData/detail", body, {headers: {skipPreloader: true}})
                .then(resp => resolve(resp.data));
        })
    }

    async changeImei(oldImei: string, newImei: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + "carregister/changeImei", {imei: oldImei, newImei})
                .then(resp => {
                    if (resp) resolve(true);
                })
        })
    }

    async removeCarRegisterWithImei(imei: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(this.service + "carregister/" + imei)
                .then(resp => {
                    if (resp) resolve(true);
                })
        })
    }

    async getLogs() {
        return new Promise<IGpsProgramLog[]>((resolve, reject) => {
            axios.get<IGpsProgramLog[]>(this.service + "logs")
                .then(resp => {
                    if (resp) resolve(resp.data);
                })
        })
    }

    async getRemoteToken(userName: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.get<RemoteTokenResponse>("/gpsprogram/remotetoken")
                .then(resp => {
                    if (resp.data.result) {
                        axios.get(`https://web.nipo-gps.com/GPS/ExternalLoginServlet?type=LOGIN_USER&token=${resp.data.token}&username=${userName}`)
                            .then(resp => {
                                resolve(true);
                            })
                    }
                }).catch(err => reject(false))
                .catch(err => reject(false))
        })
    }

    async getMonthlyRoadList(userName: string, vehicle: IVehicle) {
        const tokenSuccess = await this.getRemoteToken(userName);
        const startDate = moment(vehicle.lastGpsOnline).startOf("month").format("YYYY-MM-DD")
        if (tokenSuccess) {
            window.open(`https://web.nipo-gps.com/GPS/innerHtml/mapReportsInterface.jsp?vehicleId=${vehicle.vehicleTable}&startDate=${startDate}&filterTime=00:00:00&stopsControl=0&stopPlaces=0&kilometres=0&includeCityNoncityDistance=0&accuracy=0&firmName=&reportType=monthRoadList`, "_blank");
        }
    }

    async getDailyRoadList(userName: string, vehicle: IVehicle) {
        const tokenSuccess = await this.getRemoteToken(userName);
        const startDate = moment(vehicle.lastGpsOnline).format("YYYY-MM-DD")
        if (tokenSuccess) {
            window.open(`https://web.nipo-gps.com/GPS/innerHtml/mapReportsInterface.jsp?vehicleId=${vehicle.vehicleTable}&startDate=${startDate}&filterTime=00:00:00&stopsControl=0&stopPlaces=0&kilometres=0&includeCityNoncityDistance=0&accuracy=0&firmName=&reportType=dayRoadList`, "_blank");
        }
    }

    async getVehiclesData(carTable: string) {
        return new Promise<IVehicleDataParsed>((resolve, reject) => {
            axios.get<IVehicleDataResponse>(this.service + "vehicles/data/" + carTable)
                .then(resp => {
                    resolve(this.parseVehicleData(resp.data.body))
                }).catch(reject)
        })
    }

    async setVehicleData(carTable: string, param: keyof IVehicleData, value: any){
        return new Promise<IVehicleDataParsed>((resolve, reject) => {
            const body = {
                vehicleTable: carTable,
                paramName: param,
                paramValue: param.toLowerCase().includes("coef") ? value.toString() : value
            }
            axios.post<IVehicleDataResponse>(this.service + "vehicles/data", body)
                .then(resp => {
                    if(resp.data.result && resp.data.body) {
                        resolve(this.parseVehicleData(resp.data.body))
                    }
                })
        })
    }

    async addVehicleToAccount(data: IAddVehicleToAccount) {
        return new Promise<boolean>((resolve, reject) => {
            const requestData = {...data, vehicleId: data.vehicleId};
            axios.post(this.service + "vehicles", requestData)
                .then((response: any) => resolve(response.data.result))
                .catch(reject);
        })
    }

    async addVehiclesToAccount(data: IAddVehicleToAccount[]) {
        return new Promise<MultipleVehicleCopyToAccountResponse[]>((resolve, reject) => {
            axios.post<MultipleVehicleCopyToAccountResponse[]>(this.service + "vehicles/multiple", data)
                .then((response: any) => resolve(response.data))
                .catch(reject);
        })
    }

    async getAllAccountReports() {
        return new Promise<IAccountReports[]>((resolve, reject) => {
            axios.get<IAccountReports[]>(this.service + "accounts/reports")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getAccountReports(userName: string) {
        return new Promise<IAccountReports[]>((resolve, reject) => {
            axios.get<IAccountReports[]>(this.service + "accounts/reports/" + userName)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })





    }

    async setReportPermission(userName: string, reportId: string) {
        return new Promise<IAccountReports[]>((resolve, reject) => {
            axios.post<IAccountReports[]>(this.service + "accounts/reports", {userName, reportId})
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    private parseVehicleData(data: IVehicleData){
        return {
            ...data,
            fuel: this.parseTrueFalseStringToNumber(data.fuel),
            kanshina: this.parseTrueFalseToNumber(data.kanshina),
            oboroti: this.parseTrueFalseToNumber(data.oboroti),
            contact_support: this.parseTrueFalseToNumber(data.contact_support),
            teltonika: this.parseTrueFalseToNumber(data.teltonika)
        }

    }

    private parseTrueFalseStringToNumber = (value: "true" | "false" | undefined) => {
        return value === "true" ? 1 : 0;
    }

    private parseTrueFalseToNumber = (value: boolean | undefined) => {
        return value ? 1 : 0;
    }

    async getTcpProxyStats() {
        return new Promise<TcpProxyEntry[][]>((resolve, reject) => {
            axios.get<string>(this.service + "tcpproxy/stats/row", {headers: {skipPreloader: true}})
                .then(resp => {
                    resolve(JSON.parse(resp.data))
                }).catch(reject)
        })
    }

    async changeAccountPassword(username:string, password: string) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.service + "accounts/" + username + "/changeaccountpassword", password.toString(), {
                headers: {
                    'Content-Type': 'application/json'
                }})
                .then(resp => {
                    if(resp.data)
                        resolve(resp)
                    else reject(false);
                }).catch(reject)
        })
    }
}
