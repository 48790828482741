import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {DynamicForm, UtilService as NewUtilService} from "@xal3xfx/react-utils";
import {formElements} from "./protocol-form";
import {ICreateProtocol, IVehicle, Protocol} from "../../lib/types/types";
import {DeviceService} from "../../services/deviceService";
import {ClientFirmService} from "../../services/clientFirmService";
import {EmployeeService} from "../../services/employeeService";
import {ProtocolService} from "../../services/protocolService";
import {useToast} from "../../lib/hooks/useToast";
import {useDialog} from "../../lib/hooks/useDialog";

interface Props {
    createdCb?: (createdProtocol: Protocol) => void
}

let initialValues: ICreateProtocol = {
    dateTime: new Date(),
    clientFirmId: 0,
    vehicleId: 0,
    oldDeviceId: 0,
    newDeviceId: 0,
    employeeIds: [],
    protocolTypeId: 0
}

const CreateProtocolForm: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showToast} = useToast();
    const {hideDialog} = useDialog();

    const deviceService = new DeviceService();
    const clientFirmService = new ClientFirmService();
    const employeeService = new EmployeeService();
    const protocolService = new ProtocolService();

    const [formElementsState, setFormElementsState] = useState<typeof formElements>(JSON.parse(JSON.stringify(formElements)));
    const [currentFirmVehicles, setCurrentFirmVehicles] = useState<IVehicle[]>();

    const formikRef = useRef<any>();


    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            loadInitialDropdowns();

        }
    }, []);

    const loadInitialDropdowns = async () => {
        const [deviceOptions, clientFirmOptions, employeeOptions, protocolActionOptions] = await Promise.all([deviceService.getFreeDevicesDropdown(), clientFirmService.getClientFirmsDropdown(false), employeeService.getAllEmployeesDropdown(), protocolService.getProtocolActionOptions()]);
        const tempFormElements = {...formElementsState};

        tempFormElements.clientFirmId.options = clientFirmOptions;
        tempFormElements.newDeviceId.options = deviceOptions;
        tempFormElements.employeeIds.options = employeeOptions;
        tempFormElements.protocolActionIds.options = protocolActionOptions;
        setFormElementsState(tempFormElements);
    }

    const onCreateProtocol = (data: ICreateProtocol) => {
        return new Promise<boolean>((resolve, reject) => {
            protocolService.createProtocol(data)
                .then(resp => {
                    if (resp) {
                        showToast("success", f({id: "done"}), f({id: "protocolCreatedSuccessfully"}));
                        if(props.createdCb) props.createdCb(resp)
                        hideDialog();
                        resolve(true);
                    }
                }).catch(reject)
        });
    }

    const handleFieldChange = async (field: string, value: any) => {
        if (field === "clientFirmId") {
            //Get the firm vehicles
            const clientFirmVehicles = await clientFirmService.getClientFirmVehicles(value);
            setCurrentFirmVehicles(clientFirmVehicles);
            const tempFormElements = {...formElementsState};
            const vehicleOptions = NewUtilService.generateDropdownOptionsFromData<IVehicle>(clientFirmVehicles, "vehicleId", 'vehicleLpn');
            tempFormElements.vehicleId.options = vehicleOptions;
            //If there is only one vehicle in the options - fill the oldDeviceId dropdown with it's gps options and select the first gps that it has
            if(vehicleOptions.length === 1) {
                //Select the gps
                formikRef.current.setFieldValue("oldDeviceId", clientFirmVehicles[0]?.deviceDtos.find(el => el.deviceTypeName.toLowerCase().includes("gps"))?.deviceId || 0);
                //Fill the dropdown with this vehicle's devices
                buildOldDeviceOptions(clientFirmVehicles[0]);
            }else {
                tempFormElements.oldDeviceId.options = [];
            }
            setFormElementsState(tempFormElements);
        }
        if (field === "vehicleId") {
            if (formikRef.current) {
                const currentlySelectedVehicle = currentFirmVehicles?.find(el => el.vehicleId === value);
                if (currentlySelectedVehicle) {
                    formikRef.current.setFieldValue("oldDeviceId", currentlySelectedVehicle.deviceDtos.find(el => el.deviceTypeName.toLowerCase().includes("gps"))?.deviceId || 0);
                    buildOldDeviceOptions(currentlySelectedVehicle);
                }
            }

        }
    }

    const buildOldDeviceOptions = (currentlySelectedVehicle: IVehicle) => {
        setFormElementsState(prevFormElementsState => {
            prevFormElementsState.oldDeviceId.options = currentlySelectedVehicle.deviceDtos.filter(device => device.deviceTypeName.toLowerCase().includes("gps")).map(el => {
                return {
                    key: el.deviceId!.toString(),
                    id: el.deviceId!.toString(),
                    description: el.deviceNumber!.toString()
                }
            })

            return prevFormElementsState;
        })
    }

    return <>
        <DynamicForm formElements={formElementsState}
                     setFormikRef={(formik) => formikRef.current = formik}
                     onFieldChangeCallback={handleFieldChange}
                     isUpdate={false}
                     onCreate={onCreateProtocol}
                     rowClassName={"col-12 md:col-6 mt-3"}
                     formButtonsClassName={"col-12 md:col-6"}
                     onUpdate={() => Promise.resolve(true)}
                     initialValues={initialValues}
                     fieldOrder={['dateTime', 'clientFirmId', 'vehicleId', 'oldDeviceId', 'newDeviceId', 'employeeIds', 'protocolTypeId', 'protocolActionIds']}
                     onCancelUpdate={() => 0}/>
    </>
};

export default CreateProtocolForm
