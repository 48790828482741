import {createContext, useEffect, useRef, useState} from 'react';
import {Circles} from 'react-preloaders2'

export const PreloaderContext = createContext<any>(null);

export const PreloaderContextProvider = (props: any) => {
    const [renderLoading, setRenderLoading] = useState(false);
    const [rerender, setRerender] = useState(new Date().getTime())
    const loadingElementsCountRef = useRef(0);

    const setLoading = (flag: boolean) => {
        if (flag) loadingElementsCountRef.current = loadingElementsCountRef.current + 1
        else loadingElementsCountRef.current = loadingElementsCountRef.current - 1;
        const dateNow = Date.now();
        setRerender(dateNow);
    }

    useEffect(() => {
    }, [renderLoading]);


    useEffect(() => {
        if (loadingElementsCountRef.current > 0) setRenderLoading(true);
        if (loadingElementsCountRef.current <= 0) {
            setRenderLoading(false);
            loadingElementsCountRef.current = 0;
        }
    }, [rerender]);

    const clearBlur = () => {
        const root = document.getElementById("root");
        if (root) {
            root.childNodes.forEach(el => {
                //@ts-ignore
                el.style.filter = ""
            })
        }

        return null;
    }

    return <>
        <PreloaderContext.Provider value={{setLoading}}>
            {
                renderLoading ?
                    <Circles background={'blur'} customLoading={true}/>
                    :
                    clearBlur()
            }
            {props.children}
        </PreloaderContext.Provider>
    </>
};
