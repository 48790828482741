import {FormElementValues} from "@xal3xfx/react-utils";
import {FormElement} from "@xal3xfx/react-utils/dist/DynamicForm";
import {ICreateInvoiceGroup} from "../../../lib/types/types";

const clientFirmId: FormElementValues<'text'> = {
    type: "text",
    label: 'clientFirm',
    props: { disabled: true },
}

const invoiceCyrcle: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'invoiceCycle',
    props: {},
    options: [{key: 0, description: "Предходен", id: 0}, {key: 1, description: "Текущ", id: 1}]
}

const invoiceDetailsId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'invoiceDetailsName',
    props: {},
    options: []
}

const invoiceGroupName: FormElementValues<'text'> = {
    type: "text",
    label: 'invoiceGroupName',
    props: {},
}

const invoiceGroupDurationInMonths: FormElementValues<'number'> = {
    type: "number",
    label: 'invoiceGroupDurationInMonths',
    props: {min: 1, max: 12},
}

const emailsToSendIds: FormElementValues<'multiselect'> = {
    type: "multiselect",
    label: 'emailsToSend',
    props: {},
    options: []
}




export const formElements = {
    clientFirmId, invoiceCyrcle, invoiceDetailsId,invoiceGroupDurationInMonths, invoiceGroupName, emailsToSendIds
}
