import axios from "axios";
import {ILoginFormData, IUserPermission} from "../lib/types/types";

export class AuthService {

    async login(userData : ILoginFormData) {
        return new Promise<string>((resolve, reject) => {
            axios.post('auth/login', userData).then(response => {
                if (response.status === 200)
                    resolve(userData.username);
            }).catch(err => {
                reject(err);
            });
        })
    }

    async checkIfAuthenticated() {
        return new Promise<{username: string, permissions: IUserPermission[]}>((resolve, reject) => {
            axios.get<{username: string, permissions: IUserPermission[]}>('users/checkIfAuthenticated').then(response => {
                if (response.status === 200)
                    resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        })
    }

    async logout(){
        return new Promise<true>((resolve, reject) => {
            axios.post('auth/logout').then(response => {
                if (response.status === 200)
                    resolve(true);
            }).catch(err => {
                reject(err);
            });
        })
    }
}
