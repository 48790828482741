import * as React from 'react';
import {RefObject, useState} from 'react';
import {useIntl} from "react-intl";
import {ITariff, IVehicle} from "../../lib/types/types";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import {VehicleService} from "../../services/vehicleService";
import {useToast} from "../../lib/hooks/useToast";
import {ReactiveTable} from "react-ive-tables";

interface Props {
    vehiclesData: IVehicle[];
    clientFirmTariffsData: ITariff[];
    hideMultipleAssign : () => void;
    callback : () => void
}

export const TariffMultipleAssign: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const vehicleService = new VehicleService();
    const {showToast} = useToast();

    const [selectedTariffs, setSelectedTariffs] = useState<ITariff[]>([]);
    const [selectedVehicles, setSelectedVehicles] = useState<IVehicle[]>([]);

    const attachTariffsToVehicle = () => {
        vehicleService.assignTariffs(selectedTariffs.map(el => el.tarifId), selectedVehicles.map(el => el.vehicleId)).then(res => {
            if(res){
                showToast("success", f({id: "done"}), f({id: "tariffsAssigned"}));
                props.hideMultipleAssign();
                props.callback();
            }else{
                showToast("warn", f({id: "error"}), f({id: "operationFailed"}));
            }
        }).catch(err => {
            showToast("error", f({id: "error"}), f({id: "operationFailed"}));
        });
    }

    return <>
        <Fieldset>
            <div className={"grid w-full"}>
                <div className="p-field col-6">
                    <ReactiveTable forOverlay data={props.clientFirmTariffsData} columnOrder={['tarifName']}
                                     showHeader={false} selectionMode={'checkbox'} selectionKey={'tarifId'}
                                     setSelected={setSelectedTariffs}/>
                </div>
                <div className="p-field col-6">
                    <ReactiveTable forOverlay data={props.vehiclesData} columnOrder={['vehicleLpn']}
                                     showHeader={false} selectionMode={'checkbox'} selectionKey={'vehicleId'}
                                     setSelected={setSelectedVehicles}/>
                </div>
                <div className={'p-field col-12'}>
                    <Button label={f({id: 'attachTariffsToVehicle'})} onClick={attachTariffsToVehicle} disabled={selectedTariffs.length === 0 || selectedVehicles.length === 0}/>
                </div>
            </div>
        </Fieldset>
    </>
};
