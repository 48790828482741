import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {formElements} from './create-invoice-details-form'
import {validationSchema} from './validation'
import {IClientFirm, ICreateInvoiceDetails, IInvoiceDetail} from "../../../lib/types/types";
import {useDialog} from "../../../lib/hooks/useDialog";
import {ClientFirmService} from "../../../services/clientFirmService";
import {useDynamicForm} from "../../../lib/hooks/useDynamicForm";
import {InputText} from "primereact/inputtext";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import {templates} from './InvoiceDetailsTemplates'

interface Props {
    clientFirm: IClientFirm | null;
    invoiceDetails?: ICreateInvoiceDetails;
    createdCb?: (createdInvoiceDetails: IInvoiceDetail) => void;
}

let initialValue: ICreateInvoiceDetails = {
    invoiceDetailsName: "Основни данни",
    recieverName: "",
    recieverAddress: "",
    recieverIdentityCode: "",
    recieverIdentityCodeZDDS: "",
    recieverMol: "",
    recieverBank: "",
    recieverBic: "",
    recieverIban: "",
    providerName: "",
    providerAddress: "",
    providerIdentityCode: "",
    providerIdentityCodeZDDS: "",
    providerMol: "",
    providerBank: "",
    providerBic: "",
    providerIban: "",

}

export const CreateOrUpdateInvoiceDetails: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const {hideDialog} = useDialog();
    const formikRef = useRef<any>();

    const [invoiceDetailsName, setInvoiceDetailsName] = useState<string>("Основни данни");
    const [invoiceDetailsNameInvalid, setInvoiceDetailsNameInvalid] = useState(false);
    const [formElementsState, setFormElementsState] = useState({...initialValue});

    const clientFirmService = new ClientFirmService();
    const dynamicForm = useDynamicForm();

    useEffect(() => {
        let formElementsTemp = {...formElementsState};
        if (props.clientFirm && !props.invoiceDetails) {
            formElementsTemp.recieverName = props.clientFirm.clientFirmName;
            formElementsTemp.recieverAddress = props.clientFirm.address;
            formElementsTemp.recieverMol = props.clientFirm.mol;
            formElementsTemp.recieverIdentityCode = props.clientFirm.bulstat;
            formElementsTemp.recieverIdentityCodeZDDS = "BG" + props.clientFirm.bulstat
        } else if (props.invoiceDetails) {
            console.log("THE PROPS IS: ", props.invoiceDetails)
            formElementsTemp = {...props.invoiceDetails}
            setInvoiceDetailsName(props.invoiceDetails.invoiceDetailsName || "Основни данни");
        }
        setFormElementsState(formElementsTemp);

    }, [props])

    const createHandler = (data: ICreateInvoiceDetails) => {
        if (!invoiceDetailsName || !props.clientFirm) {
            setInvoiceDetailsNameInvalid(true);
            return Promise.resolve(false);
        }
        return new Promise<boolean>((resolve, reject) => {
            clientFirmService.createInvoiceDetails({...data, invoiceDetailsName}, props.clientFirm!.clientFirmId)
                .then((res) => {
                    resolve(true);
                    if (props.createdCb) props.createdCb(res[res.length - 1]);
                    else hideDialog();
                }).catch(reject);
        });
    }

    const updateHandler = (data: ICreateInvoiceDetails) => {
        if (!invoiceDetailsName || !props.clientFirm) return Promise.resolve(false);
        return new Promise<boolean>((resolve, reject) => {
            clientFirmService.updateInvoiceDetails({
                ...data,
                invoiceDetailsName
            }, props.clientFirm!.clientFirmId)
                .then((res) => {
                    resolve(true);
                    hideDialog();
                }).catch(reject);
        });
    }

    const getForm = () => {
        return dynamicForm({
            className: "col-12 md:col-6",
            fieldOrder: ['recieverName', 'providerName', 'recieverAddress', 'providerAddress', 'recieverIdentityCode', 'providerIdentityCode', 'recieverIdentityCodeZDDS', 'providerIdentityCodeZDDS', 'recieverMol', 'providerMol', 'recieverBank', 'providerBank', 'recieverBic', 'providerBic', 'recieverIban', 'providerIban'],
            initialValues: formElementsState,
            onFormReset: () => 0,
            onCreate: createHandler,
            onUpdate: updateHandler,
            isUpdate: !!props.invoiceDetails,
            onCancelUpdate: () => 0,
            validationSchema: validationSchema,
            formElements: formElements,
            setFormikRef: formik => formikRef.current = formik
        })
    }

    return <>
        <div className={"grid w-full justify-content-center"}>

            <span className="p-float-label col-12 mb-2 mt-3">
                <InputText invalid={invoiceDetailsNameInvalid} value={invoiceDetailsName} onChange={(e) => {
                    setInvoiceDetailsName(e.target.value);
                    initialValue.invoiceDetailsName = e.target.value
                }}/>
                <label className={"p-ml-2"} htmlFor="command">{f({id: "invoiceDetailsName"})}</label>
            </span>

            <label style={{color: "#2196F3", fontWeight: "bold"}} className={"col-6"}>ПОДАТЕЛ</label>
            <label style={{color: "#2196F3", fontWeight: "bold"}} className={"col-6"}>ДОСТАВЧИК</label>
        </div>
        {getForm()}

        <Fieldset legend={f({id: "templates"})}>
            <div className={"grid justify-content-center"}>
                <Button className={"col-3 p-m-2"}
                        onClick={() => formikRef.current!.setValues({...formikRef.current.values, ...templates.ig})}
                        label={"IG"}/>
                <Button className={"col-3 p-m-2"}
                        onClick={() => formikRef.current!.setValues({...formikRef.current.values, ...templates.nipo})}
                        label={"Nipo"}/>
                <Button className={"col-3 p-m-2"}
                        onClick={() => formikRef.current!.setValues({...formikRef.current.values, ...templates.fms})}
                        label={"FMS"}/>
            </div>
        </Fieldset>

    </>
}
