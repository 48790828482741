import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {GpsProgramService} from "../../services/gpsProgramService";
import {TcpProxyEntry} from "../../lib/types/types";
import {TabMenu} from "primereact/tabmenu";
import ProxyStatusRaw from "./ProxyStatusRaw";
import ProxyStatusSummary from "./ProxyStatusSummary";
import {Card} from "primereact/card";

interface Props {

}

const ProxyStatusWrapper : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const didUnmountRef = useRef(false);

    const gpsProgramService = new GpsProgramService();

    const [rawData, setRawData] = useState<TcpProxyEntry[][]>()
    const [selectedComponentToRender, setSelectedComponentToRender] = useState<"summary" | "raw">("summary");

    const intervalRef = useRef<NodeJS.Timeout>();

    const rawDataRef = useRef<TcpProxyEntry[][]>();

    useEffect(() => {
        // if (!didMountRef.current) {
        didMountRef.current = true;

        gpsProgramService.getTcpProxyStats()
            .then(resp => {
                rawDataRef.current = resp;
                setRawData(resp)
            });

        intervalRef.current = setInterval(() => {
            gpsProgramService.getTcpProxyStats()
                //TODO: TEST BY MANUALLY ADJUSTING THE DATA
                .then(resp => {
                    setRawData(resp)
                });
        }, 5000);
        // }

        return () => {
            if(!didUnmountRef.current){
                didUnmountRef.current = true;
            }
            clearInterval(intervalRef.current);
        }
    }, []);

    const items = [
        {
            label: f({id: "summary"}),
            icon: 'fal fa-folder-open',
            command: () => setSelectedComponentToRender('summary'),
            key: ['Vehicles']
        },
        {
            label: f({id: "raw"}),
            icon: 'fal fa-folder-open',
            command: () => setSelectedComponentToRender('raw'),
            key: ['Vehicles']
        },
    ];

    const renderSelectedComponent = () => {
        if(!rawData) return;
        switch (selectedComponentToRender) {
            case 'raw' : {
                return <ProxyStatusRaw data={rawData}/>
            }
            case 'summary' : {
                return <ProxyStatusSummary data={rawData}/>
            }
        }
    }

    return <>
        <Card className={"w-full"}>

            <TabMenu model={items} className={"w-full"}/>
        {renderSelectedComponent()}
        </Card>
    </>
};

export default ProxyStatusWrapper
