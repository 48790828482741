import * as React from "react";
import {DynamicForm} from "@xal3xfx/react-utils";
import {FormElement} from "@xal3xfx/react-utils/dist/DynamicForm";
import * as Yup from "yup";
import {FormButtonsPosition} from "@xal3xfx/react-utils/dist/FormButtons";
import {FormikValues} from "formik";

export interface UseDynamicFormProps<T> {
    onFieldChangeCallback?: (field: string, value: any) => void;
    formElements: FormElement<T>;
    isUpdate: boolean;
    onCreate: (values: T) => Promise<boolean>;
    onUpdate: (values: T) => Promise<boolean>;
    onFormReset?: () => void;
    children?: React.ReactNode;
    validationSchema?: Yup.ObjectSchema<any>;
    rowClassName?: string;
    formButtonsPosition?: FormButtonsPosition;
    initialValues: T;
    fieldOrder: (keyof T)[];
    customElements?: {
        [key in keyof Partial<T> | string]: (formik: any) => JSX.Element;
    };
    onCancelUpdate: () => void;
    className?: any;
    setFormikRef?: (formik: any) => void;
    disableSaveButtons?: boolean
}


export function useDynamicForm() : <T extends FormikValues>(props: UseDynamicFormProps<T>) => JSX.Element {

    function getDynamicForm<T extends FormikValues>(props : UseDynamicFormProps<T>) {
        return <DynamicForm rowClassName={props.className + " mt-3"} initialValues={props.initialValues} onFormReset={props.onFormReset} onCreate={props.onCreate}
                            onUpdate={props.onUpdate} isUpdate={props.isUpdate} onCancelUpdate={props.onCancelUpdate}
                            fieldOrder={props.fieldOrder}
                            validationSchema={props.validationSchema}
                            formElements={props.formElements}
                            disableSaveButton={props.disableSaveButtons}
                            customElements={props.customElements}
                            setFormikRef={props.setFormikRef}
                            formButtonsPosition={props.formButtonsPosition || 'start'}
                            onFieldChangeCallback={props.onFieldChangeCallback}
                            formButtonsClassName={props.isUpdate ? "col-12 md:col-4" : "col-12 md:col-6"}
        />
    }

    return getDynamicForm

}
