import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {ProxySummaryRow, TcpProxyEntry} from "../../lib/types/types";
import {TableRow, TableRowContainer} from "./ProxyStatusRaw";
import {Card} from "primereact/card";
import {ReactiveTable} from "react-ive-tables";
import {DataTableRowToggleEvent} from "primereact/datatable";
import {InfoCard} from "../../lib/templates/InfoCard";
import "../serverStatus/ServerStatus.css"

interface Props {
    data: TcpProxyEntry[][]
}

const ProxyStatusSummary: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    const [mainTableData, setMainTableData] = useState<{ [key: string]: ProxySummaryRow }>()
    // const [prevMainTableData, setPrevMainTableData] = useState<{ [key: string]: ProxySummaryRow }>();
    const prevMainTableDataRef = useRef<{ [key: string]: ProxySummaryRow }>();

    const [detailTableData, setDetailTableData] = useState<{ [key: string]: ProxySummaryRow[] }>()
    // const [prevDetailTableData, setPrevDetailTableData] = useState<{ [key: string]: ProxySummaryRow[] }>()
    const prevDetailTableDataRef = useRef<{ [key: string]: ProxySummaryRow[] }>();

    const [fullData, setFullData] = useState<{ [key: string]: TableRow[] }>()
    // const [prevFullData, setPrevFullData] = useState<{ [key: string]: TableRow[] }>()
    const prevFullDataRef = useRef<{ [key: string]: TableRow[]  }>();

    const [infoCardData, setInfoCardData] = useState<{ scur: number, smax: number, bin: number, bout: number }>();

    const [areZeroLinesShown, setAreZeroLinesShown] = useState<{ [key: string]: boolean }>();
    const [isFullDataShown, setIsFullDataShown] = useState<{ [key: string]: boolean }>();

    const [rebuildColumns, setRebuildColumns] = useState<{ [key: string]: number }>({});
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [columnOrderMap, setColumnOrderMap] = useState<{ [key: string]: string[] }>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (props.data) parseData(props.data);
    }, [props.data])

    const parseData = (data: TcpProxyEntry[][]) => {
        let isFirst = false;
        if(!prevMainTableDataRef.current && !prevMainTableDataRef.current) isFirst = true;

        let mainTableRows: { [key: string]: ProxySummaryRow } = {};
        let detailTableRows: { [key: string]: ProxySummaryRow[] } = {};
        let infoCardTempData = {scur: 0, smax: 0, bin: 0, bout: 0};

        let areZerosShownMap: { [key: string]: boolean } = {};
        let isFullDataShownMap: { [key: string]: boolean } = {};
        let tempColumnOrderMap: { [key: string]: string[] } = {};
        let fullDataMap: TableRowContainer = {};

        data.forEach((el, index) => {
            const currentRow: TableRow = el.reduce<TableRow>((acc, currentRow) => {
                return {...acc, [currentRow.field.name]: currentRow.value.value}
            }, {} as TableRow)

            if (currentRow.svname.includes("tcpd")) {
                const currentSummaryRow: ProxySummaryRow = {
                    key: index,
                    tcpName: currentRow.svname,
                    name: currentRow.pxname,
                    bin: currentRow.bin,
                    bout: currentRow.bout,
                    scur: currentRow.scur,
                    smax: currentRow.smax,
                    status: currentRow.status
                };
                if (detailTableRows[currentRow.svname]) detailTableRows[currentRow.svname].push(currentSummaryRow);
                else detailTableRows[currentRow.svname] = [currentSummaryRow];

                infoCardTempData.scur += currentSummaryRow.scur;
                infoCardTempData.smax += currentSummaryRow.smax;
                infoCardTempData.bin += currentSummaryRow.bin;
                infoCardTempData.bout += currentSummaryRow.bout;

                //If the current tcp has already been added to the map -> calculate the new data
                if (mainTableRows[currentRow.svname]) {
                    const summaryRow = mainTableRows[currentRow.svname];
                    summaryRow.bin += currentSummaryRow.bin;
                    summaryRow.bout += currentSummaryRow.bout;
                    summaryRow.scur += currentSummaryRow.scur;
                    summaryRow.smax += currentSummaryRow.smax;
                } else {
                    //otherwise add it to the map
                    mainTableRows[currentRow.svname] = {
                        ...currentSummaryRow,
                        name: currentSummaryRow.tcpName
                    };
                    areZerosShownMap[currentRow.svname] = false;
                    isFullDataShownMap[currentRow.svname] = false;
                    tempColumnOrderMap[currentRow.svname] = ["name", "scur", "smax", "bin", "bout", 'status'];

                }


            }
        })

        data.forEach(el => {
            const currentRow: TableRow = el.reduce<TableRow>((acc, currentRow) => {
                return {...acc, [currentRow.field.name]: currentRow.value.value}
            }, {} as TableRow)

            if (currentRow.svname.includes("tcpd")) {
                if (fullDataMap[currentRow.svname]) {
                    fullDataMap[currentRow.svname].push({...currentRow, name: currentRow.pxname});
                } else {
                    fullDataMap[currentRow.svname] = [{...currentRow, name: currentRow.pxname}];
                }
            }
        })

        if(!isFirst){
            // setPrevFullData({...fullData});
            // setPrevDetailTableData({...detailTableData});
            // setPrevMainTableData({...mainTableData});
        }else {
            setAreZeroLinesShown(areZerosShownMap);
            setIsFullDataShown(isFullDataShownMap);
            setColumnOrderMap(tempColumnOrderMap);
        }
        prevFullDataRef.current = {...fullData};
        prevDetailTableDataRef.current = {...detailTableData};
        prevMainTableDataRef.current = {...mainTableData};

        setMainTableData(mainTableRows);
        setDetailTableData(detailTableRows);
        setFullData(fullDataMap);


        setInfoCardData(infoCardTempData);
    }

    const handleRowToggle = (e: DataTableRowToggleEvent) => {
        setExpandedRows(e.data);
    }

    const rowClass = (data: ProxySummaryRow) => {
        return {
            'vehicle-without-gps': data.status.includes("DOWN")
        }
    }

    const toggleZeroLines = (data: ProxySummaryRow) => {
        const selectedTcp = data.tcpName;
        const isCurrentShown = areZeroLinesShown![selectedTcp];
        setAreZeroLinesShown({...areZeroLinesShown, [selectedTcp]: !isCurrentShown})
    }

    const toggleFullData = (data: ProxySummaryRow) => {
        const selectedTcp = data.tcpName;
        const isCurrentShown = isFullDataShown![selectedTcp];
        setIsFullDataShown({...isFullDataShown, [selectedTcp]: !isCurrentShown})
        setColumnOrderMap({
            ...columnOrderMap,
            [selectedTcp]: isCurrentShown ? ["name", "scur", "smax", "bin", "bout"] : Object.keys(fullData![selectedTcp][0])
        })
        setRebuildColumns({...rebuildColumns, [selectedTcp]: Date.now()});
    }

    const getHeaderButtons = (data: ProxySummaryRow) => {
        return [
            {
                onClick: () => toggleZeroLines(data),
                icon: areZeroLinesShown![data.tcpName] ? 'fal fa-eye-slash' : 'fal fa-eye',
                className: 'mr-2',
                tooltipLabel: f({id: areZeroLinesShown![data.tcpName] ? "hideZeroLines" : "showZeroRows"})
            },
            {
                onClick: () => toggleFullData(data),
                icon: isFullDataShown![data.tcpName] ? 'fal fa-caret-left' : 'fal fa-caret-right',
                className: 'mr-2',
                tooltipLabel: f({id: isFullDataShown![data.tcpName] ? "hideFullData" : "showFullData"})
            },
        ]
    }

    const getInnerTableData = (svcname: string) => {
        const isFullData = isFullDataShown![svcname];
        let result: ProxySummaryRow[] | TableRow[] = [];
        if (isFullData && fullData) {
            result = fullData[svcname]
        } else if (detailTableData) {
            result = detailTableData[svcname];
        }
        if (areZeroLinesShown && areZeroLinesShown[svcname]) {
            return result
        } else {
            return result.filter((el: ProxySummaryRow) => el.scur > 0);
        }
    }

    const getDetailedTemplate = (rowData: ProxySummaryRow | TableRow, field: keyof typeof rowData, prevDataSet : ProxySummaryRow[] | TableRow[]) => {
        const prevEntry = prevDataSet.find(el => el.name === rowData.name);
        let className = "KUR";
        if(prevEntry && prevEntry[field] !== rowData[field]) {
            className = "cellBackground ";
        }

        return <div className={className}>{rowData[field]}</div>
    }

    const getDetailColumnTemplate = (data: ProxySummaryRow) => {
        const selectedTcp = data.tcpName;
        if(!prevFullDataRef.current || ! prevDetailTableDataRef.current) return;
        return {
            name: (rowData: ProxySummaryRow | TableRow) => rowData.name.toUpperCase(),
            scur: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "scur", prevDetailTableDataRef.current![selectedTcp]),
            smax: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "smax", prevDetailTableDataRef.current![selectedTcp]),
            bin: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "bin", prevDetailTableDataRef.current![selectedTcp]),
            bout: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "bout", prevDetailTableDataRef.current![selectedTcp]),
        }

    }

    const rowExpansionTemplate = (data: ProxySummaryRow) => {
        return (
            <div className="p-3 flex w-full justify-content-center align-items-center" style={{maxWidth: "83vw"}}>
                <ReactiveTable
                    wrapperClassName={"w-full"}
                    columnOrder={columnOrderMap![data.name]}
                    data={getInnerTableData(data.name)}
                    showHeader={true}
                    showFilters={false}
                    rebuildColumns={rebuildColumns[data.tcpName] || 0}
                    columnTemplate={getDetailColumnTemplate(data)}
                    headerButtons={getHeaderButtons(data)}
                    dtProps={{
                        rowClassName: rowClass
                    }}
                />

            </div>
        );
    };

    return <>

        {infoCardData &&
            <div className={"grid w-full mt-3"}>
                <div className={"col-12 md:col-3"}>
                    <InfoCard icon={"pi pi-car"} iconBackgroundColor={"#cae6fc"} header={"Current"}
                              content={infoCardData.scur}/>
                </div>
                <div className={"col-12 md:col-3"}>
                    <InfoCard icon={"fab fa-buffer"} iconBackgroundColor={"#cae6fc"} header={"Max"}
                              content={infoCardData.smax}/>
                </div>
                <div className={"col-12 md:col-3"}>
                    <InfoCard icon={"far fa-hdd"} iconBackgroundColor={"#cae6fc"} header={"Bytes in"}
                              content={infoCardData.bin}/>
                </div>

                <div className={"col-12 md:col-3"}>
                    <InfoCard icon={"far fa-hdd"} iconBackgroundColor={"#cae6fc"} header={"Bytes out"}
                              content={infoCardData.bout}/>
                </div>
            </div>
        }


        {mainTableData !== undefined &&
            <Card className={"mt-3"}>
                <ReactiveTable columnOrder={["name", "scur", "smax", "bin", "bout"]}
                               data={Object.values(mainTableData)}
                               showFilters={false}
                               showPaginator={false}
                               expandable
                               columnTemplate={{
                                   name: (rowData: ProxySummaryRow) => rowData.name.toUpperCase(),
                                   scur: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "scur", Object.values(prevMainTableDataRef.current!)),
                                   smax: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "smax", Object.values(prevMainTableDataRef.current!)),
                                   bin: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "bin", Object.values(prevMainTableDataRef.current!)),
                                   bout: (rowData: ProxySummaryRow | TableRow) => getDetailedTemplate(rowData, "bout", Object.values(prevMainTableDataRef.current!)),
                               }}
                               paginatorOptions={[100000]}
                               dtProps={{
                                   rowExpansionTemplate: rowExpansionTemplate,
                                   expandedRows: expandedRows,
                                   onRowToggle: handleRowToggle,
                                   dataKey: "key"
                               }}
                />
            </Card>


        }
    </>
};

export default ProxyStatusSummary
