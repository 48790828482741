import {Fieldset} from "primereact/fieldset";
import {ReactiveTable} from "react-ive-tables";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import * as React from "react";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";

interface Props<T> {
    leftData: T[];
    rightData: T[];
    submitCb: (leftData: T[] , rightData: T[] ) => void;
    selectionKey: Extract<keyof T, string>;
    columnOrder: Extract<keyof T, string>[];
    leftTableTitle: string;
    rightTableString: string;
    buttonLabel?: string

}

export const AssignComponent = <T extends unknown>(props: Props<T>) => {
    const {formatMessage: f} = useIntl();

    const [leftTableData, setLeftTableData] = useState<T[]>([]);
    const [leftTableSelectedRecords ,setLeftTableSelectedRecords] = useState<T[]>([]);
    const [rightTableData, setRightTableData] = useState<T[]>([]);
    const [rightTableSelectedRecords, setRightTableSelectedRecords] = useState<T[]>([]);
    const [selectionResetter, setSelectionResetter] = useState(0);

    useEffect(() => {
        if(props.leftData) setLeftTableData(props.leftData);
        if(props.rightData) setRightTableData(props.rightData);
    }, [props])

    const moveFromRightToLeft = () => {
        if(rightTableSelectedRecords && rightTableData) {
            const selectedRecordsFromRightTableIds = rightTableSelectedRecords.map(el => el[props.selectionKey]);
            const newRightTableData = rightTableData.filter(el => !selectedRecordsFromRightTableIds.includes(el[props.selectionKey]));
            const newLeftTableData = [...leftTableData!, ...rightTableSelectedRecords];

            setRightTableData(newRightTableData);
            setLeftTableData(newLeftTableData);
            setRightTableSelectedRecords([]);
            setLeftTableSelectedRecords([]);
            setSelectionResetter(new Date().getTime());
        }

    }

    const moveFromLeftToRight = () => {
        if(leftTableSelectedRecords && leftTableData) {
            const selectedRecordsFromLeftTableIds = leftTableSelectedRecords.map(el => el[props.selectionKey]);
            const newLeftTableData = leftTableData.filter(el => !selectedRecordsFromLeftTableIds.includes(el[props.selectionKey]));
            const newRightTableData = [...rightTableData!, ...leftTableSelectedRecords];

            setRightTableData(newRightTableData);
            setLeftTableData(newLeftTableData);
            setRightTableSelectedRecords([]);
            setLeftTableSelectedRecords([]);
            setSelectionResetter(new Date().getTime());
        }
    }

    const saveHandler = () => {
        props.submitCb(leftTableData, rightTableData);
    }


    return <Card id={'assign-component'} className={"p-datatable-wrapper mt-5 p-shadow-4"} style={{ borderRadius: '1rem' }}>
                 <div className={"grid w-full"}>
                     <div className="p-field col-5">
                         <Fieldset legend={props.leftTableTitle} style={{height: "100%"}}>
                             <ReactiveTable data={leftTableData}
                                              selectionResetter={selectionResetter}
                                              showSkeleton={false}
                                              showHeader={false}
                                              setSelected={setLeftTableSelectedRecords}
                                              selectionMode={'checkbox'}
                                              selectionKey={props.selectionKey}
                                              columnOrder={props.columnOrder}
                             />
                         </Fieldset>
                     </div>

                     <div className="p-field col-2 justify-content-center flex align-items-center flex-column">
                         <div className={'grid w-full p-p-3 p-shadow-4'} style={{width: "100%", borderRadius: "1rem"}}>
                             <div className={"col-12 xl:col-6 w-full"}>
                                 <Button onClick={moveFromRightToLeft} style={{width: '100%'}} icon={"pi pi-angle-double-left"}/>
                             </div>
                             <div className={"col-12 xl:col-6 w-full"}>
                                 <Button onClick={moveFromLeftToRight} style={{width: '100%'}} icon={"pi pi-angle-double-right"}/>
                             </div>
                         </div>

                         <Button className={'mt-3'} onClick={saveHandler} label={props.buttonLabel || f({id:"administrate"})} />

                     </div>

                     <div className="p-field col-5">
                         <Fieldset legend={props.rightTableString} style={{height: "100%"}}>
                             <ReactiveTable data={rightTableData}
                                              showHeader={false}
                                              selectionResetter={selectionResetter}
                                              setSelected={setRightTableSelectedRecords}
                                              selectionMode={'checkbox'}
                                              selectionKey={props.selectionKey}
                                              columnOrder={props.columnOrder}
                             />
                         </Fieldset>
                     </div>
                 </div>
             </Card>
};
