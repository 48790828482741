import * as React from 'react';
import {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {ITariff, IVehicle} from "../../lib/types/types";
import {ReactiveTable} from "react-ive-tables";
import {VehicleService} from "../../services/vehicleService";
import {CreateOrEditTariff} from "./CreateOrEditTariff";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {useToast} from "../../lib/hooks/useToast";
import {useDialog} from "../../lib/hooks/useDialog";
import {Button} from "primereact/button";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
`

const HeaderText = styled.h2`
  margin-left: auto;
  margin-right: auto;
`

const ButtonsContainer = styled.h2`
  display: flex;
  align-items: center;
`


interface Props {
    selectedVehicle?: IVehicle;
    firmTariffs: ITariff[];
    clientFirmId: number;
    tableHeight?: number;
    refresh?: number
}

export const TariffsForVehicle: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const vehicleService = new VehicleService();

    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();
    const {showDialog, isShown: isSidebarShown} = useDialog();
    const [selectedTariff, setSelectedTariff] = useState<ITariff>();
    const [vehicleTariffsData, setVehicleTariffsData] = useState<ITariff[]>([]);
    const [selectionResetter, setSelectionResetter] = useState(0);

    useEffect(() => {
        getAllTariffsForVehicle();
    }, [props.selectedVehicle]);

    useEffect(() => {
        if(props.refresh) getAllTariffsForVehicle();
    }, [props.refresh])

    useEffect(() => {
        setSelectionResetter(Date.now());
        setSelectedTariff(undefined);
    }, [props.selectedVehicle]);


    const getAllTariffsForVehicle = () => {
        if(!props.selectedVehicle) return;
        vehicleService.getAllTariffsForVehicle(props.selectedVehicle.vehicleId).then(setVehicleTariffsData)
    }

    useEffect(() => {
        console.log(selectedTariff)
    }, [selectedTariff])

    const createOrEditTariff = (edit: boolean) => {
        if ((edit && !selectedTariff) || !props.selectedVehicle) return
        showDialog({
            newContent: <CreateOrEditTariff onSubmitCallback={onSubmitCallback} clientFirmId={props.clientFirmId}
                                            vehicleId={props.selectedVehicle.vehicleId}
                                            edit={edit}
                                            selectedTariff={edit ? selectedTariff : undefined}
                                            tariffTemplates={props.firmTariffs}/>,
            heading: <div> {f({id: edit ? "editTariff" : "createTariff"})}
                <i className={'fal fa-car fa-sm'}/></div>
        });
    }

    const onSubmitCallback = () => {
        setSelectedTariff(undefined);
        setSelectionResetter(new Date().getTime());
        getAllTariffsForVehicle();
    }

    const deleteTariff = () => {
        if(!props.selectedVehicle) return
        if (selectedTariff) {
            //@ts-ignore
            // eslint-disable-next-line react-hooks/rules-of-hooks
            showConfirmDialog({body: f({id: "confirmTarifDelete"}, {tarifName: selectedTariff.tarifName})}).then(answer => {
                if (answer) {
                    vehicleService.deleteTariff(props.selectedVehicle!.vehicleId, selectedTariff.tarifId).then(() => {
                        setSelectedTariff(undefined);
                        getAllTariffsForVehicle();
                        showToast("success", f({id: "done"}), f({id: "tariffDeleted"}));
                    });
                } else {

                }
            })
        }
    }

    const menuModel = [
        {label: f({id: "add"}), icon: 'pi pi-plus', command: createOrEditTariff.bind(undefined, false), disabled: !props.selectedVehicle},
        {label: f({id: "edit"}), icon: 'pi pi-pencil', command: createOrEditTariff.bind(undefined, true), disabled: !selectedTariff, className: "p-button-warning"},
        {label: f({id: "delete"}), icon: 'pi pi-trash', command: deleteTariff, disabled: !selectedTariff, className: "p-button-danger"},
    ];

    const getHeader = () => {
        return <HeaderWrapper>
            <ButtonsContainer>
                {menuModel.map(el => {
                    return <Button className={"mr-2 " + el.className}
                                   onClick={el.command}
                                   icon={el.icon}
                                   tooltip={el.label}
                                   disabled={el.disabled}
                    />
                })}
            </ButtonsContainer>

            <HeaderText>{f({id: "tariffsFor"}, {for: props.selectedVehicle?.vehicleLpn})}</HeaderText>
        </HeaderWrapper>
    }

    return <>
        <ReactiveTable data={vehicleTariffsData} tableHeight={props.tableHeight + "vh"  || "auto"}
            // contextMenu={menuModel}
                       selectionResetter={selectionResetter}
                       columnOrder={['tarifName', 'tarifType.tarifTypeName', 'tarifDescription', 'januaryPrice', 'februaryPrice', 'marchPrice', 'aprilPrice', 'mayPrice', 'junePrice', 'julyPrice', 'augustPrice', 'septemberPrice', 'octoberPrice', 'novemberPrice', 'decemberPrice']}
                       specialLabels={{
                           tarifName: "tariffName",
                           'tarifType.tarifTypeName': "tariffType",
                           tarifDescription: 'description',
                           januaryPrice: 'january',
                           februaryPrice: 'february',
                           marchPrice: "march",
                           aprilPrice: 'april',
                           mayPrice: 'may',
                           junePrice: 'june',
                           julyPrice: 'july',
                           augustPrice: 'august',
                           septemberPrice: 'september',
                           octoberPrice: 'october',
                           novemberPrice: 'november',
                           decemberPrice: 'december'
                       }}
                       selectionMode={'single'}
                       selectionKey={'tarifId'} setSelected={setSelectedTariff}
                       dtProps={{header: getHeader()}}
        />
    </>
};
