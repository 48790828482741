import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {CreateInvoiceGroup} from "./CreateInvocieGroup";
import {InvoiceGroup} from "../../../lib/types/types";
import InvoiceGroupEmails from "./InvoiceGroupEmails";
import {Divider} from "primereact/divider";

interface Props {
    selectedInvoiceGroup: InvoiceGroup;
    clientFirmName: string;
}

const PreviewInvoiceGroup : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
        <CreateInvoiceGroup clientFirmId={props.selectedInvoiceGroup.clientFirmId} clientFirmName={props.clientFirmName} invoiceGroupForEdit={props.selectedInvoiceGroup} />
        <Divider >
            <h2>{f({id: "emails"})}</h2>
        </Divider>
        <InvoiceGroupEmails selectedInvoiceGroup={props.selectedInvoiceGroup} />
    </>
};

export default PreviewInvoiceGroup
