import {useIntl} from 'react-intl';
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {useDialog} from "../../lib/hooks/useDialog";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {useToast} from "../../lib/hooks/useToast";
import {OverlayPanel} from "primereact/overlaypanel";
import {ProtocolService} from "../../services/protocolService";
import {ClientFirmService} from "../../services/clientFirmService";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {Protocol} from "../../lib/types/types";
import moment from "moment/moment";
import CreateProtocolForm from "./CreateProtocolForm";
import {ReactiveTable} from "react-ive-tables";
import {UtilService} from "../../services/utilService";
import {SpeedDial} from "primereact/speeddial";
import {Tooltip} from "primereact/tooltip";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {PeriodFilter} from "../../lib/templates/PeriodFilter";
import Notes from "../../lib/templates/Notes";
import {Dialog} from "primereact/dialog";
import LogTable from "../../lib/templates/LogTable";
import {PermissionContext} from "../../lib/context/PermissionsContext";
import ProtocolActionForm from "./protocolAction/ProtocolActionForm";
import PreviewProtocol from "./PreviewProtocol";
import ProtocolInvoiceNumber from "./ProtocolInvoiceNumber";

interface Props {
    clientFirmId?: number;
}

const NewProtocols: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showDialog, hideDialog} = useDialog();
    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();
    const {allowAction, getMenuOptionsForUser} = useContext(PermissionContext);

    const op = useRef<OverlayPanel>(null);
    const employeesOp = useRef<any>();
    const actionsOp = useRef<any>();

    const protocolService = new ProtocolService();
    const clientFirmService = new ClientFirmService();

    const [gridData, setGridData] = useState<Protocol[]>([]);
    const [operationsTooltip, setOperationsTooltip] = useState<any>();
    const [firmOptions, setFirmOptions] = useState<IDropdownOption[]>();
    const [selectedProtocol, setSelectedProtocol] = useState<Protocol>()
    const [selectedProtocolForDetails, setSelectedProtocolForDetails] = useState<Protocol>()
    const [selectedOperation, setSelectedOperation] = useState<'markAsChecked' | 'notes' | 'log' | 'preview' | 'invoiceNumber'>();
    const [filteredRowsCount, setFilteredRowsCount] = useState(gridData?.length);
    const [dtRefresher, setDtRefresher] = useState(0);
    const [filterDates, setFilterDates] = useState<Date[]>([moment().startOf("year").toDate(), moment().toDate()]);
    const [showLogsDialog, setShowLogsDialog] = useState(false);
    const [protocolActionOptions, setProtocolActionOptions] = useState<IDropdownOption[]>()

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        clientFirmService.getClientFirmsDropdown(true).then(resp => {
            setFirmOptions(resp);
        })

        protocolService.getProtocolActionOptions()
            .then(resp => {
                setProtocolActionOptions(resp)
            })
    }, []);

    useEffect(() => {
        if (!filterDates) return;
        loadTable();
    }, [filterDates, dtRefresher])

    useEffect(() => {
        switch (selectedOperation) {
            case 'markAsChecked': {
                confirmProtocolChecked();
                break;
            }
            case 'notes': {
                showProtocolNotes();
                break;
            }
            case 'log': {
                setShowLogsDialog(true);
                break;
            }
            case 'preview': {
                showPreviewProtocol();
                break;
            }
            case 'invoiceNumber': {
                showInvoiceNumberDialog();
                break;
            }
        }
    }, [selectedOperation]);

    const showPreviewProtocol = () => {
        if(!selectedProtocol || !selectedProtocol.protocolId) return;
        showDialog({newContent: <PreviewProtocol protocolId={selectedProtocol.protocolId} />, heading: f({id: "previewProtocol"}), style: {minWidth: "40vw", maxWidth: "60vw"}, onHideCallback: () => {
                setSelectedOperation(undefined);
                setDtRefresher(Date.now());
            }})
    }

    const showInvoiceNumberDialog = () => {
        if(!selectedProtocol || !selectedProtocol.protocolId) return;
        showDialog({newContent: <ProtocolInvoiceNumber protocolId={selectedProtocol.protocolId} invoiceNumber={selectedProtocol.invoiceNumber} />, heading: f({id: "invoiceNumber"}), onHideCallback: () => {
                setSelectedOperation(undefined);
                setDtRefresher(Date.now());
            }})
    }

    const loadTable = () => {
        const periodStart = moment(filterDates[0]).format("YYYY-MM-DD HH:mm:ss");
        const periodEnd = moment(filterDates[1]).format("YYYY-MM-DD HH:mm:ss");
        protocolService.getAllProtocols(periodStart, periodEnd, props.clientFirmId)
            .then(setGridData)
            .catch()
    }

    const confirmProtocolChecked = () => {
        if (selectedProtocol) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            showConfirmDialog({body: f({id: "confirmMarkProtocolAsChecked"}, {vehicleLpn: selectedProtocol.vehicleLpn})}).then(answer => {
                if (answer) {
                    protocolService.markProtocolAsChecked(selectedProtocol.protocolId).then(() => {
                        setSelectedProtocol(undefined)
                        showToast("success", f({id: "done"}), f({id: "protocolMarkedAsChecked"}));
                        setDtRefresher(new Date().getTime())
                    })
                }
            })
        }
    }

    const onFilterCb = (filteredData: Protocol[]) => {
        setFilteredRowsCount(filteredData.length);
    }

    const footerTemplate = () => {
        return <div style={{textAlign: "right"}}>
            <p>{f({id: "filteredRecords"}) + filteredRowsCount}</p>
            <p>{f({id: "totalRecords"}) + gridData.length}</p>
        </div>
    }

    const showCreateForm = () => {
        showDialog({
            newContent: <CreateProtocolForm createdCb={createdProtocolCb}/>,
            heading: f({id: "createProtocol"}),
            onHideCallback: () => setDtRefresher(new Date().getTime()),
            style: {minWidth: "40vw", width: "40vw"}
        });
    }

    const createdProtocolCb = (createdProtocol: Protocol) => {
        if(createdProtocol && createdProtocol.protocolId) {
            setSelectedProtocol(createdProtocol);
            setSelectedOperation("preview")
        }
    }

    const showCreateProtocolAction = () => {
        showDialog({
            newContent: <ProtocolActionForm/>,
            heading: f({id: "addAction"}),
            onHideCallback: () => 0,
            style: {width: "40vw", minWidth: "40vw"}
        })
    }

    const protocolOptions = UtilService.protocolTypeOptions;

    const menuModel = getMenuOptionsForUser([
        {
            label: f({id: "preview"}),
            icon: 'fal fa-magnifying-glass',
            command: () => setSelectedOperation('preview')
        },
        {
            label: f({id: "markAsChecked"}),
            icon: 'pi pi-check',
            command: () => setSelectedOperation('markAsChecked'),
            key: ['ProtocolsMarkChecked']
        },
        {
            label: f({id: "notes"}),
            icon: 'fas fa-sticky-note',
            command: () => setSelectedOperation('notes'),
            key: ['ProtocolsGetNote', 'ProtocolsAddNote']
        },
        {
            label: f({id: "history"}),
            icon: 'fal fa-clock-rotate-left',
            command: () => setSelectedOperation('log'),
            key: ['Audit']
        },
        {
            label: f({id: "invoiceNumber"}),
            icon: 'fal fa-file-invoice',
            command: () => setSelectedOperation('invoiceNumber'),
            key: ['ChangeProtocolInvoiceNumber']
        },
    ])

    const toggleForInvoice = (rowData: Protocol) => {
        if(!allowAction(['SwitchProtocolInvoiceStatus'])) return;
        showConfirmDialog({body: f({id: rowData.forInvoice ? "confirmRemoveProtocolForInvoice" : "confirmMakeProtocolForInvoice"})}).then(answer => {
            if (answer) {
                protocolService.switchProtocolInvoiceStatus(rowData.protocolId)
                    .then(resp => {
                        showToast("success", f({ id: "done" }), f({ id: "successfulOperation" }));
                        setDtRefresher(Date.now());
                    })
            } else {

            }
        })

    }

    const generateStatusTemplate = (rowData: Protocol) => {
        let className = rowData.forInvoice ? 'fas fa-check-circle fa-2x' : 'fas fa-times-circle fa-2x';
        let color = rowData.forInvoice ? '#46b04a' : '#fc0303';
        return <button
            onClick={() => toggleForInvoice(rowData)}
            style={{color, backgroundColor: "transparent", border: "0px", cursor: "pointer"}}>
            <i className={className}></i>
        </button>
    }

    const getColumnTemplate = () => {
        return {
            protocolType: (rowData: Protocol) => protocolOptions.find(el => el.id === rowData.protocolType)?.description,
            operations: (rowData: Protocol) => <div className={'speeddial-operations'}>
                <SpeedDial className={'speeddial-right'} model={menuModel} pt={{button: {root: {tabIndex: -1}}}}
                           direction="left" onClick={() => {
                    setSelectedProtocol(rowData);
                    setOperationsTooltip(<Tooltip key={rowData.protocolId}
                                                  target={'.speeddial-operations .speeddial-right .p-speeddial-action'}
                                                  position={'top'}/>)
                }}/>
            </div>,
            oldDeviceImei: (rowData: Protocol) => rowData.oldDeviceImei === 0 ? f({id: "noDevice"}) : rowData.oldDeviceImei,
            newDeviceImei: (rowData: Protocol) => rowData.newDeviceImei === 0 ? f({id: "noDevice"}) : rowData.newDeviceImei,
            dateTime: (rowData: Protocol) => moment(rowData.dateTime).format("DD-MM-YYYY HH:mm:ss"),
            checked: (rowData: Protocol) => rowData.checked ? rowData.checkEmployee.employeeName :
                <span style={{color: "red"}}>{f({id: "notChecked"})}</span>,
            employees: generateEmployeesTemplate,
            invoiceNumber: (rowData: Protocol) => rowData.invoiceNumber || f({id: "noInvoice"}),
            forInvoice: generateStatusTemplate,
            protocolActions: (rowData: Protocol) => <Button label={f({id: "view"})} onClick={(e) => {
                setSelectedProtocolForDetails(rowData);
                actionsOp.current?.toggle(e)
            }}/>

        }
    }

    const getSpecialFilters = () => {
        return {
            protocolType: (options: any) => <Dropdown showClear value={options.value}
                                                      optionValue={"id"} optionLabel={"description"}
                                                      options={protocolOptions}
                                                      placeholder={f({id: 'choose'})}
                                                      onChange={(e) => options.filterApplyCallback(e.value)}
                                                      style={{textAlign: "left"}}/>,
            checked: (options: any) => <Dropdown showClear value={options.value} filter={true}
                                                 optionValue={"id"} optionLabel={"description"}
                                                 options={UtilService.yesNoOptions}
                                                 placeholder={f({id: 'choose'})}
                                                 onChange={(e) => options.filterApplyCallback(e.value)}
                                                 style={{textAlign: "left"}}/>,
            clientFirmName: (options: any) => <Dropdown showClear value={options.value} filter={true}
                                                        optionValue={"id"} optionLabel={"description"}
                                                        options={firmOptions}
                                                        placeholder={f({id: 'choose'})}
                                                        onChange={(e) => {
                                                            options.filterApplyCallback(e.value)
                                                        }}
                                                        style={{textAlign: "left"}}/>,
            protocolActions: (options: any) => <Dropdown showClear value={options.value} filter={true}
                                                         optionValue={"id"} optionLabel={"description"}
                                                         options={protocolActionOptions}
                                                         placeholder={f({id: 'choose'})}
                                                         onChange={(e) => {
                                                             options.filterApplyCallback(e.value)
                                                         }}
                                                         style={{textAlign: "left"}}/>,
        }
    }

    const onDateChange = (dates: Date[]) => {
        if (!dates[0] || !dates[1]) return;
        setFilterDates(dates);
    }

    const generateEmployeesTemplate = (rowData: Protocol) => {
        if (rowData.employees && rowData.employees.length > 0) {
            let buttonLabel = rowData.employees[0].employeeName?.toString();
            if (rowData.employees.length > 1) buttonLabel += " " + f({id: "andMore"}, {count: rowData.employees.length - 1});
            return <Button label={buttonLabel} className={"w-full"} onClick={(e) => {
                setSelectedProtocolForDetails(rowData);
                employeesOp.current?.toggle(e)
            }}/>
        } else {
            return <label>{f({id: "noEmployees"})}</label>
        }
    }

    const handleExternalFilter = {
        employees: (rowData: Protocol, filterValue: string) => {
            return rowData.employees.some(el => el.employeeName.includes(filterValue));
        },
        protocolActions: (rowData: Protocol, filterValue: any) => {
            return rowData.protocolActions.some(el => el.protocolActionId === filterValue)
        }
    }

    const showProtocolNotes = async () => {
        if (!selectedProtocol) return;
        const currentProtocolNotes = await protocolService.getProtocolNotes(selectedProtocol?.protocolId)
        showDialog({
            newContent: <Notes id={selectedProtocol?.protocolId!} notes={currentProtocolNotes}
                               handleCreate={(id: number, note: string) => protocolService.addNote(id, note)}/>,
            heading: f({id: "notes"}),
            onHideCallback: () => {
                setSelectedOperation(undefined);
                setDtRefresher(new Date().getTime());
            }
        })
    }

    return <>

        <div className={"flex p-d-grid"}>
            <Button
                type="button"
                icon="pi pi-caret-down"
                label={f({id: "filterPeriod"})}
                onClick={(e) => op.current?.toggle(e)}
                aria-haspopup
                aria-controls="overlay_panel"
                className="select-product-button col-1 mr-2"
            />

            {allowAction(["ProtocolsAdd"]) && <Button
                type="button"
                icon="pi pi-plus"
                tooltip={f({id: "create"})}
                onClick={(e) => showCreateForm()}
                style={{width: "2.5rem"}}
            />}

            <label style={{textAlign: "center", fontSize: "1.5rem", fontWeight: "bold"}}
                   className={"col-8"}>{f({id: "protocols"})}</label>

            {allowAction(['ProtocolActionAdd']) && <Button
                type="button"
                icon="fal fa-wrench-simple"
                tooltip={f({id: "addAction"})}
                tooltipOptions={{position: "bottom"}}
                onClick={(e) => showCreateProtocolAction()}
                style={{marginLeft: "auto"}}
            />}

        </div>

        <OverlayPanel ref={employeesOp} showCloseIcon pt={{content: {style: {width: '30vw'}}}}>
            <ReactiveTable data={selectedProtocolForDetails?.employees || []}
                           columnOrder={["employeeName", "userName"]}
                           showFilters={false}/>
        </OverlayPanel>

        <OverlayPanel ref={actionsOp} showCloseIcon pt={{content: {style: {minWidth: '15vw'}}}}>
            <ul>
                {selectedProtocolForDetails?.protocolActions.map(el => {
                    return <li>{el.protocolActionName}</li>
                })}
            </ul>
        </OverlayPanel>

        {operationsTooltip}
        <OverlayPanel
            ref={op}
            showCloseIcon
            id="overlay_panel"
            className="overlaypanel-demo"
        >
            <PeriodFilter onDateChange={onDateChange} filterDate={filterDates}/>
        </OverlayPanel>

        {firmOptions &&
            <ReactiveTable data={gridData}
                           refresher={dtRefresher}
                           selectionMode={'single'}
                           selectionKey={"protocolId"}
                           setSelected={(e) => {
                               if(e !== null) {
                                   setSelectedProtocol(e)
                               }
                           }}
                           columnOrder={['dateTime', 'clientFirmName', 'vehicleLpn', 'oldDeviceImei', 'newDeviceImei', "checked", 'employees', 'invoiceNumber', 'forInvoice', 'protocolType', 'protocolActions', 'operations']}
                           columnStyle={{operations: {body: {}, header: {width: '100px'}}}}
                           ignoreFilters={['operations']}
                           sortableColumns={['clientFirmName']}
                           columnTemplate={getColumnTemplate()}
                           specialFilters={getSpecialFilters()}
                           externalFilters={handleExternalFilter}
                           frozenColumns={['operations']}
                           onFilterCb={onFilterCb}
                           footerTemplate={footerTemplate}
                           dtProps={{
                               onDoubleClick: () => setSelectedOperation("preview")
                           }}
            />
        }

        {showLogsDialog &&
            <Dialog
                header={f({id: "historyFor"}, {type: f({id: "protocols"}), identifier: selectedProtocol?.vehicleLpn})}
                onHide={() => {
                    setSelectedOperation(undefined);
                    setShowLogsDialog(false);
                }} visible={showLogsDialog}>
                <LogTable type={"protocol"} id={selectedProtocol?.protocolId}/>
            </Dialog>
        }

    </>
};

export default NewProtocols
