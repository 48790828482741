import * as React from 'react';
import {useIntl} from "react-intl";
import './Navigation.css'


interface Props {
}

export const RightSideBar :  React.FC<Props> = (props) => {
    const {formatMessage : f} = useIntl();

    return <>
        <div id={"right-sidebar"} className={"right-sidebar"}>
        </div>
    </>
};
