import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Calendar} from "primereact/calendar";
import {FloatLabel} from "primereact/floatlabel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ProtocolService} from "../../services/protocolService";
import protocols from "./Protocols";
import {useDialog} from "../../lib/hooks/useDialog";
import {useToast} from "../../lib/hooks/useToast";

interface Props {
    protocolId: number;
    invoiceNumber?: string;
}

const ProtocolInvoiceNumber : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const protocolService = new ProtocolService();
    const {hideDialog} = useDialog();
    const {showToast} = useToast();

    const [invoiceNumber, setInvoiceNumber] = useState<string>();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if(!props.invoiceNumber) return;
        setInvoiceNumber(props.invoiceNumber);
    }, [props.invoiceNumber])

    const handleSubmit = () => {
        if(!invoiceNumber) return;

        protocolService.changeProtocolInvoiceNumber(props.protocolId, invoiceNumber)
            .then(resp => {
                if(resp) {
                    hideDialog();
                    showToast("success", f({id: "done"}), f({id: "protocolInvoiceNumberChangedSuccessfully"}));
                }else {

                }
        })
    }

    return <>
        <div className={"flex grid justify-content-center align-items-center"}>
            <div className={"col-12"}>
                <FloatLabel>
                    <InputText className={"w-full"} id={"invoiceNumber"} value={invoiceNumber}
                               onChange={(e) => setInvoiceNumber(e.target.value)}/>
                    <label htmlFor={'invoiceNumber'}>{f({id: "invoiceNumber"})}</label>
                </FloatLabel>
            </div>

            <div className={"col-12 justify-content-between flex"}>
                <Button className={"col-5"} label={f({id: "save"})} onClick={handleSubmit}/>
                <Button className={"col-5 p-button-danger"} label={f({id: "cancel"})} onClick={hideDialog}/>
            </div>
        </div>
    </>
};

export default ProtocolInvoiceNumber
