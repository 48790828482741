import {createContext, useState} from 'react';

export const WindowsContext = createContext<any>(null);

export const WindowsContextProvider = (props : any) => {
    const [mountedWindows, setMountedWindows] = useState<string[]>([]);

    const addWindow = (path: string) => {
        const tempMountedWindows = [...mountedWindows];
        tempMountedWindows.push(path);
        setMountedWindows(tempMountedWindows);
    }

    const popWindow = () => {
        let tempMountedWindows = [...mountedWindows];
        tempMountedWindows = tempMountedWindows.slice(0, -1);
        setMountedWindows(tempMountedWindows);
    }

    const isComponentMounted = (path: string) => mountedWindows.includes(path);


    return<>
        <WindowsContext.Provider value={{ addWindow, popWindow, setMountedWindows, isComponentMounted, mountedWindows }}>
            {props.children}
        </WindowsContext.Provider>
    </>
};
