import React, {useEffect, useState} from 'react';
import {TabMenu} from "primereact/tabmenu";
import {SettingsTab} from "./tabs/SettingsTab";
import {useIntl} from "react-intl";
import {HomeTab} from "./tabs/HomeTab";

interface Props {
    content: any
}

export const SettingsSidebar: React.FC<Props> = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [renderedComponent, setRenderedComponent] = useState<any>(null);
    const {formatMessage : f } = useIntl();
    const tabs = [
        {label: f({id: "home"}), icon: 'pi pi-fw pi-home'},
        {label: f({id: "settings"}), icon: 'pi pi-fw pi-cog'}
    ];

    useEffect(() => {
        switch (selectedTab){
            case 0:{
                setRenderedComponent(<HomeTab content={props.content} />);
                break;
            }
            case 1: {
                setRenderedComponent(<SettingsTab />);
                break;
            }
        }
    }, [selectedTab, props.content]);

    return <>
        <TabMenu model={tabs} activeIndex={selectedTab} onTabChange={(e) => setSelectedTab(e.index)} />
        {renderedComponent}
    </>
}
