import {FormElementValues} from "@xal3xfx/react-utils";
import {UtilService} from "../../services/utilService";

const dateTime: FormElementValues<'calendar'> = {
    type: "calendar",
    label: 'dateTime',
    props: {showTime: true},
}

const clientFirmId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'clientFirm',
    props: {virtualScrollerOptions: {itemSize: 30}},
    options: []
}

const vehicleId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'vehicle',
    props: {virtualScrollerOptions: {itemSize: 30}},
    options: []
}

const oldDeviceId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'oldDevice',
    props: {virtualScrollerOptions: {itemSize: 30}},
    options: []
}

const newDeviceId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'newDevice',
    props: {virtualScrollerOptions: {itemSize: 30}},
    options: []
}

const employeeIds: FormElementValues<'multiselect'> = {
    type: "multiselect",
    label: 'employeeIds',
    props: {filter: true},
    options: []
}

const protocolTypeId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'protocolTypeId',
    props: {},
    options: UtilService.protocolTypeOptions
}

const protocolActionIds: FormElementValues<'multiselect'> = {
    type: "multiselect",
    label: 'protocolAction',
    props: {filter: true},
    options: []
}


export const formElements = {
    protocolTypeId, employeeIds, newDeviceId, oldDeviceId, vehicleId, clientFirmId, dateTime, protocolActionIds
}
