import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {DynamicForm} from "@xal3xfx/react-utils";
import {formElements} from "./credit-debit-note-form";
import {ICreateCreditOrDebitNote} from "../../../lib/types/types";
import {InvoiceService} from "../../../services/invoiceService";
import {useDialog} from "../../../lib/hooks/useDialog";

interface Props {
    invoiceId: number;
    isCredit: boolean;
}

const initialValues: ICreateCreditOrDebitNote = {
    count: 0,
    opinion: "",
    price: 0,
    dateTime: new Date()
}

const CreateCreditAndDebitNote: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {hideDialog} = useDialog();

    const invoiceService = new InvoiceService();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleCreate = (data: ICreateCreditOrDebitNote) => {
        return new Promise<boolean>((resolve, reject) => {
            if(props.isCredit) {
                invoiceService.createCreditNote(data, props.invoiceId)
                    .then(resp => {
                        resolve(true);
                        hideDialog();
                    }).catch(reject)
            }else {
                invoiceService.createDebitNote(data, props.invoiceId)
                    .then(resp => {
                        resolve(true)
                        hideDialog();
                    }).catch(reject)
            }
        })

    }

    return <>
        <DynamicForm formElements={formElements} isUpdate={false} onCreate={handleCreate}
                     rowClassName={"col-12 md:col-6 mt-3"}
                     onUpdate={() => Promise.resolve(true)} initialValues={initialValues}
                     fieldOrder={['price', 'count', 'dateTime', 'opinion']} onCancelUpdate={() => 0}/>
    </>
};

export default CreateCreditAndDebitNote
