import * as React from 'react';
import {useState} from 'react';
import {App} from "../App";
import {UserContextProvider} from "./context/UserContext";
import {AxiosInterceptor} from "./AxiosInterceptor";
import {ToastContextProvider} from "./context/ToastContext";
import {WindowsContextProvider} from "./context/WindowsContext";
import {IntlProvider} from 'react-intl';
import messages_bg from "./textProperties/bg.json"
import {DialogContextProvider} from "./context/DialogContext";
import {PreloaderContextProvider} from "./context/PreloaderContext";
import {ConfirmDialogContextProvider} from "./context/ConfirmDialogContext";
import {GlobalEventListenerContextProvider} from "./context/GlobalEventListenerContext";
import {BrowserRouter} from 'react-router-dom';
import {Key, SWRConfig, SWRHook} from "swr";
import axios from "axios";
import {PermissionsContextProvider} from "./context/PermissionsContext";
import {PrimeReactProvider} from "primereact/api";

interface Props {
}

export const AppProxy: React.FC<Props> = (props) => {
    const [interceptorReady, setInterceptorReady] = useState(false);
    const [messages, setMessages] = useState({'bg': messages_bg});
    const [language, setLanguage] = useState('bg');

    const handleInterceptorReady = () => {
        setInterceptorReady(true);
    };

    const proxyMiddleware = (next: SWRHook) => {
        return (key: Key, fetcher: any, config: any) => {
            const extendedFetcher = (...args: any) => {
                args.push(true);
                return fetcher(args);
            }

            return next(key, extendedFetcher, config);
        }
    }


    return <>
        <PrimeReactProvider value={{ripple: true}}>
            <BrowserRouter>
                <UserContextProvider>
                    <IntlProvider locale={"bg-BG"} messages={messages.bg}>
                        <WindowsContextProvider>
                            <PermissionsContextProvider>
                                <GlobalEventListenerContextProvider>
                                    <ToastContextProvider>
                                        <ConfirmDialogContextProvider>
                                            <PreloaderContextProvider>
                                                <AxiosInterceptor callback={handleInterceptorReady}>
                                                        <SWRConfig value={{
                                                            fetcher: ([url, isSWR]) => axios(url, {data: {isSWR}}).then(response => response.data),
                                                            loadingTimeout: 500,
                                                            use: [proxyMiddleware]
                                                        }}>
                                                            <DialogContextProvider>
                                                                {interceptorReady ? <App/> : null}
                                                            </DialogContextProvider>
                                                        </SWRConfig>
                                                </AxiosInterceptor>
                                            </PreloaderContextProvider>
                                        </ConfirmDialogContextProvider>
                                    </ToastContextProvider>
                                </GlobalEventListenerContextProvider>
                            </PermissionsContextProvider>
                        </WindowsContextProvider>
                    </IntlProvider>
                </UserContextProvider>
            </BrowserRouter>
        </PrimeReactProvider>
    </>
};
