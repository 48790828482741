import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef, useContext, ReactElement} from 'react';
import {IClientFirm} from "../../../lib/types/types";
import {Card} from "primereact/card";
import {useLocation, useParams} from "react-router-dom";
import {ClientFirmService} from "../../../services/clientFirmService";
import {Divider} from "primereact/divider";
import {TabMenu} from "primereact/tabmenu";
import {PermissionContext} from "../../../lib/context/PermissionsContext";
import NewProtocols from "../../protocols/NewProtocols";
import FirmVehicles from "./FirmVehicles";
import Invoices from "../../invoices/Invoices";
import Invoicing from "./invoicing/Invoicing";

interface Props {
}


const FirmDetails : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const location = useLocation();
    const clientFirmService = new ClientFirmService();
    const {getMenuOptionsForUser} = useContext(PermissionContext);
    const params: {clientFirmId: string} = useParams();

    const [selectedFirm, setSelectedFirm] = useState<IClientFirm>()

    const [clientFirmEmail, setClientFirmEmail] = useState<string>();
    const [clientFirmPhone, setClientFirmPhone] = useState<string>()
    const [selectedComponentToRender, setSelectedComponentToRender] = useState<"vehicles" | "invoices" | "protocols" | "invoicing" | "accounts">('vehicles');

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        const state = params.clientFirmId;
        if(!state) return;
        clientFirmService.getClientFirmById(Number.parseInt(state))
            .then(resp => setSelectedFirm(resp))

        clientFirmService.getFirmMails(Number.parseInt(state))
            .then(resp => {
                if(resp && resp.length > 0) setClientFirmEmail(resp[0].emailOwner + " - " + resp[0].email)
            })

        clientFirmService.getFirmPhones(Number.parseInt(state))
            .then(resp => {
                if(resp && resp.length > 0) setClientFirmPhone(resp[0].ownerName  + " - " + resp[0].phoneNumber)
            })
    }, [params])

    const items = getMenuOptionsForUser([
        {
            label: f({id: "vehicles"}),
            icon: 'fal fa-folder-open',
            command: () => setSelectedComponentToRender('vehicles'),
            key: ['Vehicles']
        },
        {
            label: f({id: "invoices"}),
            icon: 'fal fa-box-archive',
            command: () => setSelectedComponentToRender('invoices'),
            key: ['Invoice']
        },
        {
            label: f({id: "protocols"}),
            icon: 'fal fa-inbox',
            command: () => setSelectedComponentToRender('protocols'),
            key: ['Protocols']
        },
        {
            label: f({id: "invoicing"}),
            icon: 'fal fa-inbox',
            command: () => setSelectedComponentToRender('invoicing'),
            key: ['InvoiceGroups']
        },
        {
            label: f({id: "accounts"}),
            icon: 'fal fa-inbox',
            command: () => setSelectedComponentToRender('accounts')
        }
    ]);

    const renderSelectedComponent = () => {
        if(!selectedFirm) return;
        switch (selectedComponentToRender) {
            case 'vehicles' : {
                return <FirmVehicles clientFirmId={selectedFirm?.clientFirmId} />
            }
            case 'invoices' : {
                return <Invoices clientFirmId={selectedFirm?.clientFirmId}/>
            }
            case 'protocols' : {
                return <NewProtocols clientFirmId={selectedFirm.clientFirmId}/>
            }
            case 'invoicing' : {
                return <Invoicing clientFirmId={selectedFirm?.clientFirmId} clientFirmName={selectedFirm?.clientFirmName}/>
            }
            case 'accounts' : {
                return <></>
            }
        }
    }

    return <>
        <Card className={"p-3"}>
            <div className={"font-medium text-3xl text-900 mb-3"}>{selectedFirm?.clientFirmName}</div>
            <div className={"grid grid-nogutter border-top-1 surface-border pt-2 justify-center"}>
                <div className={"col-12 md:col-6 p-3"}>
                    <div className={"text-500 font-medium mb-2"}>{f({id: "mol"})}</div>
                    <div className={"text-900 text-xl"}>{selectedFirm?.mol}</div>
                </div>

                <div className={"col-12 md:col-6 p-3"}>
                    <div className={"text-500 font-medium mb-2"}>{f({id: "bulstat"})}</div>
                    <div className={"text-900 text-xl"}>{selectedFirm?.bulstat}</div>
                </div>

                <div className={"col-12 md:col-6 p-3"}>
                    <div className={"text-500 font-medium mb-2"}>{f({id: "address"})}</div>
                    <div className={"text-900 text-xl"}>{selectedFirm?.address}</div>
                </div>

                <div className={"col-12 md:col-6 p-3"}>
                    <div className={"text-500 font-medium mb-2"}>{f({id: "email"})}</div>
                    <div className={"text-900 text-xl"}>{clientFirmEmail ?? f({id: "noEmail"})}</div>
                </div>

                <div className={"col-12 md:col-6 p-3"}>
                    <div className={"text-500 font-medium mb-2"}>{f({id: "phone"})}</div>
                    <div className={"text-900 text-xl"}>{clientFirmPhone ?? f({id: "noPhone"})}</div>
                </div>
            </div>

            <Divider/>

            <TabMenu model={items} className={"text-xl"}/>
            {renderSelectedComponent()}
        </Card>
    </>
};

export default FirmDetails
