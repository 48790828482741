import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useUserInfo} from "../../lib/hooks/useUserInfo";
import {EmployeeDto, InternalTaskDto} from "../../services/backend-api";
import useSWR, {mutate} from "swr";
import {SelectButton} from "primereact/selectbutton";
import {SelectItemOptionsType} from "primereact/selectitem";
import {InternalTasksGrid} from "./InternalTasksGrid";
import {InputText} from "primereact/inputtext";
import InternalTask from "./InternalTask";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Badge} from "primereact/badge";

interface Props {

}

export const InternalTasks: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const {userInfo} = useUserInfo();
    const isAdmin = userInfo.username === "admin";

    const [selectedTask, setSelectedTask] = useState<InternalTaskDto>();
    const [showTaskDialog, setShowTaskDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [tasksToShow, setTasksToShow] = useState<"all" | "assignedToMe" | "createdByMe" | 'unread'>("all");
    //Filtered tasks by `tasksToShow`
    const [filteredTasksByType, setFilteredTasksByType] = useState<InternalTaskDto[]>([]);
    //Filtered `filteredTasksByType` by `searchQuery`
    const [filteredTasksByQuery, setFilteredTasksByQuery] = useState<InternalTaskDto[]>([]);

    const [tasksCountMap, setTasksCountMap] = useState<{
        all: number;
        assignedToMe: number;
        createdByMe: number;
        unread: number;
    }>({
        all: 0,
        assignedToMe: 0,
        createdByMe: 0,
        unread: 0
    })


    const {data : tasks} = useSWR<InternalTaskDto[], Error>(isAdmin ? "/internaltasks" : "/internaltasks/owninternaltasks");
    const {data: employees} = useSWR<EmployeeDto[], Error>("/employee");

    const tasksToShowButtonOptions : SelectItemOptionsType= [
        {id: 1, name: f({id: 'all'}), value: "all"},
        {id: 2, name: f({id: 'assignedToMe'}), value: "assignedToMe"},
        {id: 3, name: f({id: 'createdByMe'}), value: "createdByMe"},
        {id: 4, name: f({id: 'unread'}), value: "unread"},
    ];

    useEffect(() => {
        if(!employees || !tasks) return;
        const meAsEmployee = employees.find(el => el.userName == userInfo.username);
        setTasksCountMap({
            all: tasks?.length,
            assignedToMe: tasks?.filter(el => (el.assignedEmployees || []).some(e => e.userName === userInfo.username)).length,
            createdByMe: tasks?.filter(el => el.createdByEmployeeId === meAsEmployee?.employeeId).length,
            unread: tasks?.filter(el => !el.open).length
        })
    }, [tasks, employees])

    useEffect(() => {
        if(!tasks || !employees) return;

        const meAsEmployee = employees.find(el => el.userName == userInfo.username);
        if(!meAsEmployee && !isAdmin)
            return;

        switch (tasksToShow){
            case "all": {
                setFilteredTasksByType(tasks);
                break;
            }
            case "assignedToMe": {
                setFilteredTasksByType(tasks.filter(el => (el.assignedEmployees || []).some(e => e.userName === userInfo.username)));
                break;
            }
            case "createdByMe": {
                setFilteredTasksByType(tasks.filter(el => el.createdByEmployeeId === meAsEmployee?.employeeId));
                break;
            }
            case "unread": {
                setFilteredTasksByType(tasks.filter(el => !el.open));
                break;
            }
        }
    }, [tasksToShow, tasks, employees]);

    useEffect(() => {
        if(searchQuery === ""){
            setFilteredTasksByQuery(filteredTasksByType);
            return;
        }

        const filteredTasks = filteredTasksByType.filter(task => {
           if(task.tittle?.toLowerCase().includes(searchQuery.toLowerCase()))
               return true;

           if(task.assignedEmployees?.some(emp => emp.userName?.includes(searchQuery)))
               return true;

           return false;
        });

        setFilteredTasksByQuery(filteredTasks);

    }, [searchQuery, filteredTasksByType]);

    useEffect(() => {
    }, [filteredTasksByType]);



    const handleTaskClicked = (task: InternalTaskDto) => {
        setShowTaskDialog(true);
        setSelectedTask(task);
    }

    const handleCloseTaskDialog = () => {
        setShowTaskDialog(false);
        setSelectedTask(undefined);
        mutate(isAdmin ? "/internaltasks" : "/internaltasks/owninternaltasks");
    }

    const getSelectButtonTemplate = (item: any) => {
        const badge = tasksToShow === item.value ? <Badge value={tasksCountMap![item.value as keyof typeof tasksCountMap]}/> : <Badge severity={"info"} value={tasksCountMap![item.value as keyof typeof tasksCountMap]}/>
        return <div>
            <span className={"mr-2"}>{item.name}</span>
            {badge}
        </div>
    }

    return <>
        <h1 className={"text-center"}>{f({ id: "internalTasks" })}</h1>
        <SelectButton value={tasksToShow} onChange={(e) => setTasksToShow(e.value)} optionLabel="name" options={tasksToShowButtonOptions} itemTemplate={getSelectButtonTemplate}/>
        <div className={'flex mt-5 gap-3'}>
            <InputText placeholder={f({id: 'search'})} value={searchQuery} onChange={e => setSearchQuery(e.target.value)}  />
            <Button label={f({id: "createNewTask"})} icon={"pi pi-plus"} onClick={() => setShowTaskDialog(true)}/>
        </div>
        <InternalTasksGrid onTaskClicked={handleTaskClicked} tasks={filteredTasksByQuery || []} />

        <Dialog header={f({id: selectedTask ? "previewTask" :  "createNewTask"})} visible={showTaskDialog} onHide={handleCloseTaskDialog} breakpoints={{ '960px': '65vw', '641px': '100vw' }}>
            <InternalTask closeDialog={() => setShowTaskDialog(false)} internalTaskId={selectedTask?.internalTaskId}/>
        </Dialog>
    </>
};

