import {Button} from 'primereact/button'
import {Toolbar} from 'primereact/toolbar'
import {useHistory} from 'react-router-dom'

interface Props {
    goBack: () => void;
    prevComponent: string;
}

export const BackTopBar: React.FC<Props> = (props) => {
    const {goBack} = useHistory();


    const goBackHandler = () => {
        props.goBack();
        goBack();
    }

    return <>
        <Toolbar left={<Button icon={"pi pi-arrow-left"} label={props.prevComponent} onClick={goBackHandler} />} />
    </>


}
