import * as React from "react";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {formElements} from './create-invoice-form'
import {validationSchema} from './validation'
import {InvoiceService} from "../../../services/invoiceService";
import {ICreateInvoice} from "../../../lib/types/types";
import {useDialog} from "../../../lib/hooks/useDialog";
import {ClientFirmService} from "../../../services/clientFirmService";
import {useDynamicForm} from "../../../lib/hooks/useDynamicForm";

interface Props {
}

const initialValue: ICreateInvoice = {
    clientFirmId: 0,
    startDate: new Date()
}

export const CreateInvoice : React.FC<Props> = (props) => {
    const { formatMessage: f } = useIntl();
    const { hideDialog } = useDialog();

    const [formElementsState, setFormElementsState] = useState<any>(formElements);

    const clientFirmService = new ClientFirmService();
    const invoiceService = new InvoiceService();
    const dynamicForm = useDynamicForm();

    useEffect(() => {
        clientFirmService.getClientFirmsDropdown(false).then(resp => {
            const formElementsTemp = {...formElementsState};
            formElementsTemp.clientFirmId.options = resp;
            setFormElementsState(formElementsTemp);
        });
    }, [])


    const createHandler = (data: ICreateInvoice) => {
        return new Promise<boolean>((resolve, reject) => {
            invoiceService.createInvoice(data).then((res) => {
                resolve(true);
                hideDialog();
            }).catch(reject);
        });
    }

    const getForm = () => {

        return dynamicForm({
            className: "col-12 md:col-6",
            fieldOrder: ['clientFirmId', 'startDate'],
            initialValues: initialValue,
            onFormReset: () => 0,
            onCreate: createHandler,
            onUpdate: () => Promise.resolve(true),
            isUpdate: false,
            onCancelUpdate: () => 0,
            validationSchema: validationSchema,
            formElements: formElementsState
        })
    }


    return <>
        {getForm()}
    </>
}
