import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ILog, ITableLog} from "../types/types";
import {LogService} from "../../services/logService";
import {ReactiveTable} from "react-ive-tables";
import moment from "moment";

export type LogType = "all" | "device" | "vehicle" | "clientFirm" | "tariff" | "simCard" | "employee" | "internalTask" | 'user' | 'protocol';
interface Props {
    type: LogType
    id? : number;
}


const LogTable : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const logService = new LogService();

    const [logs, setLogs] = useState<ITableLog[]>();

    const parseData = (data: ILog[]) => {
        if(!data) return;

        let result: ITableLog[] = [];
        data.sort((rec1, rec2) => moment(rec1.dateTime).isBefore(moment(rec2.dateTime)) ? 1 : 0).forEach(el => {
            const {oldValues, newValues, dateTime, userName} = el;
            let parsedOldValues: Object = {};
            let parsedNewValues: Object = {};
            if(oldValues) parsedOldValues = JSON.parse(oldValues);
            if(newValues) parsedNewValues = JSON.parse(newValues);

            if(!oldValues && newValues) {
                const createRecord : ITableLog = {dateTime: moment.utc(dateTime).local().format("YYYY-MM-DD HH:mm:ss"), parameter: f({id: "created"}), oldValues: "", newValues: "", userName};
                result.push(createRecord)
            }else {
                Object.keys(parsedOldValues).forEach(el => {
                    if(el !== 'ModfiedDate'){
                        //@ts-ignore
                        const currentRecord: ITableLog = {dateTime: moment.utc(dateTime).local().format("YYYY-MM-DD HH:mm:ss"), parameter: f({id: decapitalize(el)}), newValues: parsedNewValues[el] as string, oldValues: parsedOldValues[el], userName};
                        //@ts-ignore
                        result.push(currentRecord);
                    }
                })
            }

        })
        setLogs(result);
    }

    const decapitalize = (str: string) => {
        return str[0].toLowerCase() + str.substring(1);
    }

    useEffect(() => {
        switch (props.type) {
            case "all": {
                logService.getAllLogs()
                    .then(resp => parseData(resp));
                break;
            }
            case "clientFirm": {
                logService.getClientFirmLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "device": {
                logService.getDeviceLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "employee": {
                logService.getEmployeeLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "internalTask": {
                logService.getInternalTaskLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "simCard": {
                logService.getSimCardLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "tariff": {
                logService.getTariffLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "vehicle": {
                logService.getVehicleLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "user": {
                logService.getUserLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
            case "protocol": {
                logService.getProtocolLogs(props.id)
                    .then(resp => parseData(resp));
                break;
            }
        }
    }, [props.type, props.id]);


    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
        <div style={{width: "100%"}}>
            <ReactiveTable columnOrder={["dateTime", "parameter", "oldValues", "newValues", "userName"]} data={logs}/>
        </div>
    </>
};

export default LogTable
