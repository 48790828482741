import {ILog} from "../lib/types/types";
import axios from "axios";

export class LogService {
    service = "audits/"

    async getAllLogs() {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service)
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getDeviceLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "device/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getVehicleLogs(id?: number) {
        console.log("THE ID IS: ", id);
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "vehicle/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getClientFirmLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "clientFirm/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getTariffLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "tariff/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getSimCardLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "simCard/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getEmployeeLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "employee/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getUserLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "user/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getInternalTaskLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "internalTask/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }

    async getProtocolLogs(id?: number) {
        return new Promise<ILog[]>((resolve, reject) => {
            axios.get<ILog[]>(this.service + "protocol/" + (id || "0"))
                .then(resp => {
                    resolve(resp.data);
                }).catch()
        })
    }
}
