import React from 'react';
import ReactDOM from 'react-dom/client';
import './lib/styles/index.css';
import {AppProxy} from "./lib/AppProxy";

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <AppProxy />
  </React.StrictMode>,
);
