import * as React from 'react';
import {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {ITariff} from "../../lib/types/types";
import {ReactiveTable} from "react-ive-tables";
import {UtilService} from "../../services/utilService";
import {useDialog} from "../../lib/hooks/useDialog";
import {CreateOrEditTariff} from "./CreateOrEditTariff";
import {ClientFirmService} from "../../services/clientFirmService";
import {useToast} from "../../lib/hooks/useToast";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import styled from "styled-components";
import {Button} from "primereact/button";


interface Props {
    showMultipleAssign: () => void;
    clientFirmTariffs: ITariff[];
    clientFirmId: number;
    tableHeight?: number;
    clientFirmName: string;
    tariffCreatedOrUpdatedCallback: () => void
}

const HeaderWrapper = styled.div`
  display: flex;
`

const HeaderText = styled.h2`
  margin-left: auto;
  margin-right: auto;
`

const ButtonsContainer = styled.h2`
  display: flex;
  align-items: center;
`

export const TariffsForFirm: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const clientFirmService = new ClientFirmService();

    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();
    const {showDialog, isShown: isSidebarShown} = useDialog();
    const [selectedTariff, setSelectedTariff] = useState<ITariff>();
    const [tariffTypeOptions, setTariffTypeOptions] = useState([]);
    const [selectionResetter, setSelectionResetter] = useState(0);

    useEffect(() => {
        if (props.clientFirmTariffs && props.clientFirmTariffs.length > 0) {
            const tariffTypes = props.clientFirmTariffs.map(el => el.tarifType);
            setTariffTypeOptions(UtilService.generateDropdownOptionsFromData(tariffTypes, 'tarifTypeId', 'tarifTypeName'))
        }
    }, [props.clientFirmTariffs]);

    useEffect(() => {
        setSelectedTariff(undefined);
        setSelectionResetter(Date.now());
    }, [props.clientFirmId]);


    const createOrEditTariff = (edit: boolean) => {
        if (edit && !selectedTariff) return
        showDialog({
            newContent: <CreateOrEditTariff edit={edit}
                                            clientFirmId={props.clientFirmId}
                                            onSubmitCallback={onSubmitCallback}
                                            selectedTariff={edit ? selectedTariff : undefined}/>,
            heading: <div> {f({id: edit ? "editTariff" : "createTariff"})} <i className={'fal fa-building fa-sm'}/>
            </div>,
            onHideCallback: props.tariffCreatedOrUpdatedCallback,
            style: {maxWidth: "75vw"}

        });
    }

    const onSubmitCallback = () => {
        props.tariffCreatedOrUpdatedCallback();
        setSelectedTariff(undefined);
        setSelectionResetter(new Date().getTime());
    }

    const deleteTariff = () => {
        if (selectedTariff) {
            //@ts-ignore
            // eslint-disable-next-line react-hooks/rules-of-hooks
            showConfirmDialog({body: f({id: "confirmTarifDelete"}, {tarifName: selectedTariff.tarifName})}).then(answer => {
                if (answer) {
                    clientFirmService.deleteTariff(props.clientFirmId, selectedTariff.tarifId).then(() => {
                        setSelectedTariff(undefined);
                        props.tariffCreatedOrUpdatedCallback();
                        showToast("success", f({id: "done"}), f({id: "tariffDeleted"}));
                    });
                } else {

                }
            })
        }
    }

    const handleMultipleAssign = (e: any) => {
        props.showMultipleAssign();
    }

    const menuModel = [
        {label: f({id: "add"}), icon: 'pi pi-plus', command: createOrEditTariff.bind(undefined, false)},
        {label: f({id: "edit"}), icon: 'pi pi-pencil', command: createOrEditTariff.bind(undefined, true), className: "p-button-warning", disabled: !selectedTariff},
        {label: f({id: "delete"}), icon: 'pi pi-trash', command: deleteTariff, disabled: !selectedTariff, className: "p-button-danger"},
    ];

    const getHeaderButtons = () => {
        return menuModel.map(el => {
            return {
                onClick: el.command,
                icon: el.icon,
                className: '',
                tooltipLabel: el.label,
                disabled: el.disabled
            }
        }).concat({
            // @ts-ignore
            onClick: handleMultipleAssign,
            icon: 'fal fa-layer-group',
            className: '',
            tooltipLabel: 'Multiple Assign'
        })
    }

    const getHeader = () => {
        let fullMenuModel = [...menuModel, {
            command: handleMultipleAssign,
            icon: 'fal fa-layer-group',
            className: '',
            label: 'Multiple Assign',
            disabled: false
        }]
        return <HeaderWrapper>
            <ButtonsContainer>
                {fullMenuModel.map(el => {
                    return <Button className={"mr-2 " + el.className}
                                   onClick={el.command}
                                   icon={el.icon}
                                   tooltip={el.label}
                                   disabled={el.disabled}
                    />
                })}
            </ButtonsContainer>

            <HeaderText>{f({id: "tariffsFor"}, {for: props.clientFirmName})}</HeaderText>
        </HeaderWrapper>
    }


    return <>

        <ReactiveTable data={props.clientFirmTariffs} tableHeight={props.tableHeight + "vh" || "auto"}
            // contextMenu={menuModel}
                       selectionResetter={selectionResetter}
                       columnOrder={['tarifName', 'tarifType.tarifTypeName', 'tarifDescription', 'januaryPrice', 'februaryPrice', 'marchPrice', 'aprilPrice', 'mayPrice', 'junePrice', 'julyPrice', 'augustPrice', 'septemberPrice', 'octoberPrice', 'novemberPrice', 'decemberPrice']}
                       specialLabels={{
                           tarifName: "tariffName",
                           'tarifType.tarifTypeName': "tariffType",
                           tarifDescription: 'description',
                           januaryPrice: 'january',
                           februaryPrice: 'february',
                           marchPrice: "march",
                           aprilPrice: 'april',
                           mayPrice: 'may',
                           junePrice: 'june',
                           julyPrice: 'july',
                           augustPrice: 'august',
                           septemberPrice: 'september',
                           octoberPrice: 'october',
                           novemberPrice: 'november',
                           decemberPrice: 'december'
                       }}
                       selectionMode={'single'}
                       selectionKey={'tarifId'} setSelected={setSelectedTariff}
                       dtProps={{header: getHeader()}}
        />
    </>

};
