import * as React from 'react';
import {createContext, useEffect, useState} from 'react';
import {ExtendedHeaderButton, ExtendedMenuOption, IUserPermission, Permission} from "../types/types";
import {normalComponents, persistentComponents} from '../../routes/routes';
import {SidebarItem} from "@xal3xfx/reactbar";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {useWindows} from "../hooks/useWindows";

interface ExtendedSidebarItem extends SidebarItem {
    key?: Permission;
    children?: ExtendedSidebarItem[]
}

export interface PermissionContextType {
    setPermissions: (permissions: IUserPermission[]) => void;
    persistentComponentsToRender: typeof persistentComponents;
    normalComponentsToRender: typeof normalComponents;
    sidebarItemsToRender: ExtendedSidebarItem[];
    getMenuOptionsForUser: (options: ExtendedMenuOption[]) => ExtendedMenuOption[];
    getHeaderButtonsForUser: (options: ExtendedHeaderButton[]) => ExtendedHeaderButton[];
    allowAction: (requiredPermissions: Permission[]) => boolean
}

export const PermissionContext = createContext<PermissionContextType>({} as PermissionContextType);

export const PermissionsContextProvider = (props: any) => {
    const {formatMessage: f} = useIntl();
    const history = useHistory();
    const {setMountedWindows} = useWindows();

    const [userPermissions, setUserPermissions] = useState<Permission[]>();
    const [persistentComponentsToRender, setPersistentComponentsToRender] = useState<typeof persistentComponents>(persistentComponents);
    const [normalComponentsToRender, setNormalComponentsToRender] = useState<typeof normalComponents>(normalComponents);
    const [sidebarItemsToRender, setSidebarItemsToRender] = useState<ExtendedSidebarItem[]>([])

    const sidebarItems: ExtendedSidebarItem[] = [];
    const home: ExtendedSidebarItem = {
        id: 'home',
        className: "fal fa-home fa-fw",
        label: f({id: "home"}),
        command: () => {
            history.push('/');
        }
    };

    const serverStatus: ExtendedSidebarItem = {
        id: 'serverStatus',
        key: 'ServerStatus',
        className: "fal fa-server fa-fw",
        label: f({id: "serversStatus"}),
        command: () => {
            setMountedWindows(["serverStatus"]);
            history.push('/serverStatus');
        }
    };

    const proxyStatus: ExtendedSidebarItem = {
        id: 'proxyStatus',
        key: 'GpsProgramTcpProxyStats',
        className: "fal fa-network-wired fa-fw",
        label: f({id: "proxyStatus"}),
        command: () => {
            setMountedWindows(["proxyStatus"]);
            history.push('/proxyStatus');
        }
    };

    const devices: ExtendedSidebarItem = {
        id: 'devices',
        key: "Devices",
        className: "fal fa-microchip fa-fw",
        label: f({id: "devices"}),
        command: () => {
            setMountedWindows(["devices"]);
            history.push('/devices');
        }
    };

    const simCards: ExtendedSidebarItem = {
        id: 'simCards',
        key: "SimCards",
        className: "fal fa-sim-card fa-fw",
        label: f({id: "simCards"}),
        command: () => {
            setMountedWindows(["simCards"]);
            history.push('/simCards');
        }
    };

    const employees: ExtendedSidebarItem = {
        id: 'employees',
        key: 'Employee',
        className: "fal fa-users fa-fw",
        label: f({id: "employees"}),
        command: () => {
            setMountedWindows(["employee"]);
            history.push('/employee');
        }
    };

    const register: ExtendedSidebarItem = {
        id: 'register',
        className: "fal fa-list fa-fw",
        label: f({id: "register"}),
        command: () => 0,
        children: [serverStatus, proxyStatus, devices, simCards, employees]

    };

    const clientFirms: ExtendedSidebarItem = {
        id: 'clientFirms',
        key: 'ClientFirms',
        className: "fal fa-id-card fa-fw",
        label: f({id: "clientFirms"}),
        command: () => {
            setMountedWindows(["clientFirms"]);
            history.push('/clientFirms');
        }
    };

    const invoices: ExtendedSidebarItem = {
        id: 'invoices',
        key: 'Invoice',
        className: "fal fa-file-invoice fa-fw",
        label: f({id: "invoices"}),
        command: () => {
            setMountedWindows(["invoices"]);
            history.push('/invoices');
        }
    };

    const vehicles: ExtendedSidebarItem = {
        id: 'vehicles',
        key: 'Vehicles',
        className: "fal fa-truck-container fa-fw",
        label: f({id: "vehicles"}),
        command: () => {
            setMountedWindows(["vehicles"]);
            history.push('/vehicles');
        }
    }

    const unAssociatedVehicles: ExtendedSidebarItem = {
        id: 'unAssociatedVehicles',
        key: 'UnAssociatedVehicles',
        className: "fal fa-link-slash fa-fw",
        label: f({id: "unAssociatedVehicles"}),
        command: () => {
            setMountedWindows(["unAssociatedVehicles"]);
            history.push('/unAssociatedVehicles');
        }
    };


    const accounts: ExtendedSidebarItem = {
        id: 'accounts',
        key: 'Users',
        className: "fal fa-user fa-fw",
        label: f({id: "accounts"}),
        command: () => {
            setMountedWindows(["accounts"]);
            history.push('/accounts');
        }
    }

    const offerConfigurator = {
        id: 'offerConfigurator',
        className: "fal fa-cart-plus fa-fw",
        label: f({id: "offerConfigurator"}),
        command: () => {
            setMountedWindows(["offerConfigurator"]);
            history.push('/offerConfigurator');
        }
    }

    const tools: ExtendedSidebarItem = {
        id: 'tools',
        key: 'Tools',
        className: "fal fa-screwdriver-wrench fa-fw",
        label: f({id: "tools"}),
        command: () => {
            setMountedWindows(["tools"]);
            history.push('/tools');
        }
    }

    const protocols: ExtendedSidebarItem = {
        id: 'protocols',
        key: 'Protocols',
        className: "fal fa-folder-open fa-fw",
        label: f({id: "protocols"}),
        command: () => {
            setMountedWindows(["protocols"]);
            history.push('/protocols');
        }
    }

    sidebarItems.push(home, register, clientFirms, vehicles, unAssociatedVehicles, invoices, accounts, protocols, tools);

    const setPermissions = (permissions: IUserPermission[]) => {
        if (!permissions) return;
        setUserPermissions(permissions.map(el => el.permissionName));
    }

    useEffect(() => {
        let persistentComponentKeysToRender: Permission[] = Object.keys(persistentComponents).filter(el => userPermissions?.includes(el as Permission)) as Permission[];
        setNormalComponentsToRender(prevNormalComponentsToRender => {
            const res = persistentComponentKeysToRender.reduce<any>((acc, el) => {
                return {...acc, [el]: persistentComponents[el as keyof typeof persistentComponents]}
            }, {})
            return res;
        })

        let normalComponentsToRender: Permission[] = Object.keys(normalComponents).filter(el => userPermissions?.includes(el as Permission)) as Permission[];
        setNormalComponentsToRender(prevNormalComponentsToRender => {
            const res = normalComponentsToRender.reduce<any>((acc, el) => {
                return {...acc, [el]: normalComponents[el as keyof typeof normalComponents]}
            }, {})
            return res;
        })

        //This ts-ignore is because the ts does not respect the filter when defining the return type
        //@ts-ignore
        setSidebarItemsToRender(sidebarItems.map(buildSidebarNode).filter(el => el !== undefined));

    }, [userPermissions]);

    const buildSidebarNode = (item: ExtendedSidebarItem): ExtendedSidebarItem | undefined => {
        //If the item has no key -> always push it
        //Or if the item has a key, which the user has access to
        if (!item.key || userPermissions?.includes(item.key)) {
            //If the item has no children -> push the item
            if (!item.children) {
                return item;
            }
            const children: any = item.children.map(buildSidebarNode).filter(el => el !== undefined);
            //If the item has children -> check which should be pushed
            //The children var has any type, because ts does not detect the filter in the types
            return {...item, children: children}
        }
        return undefined;

    }

    const getMenuOptionsForUser = (options: ExtendedMenuOption[]) => {
        return options.filter(option => {
            if (option.key) {
                return option.key.every(k => userPermissions?.includes(k));
            } else return true;
        })
    }

    const getHeaderButtonsForUser = (options: ExtendedHeaderButton[]) => {
        return options.filter(option => {
            if (option.key) {
                return option.key.every(k => userPermissions?.includes(k));
            } else return true;
        })
    }

    const allowAction = (requiredPermissions: Permission[]) => {
        return requiredPermissions.every(k => userPermissions?.includes(k));
    }


    return <>
        <PermissionContext.Provider
            value={{
                setPermissions,
                persistentComponentsToRender,
                normalComponentsToRender,
                sidebarItemsToRender,
                getMenuOptionsForUser,
                getHeaderButtonsForUser,
                allowAction
        }}>
            {props.children}
        </PermissionContext.Provider>
    </>
};
