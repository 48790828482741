import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {ClientFirmService} from "../../../services/clientFirmService";
import styled from "styled-components";
import {ClientFirmVehicleSummary} from "../../../lib/types/types";
import {ReactiveTable} from "react-ive-tables";
import {Card} from "primereact/card";

interface Props {
    clientFirmId: number | undefined
}

const CardWrapper = styled.div<{ hover: boolean; }>`
    &:hover {
        background-color: ${props => props.hover ? "var(--firmBoxHoverColor)" : "var(--firmBoxColor)"};
        transition: background-color 300ms linear;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--firmBoxColor);
    padding: 2rem;
    border-radius: 2rem;
    margin: 1rem;
    font-weight: bold;
    cursor: ${props => props.hover ? "pointer" : "auto"}
`

const FirmVehicles: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const clientFirmService = new ClientFirmService();

    const [modelCountMap, setModelCountMap] = useState<ClientFirmVehicleSummary>()
    const [detailData, setDetailData] = useState<any[]>();
    const [headerText, setHeaderText] = useState<string>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (!props.clientFirmId) return;

        clientFirmService.getVehiclesSummary(props.clientFirmId)
            .then(resp => {
                setModelCountMap(resp)
            })

        // clientFirmService.getClientFirmVehicles(props.clientFirmId)
        //     .then(resp => {
        //         const result = resp.reduce<Record<string, number>>((acc, el) => {
        //             const currentMapping = el.deviceDtos.reduce<Record<string, number>>((deviceAcc, device) => {
        //                 const currentDeviceModel = device.deviceModelName;
        //                 if(deviceAcc[currentDeviceModel] !== undefined) {
        //                     deviceAcc[currentDeviceModel] = deviceAcc[currentDeviceModel] + 1;
        //                     return deviceAcc;
        //                 }else return {...deviceAcc, [currentDeviceModel]: 1};
        //
        //             }, {})
        //
        //             Object.keys(currentMapping).forEach(deviceType => {
        //                 if(acc[deviceType]) {
        //                     acc[deviceType] = acc[deviceType] + currentMapping[deviceType]
        //                 }else {
        //                     acc[deviceType] = currentMapping[deviceType];
        //                 }
        //             })
        //
        //             return acc
        //
        //         }, {})
        //
        //         setModelCountMap(result);
        //     }).catch()

    }, [props.clientFirmId])

    const renderCards = () => {
        if (!modelCountMap) return;
        //TODO: Think of a design, that uses the full screen to display both the cards and the detail table
        return <div className={"col-12 flex flex-row w-full"}>

            <div className={"col-12 md:col-6 flex flex-row"}>
                <div className={"col-12 md:col-3"}>
                    <CardWrapper hover={false}>
                        {/*{`${f({id: "activePlural"})} - ${Object.values(modelCountMap).reduce((acc, el) => acc + el, 0)}`}*/}
                        {`${f({id: "activePlural"})} - ${modelCountMap.activeVehicles}`}
                    </CardWrapper>

                    {Object.keys(modelCountMap.activeDeviceTypesCount).map(modelName => {
                        return <CardWrapper hover={true}
                            onClick={() => showDetails(modelCountMap?.activeDeviceTypesCount[modelName as any].vehicles, `activeDevices-${modelName}`)}>
                            {`${modelName} - ${modelCountMap.activeDeviceTypesCount[modelName as any].count}`}
                        </CardWrapper>
                    })}
                </div>

                <div className={"col-12 md:col-3"}>
                    <CardWrapper hover={false}>
                        {/*{`${f({id: "activePlural"})} - ${Object.values(modelCountMap).reduce((acc, el) => acc + el, 0)}`}*/}
                        {`${f({id: "invoiced"})} - ${modelCountMap.invoicedVehicles}`}
                    </CardWrapper>
                    {/*{Object.keys(modelCountMap.unActiveDeviceTypesCount).map(modelName => {*/}
                    {/*    return <CardWrapper>*/}
                    {/*        {`${modelName} - ${modelCountMap.unActiveDeviceTypesCount[modelName]}`}*/}
                    {/*    </CardWrapper>*/}
                    {/*})}*/}
                </div>

                <div className={"col-12 md:col-3"}>
                    <CardWrapper hover={false}>
                        {/*{`${f({id: "activePlural"})} - ${Object.values(modelCountMap).reduce((acc, el) => acc + el, 0)}`}*/}
                        {`${f({id: "uninvoiced"})} - ${modelCountMap.unInvoicedVehicles}`}
                    </CardWrapper>
                    {/*{Object.keys(modelCountMap.unActiveDeviceTypesCount).map(modelName => {*/}
                    {/*    return <CardWrapper>*/}
                    {/*        {`${modelName} - ${modelCountMap.unActiveDeviceTypesCount[modelName]}`}*/}
                    {/*    </CardWrapper>*/}
                    {/*})}*/}
                </div>
            </div>

            <div className={"col-12 md:col-6"}>
                <div className={"col-12 md:col-3"}>
                    <CardWrapper hover={false}>
                        {/*{`${f({id: "activePlural"})} - ${Object.values(modelCountMap).reduce((acc, el) => acc + el, 0)}`}*/}
                        {`${f({id: "inactivePlural"})} - ${modelCountMap.inactiveVehicles}`}
                    </CardWrapper>
                    {Object.keys(modelCountMap.unActiveDeviceTypesCount).map(modelName => {
                        return <CardWrapper hover={true}
                            onClick={() => showDetails(modelCountMap?.unActiveDeviceTypesCount[modelName as any].vehicles, `unactiveDevices-${modelName}`)}>
                            {`${modelName} - ${modelCountMap.unActiveDeviceTypesCount[modelName as any].count}`}
                        </CardWrapper>
                    })}
                </div>
            </div>
        </div>
    }

    const showDetails = (data: any, header: string) => {
        setDetailData(data);
        setHeaderText(f({id: header.split("-")[0]}) + " " + header.split("-")[1]);
    }

    return <>
        <div className={"grid flex"}>
            {renderCards()}
        </div>

        {detailData &&
            <div className={"flex justify-content-start grid"}>
                <Card className={"col-6 border-round-3xl shadow-6 text-center"}>
                    <h2>{headerText}</h2>
                    <ReactiveTable columnOrder={['lpn']} data={Object.values(detailData).map(el => {
                        return {lpn: el}
                    })} />
                </Card>
            </div>
        }
        </>
        };

        export default FirmVehicles
