import * as React from 'react';
import styles from "../lib/styles/ThemeSwitch.module.css";
import {useUserInfo} from "../lib/hooks/useUserInfo";
import {useEffect, useState} from "react";

interface Props {
}

export const ThemeSwitch :  React.FC<Props> = (props) => {
    const { theme, handleThemeChange } = useUserInfo();
    const [checked, setChecked] = useState<boolean>(theme === "light")

    const changeTheme = () => {
        handleThemeChange(theme === "light" ? "dark" : "light");
    }

    useEffect(() => {
        setChecked(theme === "light");
    }, [theme])

    return <>
        <div className={styles.switch}>
            <input type="checkbox" checked={checked} onChange={changeTheme} className={styles.switch__input} id="Switch"/>
            <label className={styles.switch__label} htmlFor="Switch">
                <span className={styles.switch__indicator}></span>
                <span className={styles.switch__decoration}></span>
            </label>
        </div>
    </>
};
