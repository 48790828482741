import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {Employee, InternalTaskDto} from "../../services/backend-api";
import {UtilService} from "../../services/utilService";
import {Chip} from "primereact/chip";

interface Props {
    task: InternalTaskDto;
    onTaskClicked: (task: InternalTaskDto) => void
}

const Wrapper = styled.div`
  padding: 1rem;
  background: var(--secondary-bg-color);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.45);
  
  &:hover {
    background: #091E420F;
  }
`

export const InternalTaskCard: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    return <>
        <Wrapper onClick={() => props.onTaskClicked(props.task)}>
            <span>{props.task.tittle}</span>
            <span>{UtilService.parseNikiDateTime(props.task.dueDateTime)}</span>
            <span><AssignedEmployeesChip employees={props.task.assignedEmployees || []}/></span>
        </Wrapper>
    </>
};


const AssignedEmployeesChip = (props: { employees: Employee[] }) => {
    const employeesCount = props.employees.length;

    const getLabel = () => {
        if (employeesCount === 0) return "";
        const firstEmployee = props.employees[0];
        if (!firstEmployee.employeeName) return "";

        let label = employeesCount > 0 ? firstEmployee.employeeName : "";

        if (employeesCount > 1) {
            label += " + " + (employeesCount - 1);
        }

        return label;
    }

    return <Chip label={getLabel()} />
}
