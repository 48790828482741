import {ICreateInternalTaskDto, IEmployee} from "../lib/types/types";
import axios from "axios";

export class InternalTaskService {
    service = "internaltasks/"

    async createInternalTask(data: ICreateInternalTaskDto){
        return new Promise<any>((resolve, reject) => {
            axios.post(this.service, data).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }
}
