import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useMemo, useRef, useState, useTransition} from 'react';
import {ProxySummaryRow, TcpProxyEntry} from "../../lib/types/types";
import {ReactiveTable} from "react-ive-tables";
import {Card} from "primereact/card";
import {DeferredContent} from "primereact/deferredcontent";

interface Props {
    data: TcpProxyEntry[][]
}

export interface TableRow extends ProxySummaryRow{
    svname: string,
    pxname: string,

    [key: string]: any
}

export interface TableRowContainer {
    [key: string]: TableRow[]
}

const ProxyStatusRaw: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    //TODO: fix type
    const [data, setData] = useState<TableRowContainer>()
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (props.data) {
            parseData(props.data)
        }
    }, [props.data]);


    const parseData = (data: TcpProxyEntry[][]) => {
        startTransition(() => {
            let result: TableRowContainer = {};

            data.forEach(el => {
                const currentRow: TableRow = el.reduce<TableRow>((acc, currentRow) => {
                    return {...acc, [currentRow.field.name]: currentRow.value.value}
                }, {} as TableRow)

                if (result[currentRow.pxname]) {
                    const pxName = currentRow['pxname']
                    //@ts-ignore
                    delete currentRow['pxname'];
                    result[pxName].push(currentRow);
                } else {
                    const pxName = currentRow['pxname']
                    //@ts-ignore
                    delete currentRow['pxname'];
                    result[pxName] = [currentRow];
                }
            })
            setData(result);
        })
    }

    return <>
        {data !== undefined && !isPending &&
            <DeferredContent >
                {Object.keys(data).map(el => {
                    return <Card className={"mt-3"}>
                        {/*@ts-ignore*/}
                        <div className={"w-full text-xl font-bold"}>{el}</div>
                        <ReactiveTable columnOrder={Object.keys(data[el][0])}
                                       data={data[el]} showFilters={false}
                        />
                    </Card>
                })
                }
            </DeferredContent>
        }
    </>
};

export default ProxyStatusRaw
