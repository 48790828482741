import axios from "axios";
import {IAccount, IAccountDetailedPermissions} from "../lib/types/types";

export class AccountsService {
    service = 'users/';

    async getAllAccounts() {
        return new Promise<IAccount[]>((resolve, reject) => {
            axios.get<IAccount[]>(this.service)
            .then(response => {
                resolve(response.data);
            })
            .catch(reject)
        })
    }

    async getUserPermissions(userId: number) {
        return new Promise<IAccountDetailedPermissions[]>((resolve, reject) => {
            axios.get<IAccountDetailedPermissions[]>(`${this.service}permissions/${userId}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(reject)
        })
    }

    async createAccount(data: {username:string, password: string }) {
        return new Promise<IAccount>((resolve, reject) => {
            axios.post<IAccount>(this.service, data)
            .then(response => resolve(response.data))
            .catch(reject);
        })
    }

    async updateAccount(data: {username: string, password: string}) {
        return new Promise<IAccount>((resolve, reject) => {
            axios.post<IAccount>(this.service, data)
            .then(response => resolve(response.data))
            .catch(reject);
        })
    }

    async deleteAccount(userId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete<boolean>(`${this.service}${userId}`)
            .then(response => {
                if(response.data) resolve(true);
                reject(false);
            })
            .catch(reject)
        })
    }

    async changePassword(userName: string, newPassword: string) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(`${this.service}changePassword`, {userName, newPassword})
                .then(response => {
                    if(response.data) resolve(true);
                    reject(false);
                })
                .catch(reject)
        })
    }

    async addOrRemovePermission(userId: number, permissions: string[], add: boolean) {
        return new Promise<any>((resolve, reject) => {
            const bodyData = {UserId: userId, PermissionNames: permissions};
            let promise;
            if(add) {
                promise = axios.post(this.service + 'permissions/', bodyData);
            }else {
                promise = axios.delete(this.service + 'permissions/', {data: bodyData});
            }
            promise
                .then((response: any) => resolve(response.data))
                .catch(reject);
        })
    }
}
