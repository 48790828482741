import * as React from "react";
import {useIntl} from "react-intl";
import {InternalTaskDto} from "../../services/backend-api";
import styled from "styled-components";
import {InternalTaskCard} from "./InternalTaskCard";
import {useEffect, useState} from "react";

interface Props {
    status: string,
    tasks: InternalTaskDto[],
    maxShownTasksCount: number;
    onTaskClicked: (task: InternalTaskDto) => void
}

const ColumnWrapper = styled.div`
  background-color: var(--taskColumnColor);
  display: flex;
  flex-direction: column;
`

const ColumnHeader = styled.div`
  display: flex;
  color: #5E6C84;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1rem 1rem;
`

const ColumnBody = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  gap: 0.5rem;
  width: 270px;
`

const ShowMoreCard = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  cursor: pointer;
  gap: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.45);
  justify-content: center;
  align-items: center;
  &:hover {
    background: #091E420F;
  }
  
`

export const InternalTasksColumn: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    const [shownTasksCount, setShownTasksCount] = useState(props.maxShownTasksCount);
    const [tasksToShow, setTasksToShow] = useState<InternalTaskDto[]>([]);
    const [maxTasksCountExceeded, setMaxTasksCountExceeded] = useState(false);

    useEffect(() => {
        let tasksToShowTemp :InternalTaskDto[] = [];
        const maxTasksCountExceededTemp = props.tasks.length > shownTasksCount;

        if(maxTasksCountExceededTemp){
            tasksToShowTemp = props.tasks.slice(0, shownTasksCount);
        }else{
            tasksToShowTemp = props.tasks;
        }

        setMaxTasksCountExceeded(maxTasksCountExceededTemp)
        setTasksToShow(tasksToShowTemp);
    }, [shownTasksCount, props.tasks]);

    const handleShowMoreTasks = () => {
        setShownTasksCount(prevState => prevState + props.maxShownTasksCount);
    }

    return <>
        <ColumnWrapper>
            <ColumnHeader>{props.status} {props.tasks.length}</ColumnHeader>
            <ColumnBody>
                {tasksToShow.map(el => <InternalTaskCard onTaskClicked={props.onTaskClicked} key={el.internalTaskId} task={el} />)}
                {maxTasksCountExceeded ? <ShowMoreCard onClick={handleShowMoreTasks}><i className={'pi pi-plus'} />{f({id: 'viewMore'})}</ShowMoreCard> : null}
            </ColumnBody>

        </ColumnWrapper>
    </>
};

