import React, {createContext} from 'react';
import {useIntl} from "react-intl";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

export const ConfirmDialogContext = createContext<any>(null);

export interface ConfirmDialogProps {
    body: any;
    header?: any
    icon?: string;
    hideIcon?: boolean;
    acceptLabel?: string;
    rejectLabel?: string;
}

export const ConfirmDialogContextProvider = (props: any) => {
    const { formatMessage: f } = useIntl();

    const showConfirmDialog = (data: ConfirmDialogProps) => {
        return new Promise((resolve) => {
            confirmDialog({
                message: data.body,
                header: data.header || f({ id: "attention" }),
                icon: data.hideIcon ? undefined : data.icon || 'pi pi-exclamation-triangle',
                acceptLabel: data.acceptLabel || f({ id: 'yes' }),
                rejectLabel: data.rejectLabel || f({ id: 'no' }),
                accept: () => resolve(true),
                reject: () => resolve(false),
                onHide: () => resolve(false)
            });
        })
    }

    return <>
        <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
            {props.children}
            <ConfirmDialog />
        </ConfirmDialogContext.Provider>
    </>
};
