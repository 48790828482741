import * as React from "react";
import {useState} from "react";
import {useIntl} from "react-intl";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {VehicleService} from "../../services/vehicleService";
import {useDialog} from "../../lib/hooks/useDialog";
import {useToast} from "../../lib/hooks/useToast";
import {FloatLabel} from "primereact/floatlabel";
import {GpsProgramService} from "../../services/gpsProgramService";
import {IDropdownOption} from "../../lib/types/types";

interface Props {
    vehicleId: number | number[];
    currentInvoiceDate: Date;
    createdCb: (result: boolean) => void
}

export const LoadInvoiceDate: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(props.currentInvoiceDate || new Date());

    const {hideDialog} = useDialog();
    const {showToast} = useToast();

    const vehicleService = new VehicleService();

    const handleSingleVehicle = (vehicleId: number, selectedDate: Date) => {
        vehicleService.loadInvoiceDate(vehicleId, selectedDate)
            .then(resp => {
                if (resp) {
                    hideDialog();
                    showToast("success", f({id: "done"}), f({id: "invoiceDateLoaded"}));
                }
                props.createdCb(resp);

            }).catch(err => {
            showToast("error", f({id: "error"}), f({id: "invoiceDateLoadFailed"}));
        })
    }

    const handleMultipleVehicles = (vehicleIds: number[], selectedDate: Date) => {
        const promises = vehicleIds.map(vehicleId => {
            return vehicleService.loadInvoiceDate(vehicleId, selectedDate);
        })

        Promise.all(promises).then(res => {
            if(res.every(response => response)){
                hideDialog();
                props.createdCb(true);
                showToast("success", f({id: "done"}), f({id: "invoiceDateLoaded"}));
            }else{
                showToast("error", f({id: "error"}), f({id: "invoiceDateLoadFailed"}));
            }
        }).catch(err => {
            showToast("error", f({id: "error"}), f({id: "invoiceDateLoadFailed"}));
        });
    }

    function isVehicleIdNumber(vehicleId: number | number[]): vehicleId is number {
        return !Array.isArray(vehicleId);
    }

    const submit = () => {
        if (selectedDate) {
            if (isVehicleIdNumber(props.vehicleId)) {
                handleSingleVehicle(props.vehicleId, selectedDate);
            } else {
                handleMultipleVehicles(props.vehicleId, selectedDate)
            }
        }
    }

    const maxDateForCalendar = new Date(new Date().setMonth(new Date().getMonth() + 2))

    return <>
        <div className={"grid w-full justify-content-center"}>
            <div className={"col-12 w-full justify-content-center"}>
                <FloatLabel>
                    <Calendar value={selectedDate} className={"w-full"} onChange={(e) => setSelectedDate(e.value as Date)}
                              maxDate={maxDateForCalendar} />
                    <label htmlFor="command">{f({id: "dateForInvoice"})}</label>
                </FloatLabel>
            </div>
        </div>

        <div className={"grid w-full justify-content-center"}>
            <div className={"col-12 md:col-6"}>
                <Button label={f({id: "save"})} icon={"pi pi-check"} onClick={submit}/>
            </div>

            <div className={"col-12 md:col-6"}>
                <Button label={f({id: "cancel"})} icon={"pi pi-times"} onClick={() => 0}/>
            </div>
        </div>
    </>
}
