import classNames from 'classnames';
import {UserContext} from "../lib/context/UserContext";
import {useContext} from "react";


interface Props{
}

const Footer : React.FC<Props> = (props) => {
            const { darkMode } = useContext(UserContext)

            const footerClassName = classNames("layout-footer", {
                'layout-footer-dark': darkMode,
                'layout-footer-light': !darkMode
            });

            return  (
                <div className={footerClassName} style={{display: "flex"}}>
                    <h3>IG-Billing v1.0.0</h3>
                </div>
        );
}

export default Footer
