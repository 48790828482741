import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Sidebar} from "primereact/sidebar";
import '../styles/rightSidebar.css'
import Draggable from 'react-draggable';
import {UserContext} from "./UserContext";
import {useIntl} from 'react-intl';
import {SettingsSidebar} from "../settingsSidebar/SettingsSidebar";
import {Dialog} from "primereact/dialog";

export const DialogContext = createContext<any>(null);
export const DialogContextProvider = (props: any) => {
    const {formatMessage: f} = useIntl();
    const {userInfo} = useContext(UserContext);
    const [isShown, setIsShown] = useState(false);
    // const [isSettingsShown, setIsSettingsShown] = useState(false);
    const [isSettingsShown, setIsSettingsShown] = useState(false);
    const [content, setContent] = useState<any>(null);
    const [heading, setHeading] = useState('');
    const [settingsSidebarContent, setSettingsSidebarContent] = useState<any>(null);
    const [dialogStyle, setDialogStyle] = useState<React.CSSProperties>()

    const didMountRef = useRef(false);

    const nodeRef = React.useRef(null);

    const onHideCallbackRef = useRef<any>(null);

    const showDialog = ({newContent, heading, onHideCallback, style} : { newContent: any, heading: any, onHideCallback: () => void, style?: React.CSSProperties }) => {
        setContent(newContent);
        setHeading(heading);
        setDialogStyle(style)
        onHideCallbackRef.current = onHideCallback;
        setIsShown(true);
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (isShown && isSettingsShown) {
            toggleSettingsMenu();
        }
    }, [isShown, isSettingsShown]);

    useEffect(() => {
        if (isSettingsShown) {
            document.querySelector('.layout-config')?.classList.add("layout-config-active");
        } else {
            document.querySelector('.layout-config')?.classList.remove("layout-config-active");
        }
    }, [isSettingsShown]);

    const bindSettingsSidebarContent = (content: any) => setSettingsSidebarContent(content);

    const unBindSettingsSidebarContent = () => setSettingsSidebarContent(null)

    const hideDialog = () => {
        setIsShown(false);
        if (isShown && onHideCallbackRef.current)
            onHideCallbackRef.current();
    }

    useEffect(() => {
        if (!isShown && onHideCallbackRef.current) {
            onHideCallbackRef.current();
        }
    }, [isShown, onHideCallbackRef]);

    const toggleSettingsMenu = (e?: any) => {
        if (e) e.stopPropagation();
        setIsSettingsShown(!isSettingsShown);
    }

    // pt={{
    //     icons: {style: {width: "100%"}},
    //     //@ts-ignore
    //     closeButton: {style: {marginLeft: "auto"}}
    // }}

    return <>
        <DialogContext.Provider
            value={{showDialog: showDialog, hideDialog: hideDialog, isShown, bindSettingsSidebarContent, unBindSettingsSidebarContent}}>
            <Dialog resizable visible={isShown} onHide={hideDialog} blockScroll header={heading} maximizable={true} breakpoints={{ '960px': '65vw', '641px': '100vw' }} style={dialogStyle}>
                <div className={"mt-3"}>
                    {content}
                </div>
            </Dialog>
            {
                userInfo.authenticated &&
                <div className={'layout-config'}>
                    <div className={'layout-config-content-wrapper'}>
                        <Draggable
                            nodeRef={nodeRef}
                            axis="y"
                            bounds={{top: -220, bottom: window.innerHeight - 320}}
                        >
                            <div ref={nodeRef}>
                                <button id={'cogButton'} onClick={(e) => toggleSettingsMenu(e)}
                                        className={'layout-config-button p-link'}><i className={'pi pi-cog'}/></button>
                            </div>
                        </Draggable>
                        <Sidebar id={"settings"} onHide={toggleSettingsMenu} dismissable
                                 visible={isSettingsShown && !isShown} modal={false} position={"right"}
                                 style={{width: "30vw"}}>
                            <SettingsSidebar content={settingsSidebarContent}/>
                        </Sidebar>
                    </div>
                </div>
            }

            {props.children}
        </DialogContext.Provider>
    </>
};
