import {FormElementValues} from "@xal3xfx/react-utils";

const count: FormElementValues<'number'> = {
    type: "number",
    label: 'count',
    props: {},
}

const price: FormElementValues<'number'> = {
    type: "number",
    label: 'price',
    props: {},
}

const opinion: FormElementValues<'text'> = {
    type: "text",
    label: 'opinion',
    props: {}
}

const dateTime: FormElementValues<'calendar'> = {
    type: "calendar",
    label: "dateTime",
    props: {}
}

export const formElements = {
    count, price, opinion, dateTime
}

