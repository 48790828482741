import * as React from "react";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {formElements} from './create-invoice-group-form'
import {validationSchema} from './validation'
import {InvoiceService} from "../../../services/invoiceService";
import {IClientFirm, ICreateInvoiceGroup, IFirmEmail, IInvoiceDetail, InvoiceGroup} from "../../../lib/types/types";
import {useDialog} from "../../../lib/hooks/useDialog";
import {ClientFirmService} from "../../../services/clientFirmService";
import {useDynamicForm} from "../../../lib/hooks/useDynamicForm";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {CreateOrUpdateInvoiceDetails} from "../invoiceDetails/CreateOrUpdateInvoiceDetails";

interface Props {
    clientFirmId: number;
    clientFirmName: string;
    clientFirm?: IClientFirm;
    vehicleId?: string;
    firmEmailId?: number;
    invoiceDetailsId?: number;
    createdCb?: (createdInvoiceGroup: InvoiceGroup) => void,
    invoiceGroupForEdit?: ICreateInvoiceGroup
}

const initialValue: ICreateInvoiceGroup = {
    clientFirmId: 0,
    invoiceCyrcle: 0,
    invoiceDetailsId: 0,
    invoiceGroupName: "Основна група",
    invoiceGroupDurationInMonths: 1,
    emailsToSendIds: [],
}

export const CreateInvoiceGroup: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const {hideDialog} = useDialog();

    const [formElementsState, setFormElementsState] = useState<typeof formElements>(formElements);
    const [formState, setFormState] = useState(initialValue);
    const [subWindowToShow, setSubWindowToShow] = useState<'createInvoiceDetails'>();
    const [clientFirm, setClientFirm] = useState<IClientFirm>();

    const clientFirmService = new ClientFirmService();
    const invoiceService = new InvoiceService();
    const dynamicForm = useDynamicForm();

    useEffect(() => {
        if (props.clientFirmId) {
            clientFirmService.getClientFirmById(props.clientFirmId).then(setClientFirm);

            const requestsArray = [clientFirmService.getInvoiceDetailsDropdown(props.clientFirmId), clientFirmService.getFirmMails(props.clientFirmId)];
            Promise.all(requestsArray).then((responses: any[]) => {
                const formElementsTemp = {...formElementsState};
                if (responses[0]) formElementsTemp.invoiceDetailsId.options = responses[0];
                formElementsTemp.invoiceDetailsId.button = <Button icon={'pi pi-plus'} type={"button"} onClick={() => {
                    setSubWindowToShow('createInvoiceDetails')
                }}/>
                if (responses[1]) {
                    const emailOptions = responses[1].map((el: IFirmEmail) => {
                        return {
                            key: el.emailAddressId,
                            id: el.emailAddressId,
                            description: el.emailOwner + " - " + el.email
                        }
                    })
                    formElementsTemp.emailsToSendIds.options = emailOptions;
                }
                setFormElementsState(formElementsTemp);

            })
        }
    }, [props.clientFirmId])

    useEffect(() => {
        if(props.invoiceGroupForEdit) {
            console.log(props.invoiceGroupForEdit);
            setFormState(props.invoiceGroupForEdit);
        }
    }, [props.invoiceGroupForEdit])

    useEffect(() => {
        if(props.invoiceGroupForEdit) return;
        const tempInitialValues = JSON.parse(JSON.stringify(initialValue));
        if (props.clientFirmName) tempInitialValues.clientFirmId = props.clientFirmName;
        if (props.firmEmailId) tempInitialValues.emailsToSendIds = [props.firmEmailId];
        if (props.invoiceDetailsId) tempInitialValues.invoiceDetailsId = props.invoiceDetailsId;
        setFormState(tempInitialValues);
    }, [props.clientFirmName, props.firmEmailId, props.invoiceDetailsId, props.invoiceGroupForEdit])

    const createHandler = (data: ICreateInvoiceGroup) => {
        return new Promise<boolean>((resolve, reject) => {
            let requestBody = {...data, clientFirmId: props.clientFirmId};
            if(props.invoiceGroupForEdit) requestBody = {...requestBody, invoiceGroupId: props.invoiceGroupForEdit.invoiceGroupId}
            invoiceService.createInvoiceGroup(requestBody).then(async (res) => {
                if (res) {
                    if (props.vehicleId) {
                        await invoiceService.addVehiclesToInvoiceGroup({
                            invoiceGroupId: res.invoiceGroupId,
                            vehicleIds: [Number(props.vehicleId)]
                        })
                    }
                    resolve(true);
                    if (props.createdCb) props.createdCb(res);
                    else hideDialog();
                } else reject();
            }).catch(reject);
        });
    }

    const getForm = () => {

        return dynamicForm({
            className: "col-12 md:col-6",
            fieldOrder: ['invoiceGroupName', 'clientFirmId', "invoiceDetailsId", "invoiceGroupDurationInMonths", "invoiceCyrcle", "emailsToSendIds"],
            initialValues: formState,
            onFormReset: () => 0,
            onCreate: createHandler,
            onUpdate: () => Promise.resolve(true),
            isUpdate: false,
            onCancelUpdate: () => 0,
            validationSchema: validationSchema,
            disableSaveButtons: props.invoiceGroupForEdit !== undefined,
            formElements: formElementsState
        })
    }

    const handleBackButton = () => {
        setSubWindowToShow(undefined);
    }

    const createdInvoiceDetailsCb = (createdInvoiceDetails: IInvoiceDetail) => {
        if(!createdInvoiceDetails) return;
        const formElementsTemp = {...formElementsState};
        formElementsTemp.invoiceDetailsId.options = [...formElementsTemp.invoiceDetailsId.options, {description: createdInvoiceDetails.invoiceDetailsName, id: createdInvoiceDetails.invoiceDetailsId, key: createdInvoiceDetails.invoiceDetailsId}];
        setFormElementsState(formElementsTemp);
        setSubWindowToShow(undefined);
    }

    if (subWindowToShow !== undefined)
        return <>
            <Toolbar
                start={<Button icon={"pi pi-arrow-left"} label={f({id: 'back'})} onClick={handleBackButton}/>}/>
            {clientFirm && <CreateOrUpdateInvoiceDetails clientFirm={clientFirm} createdCb={createdInvoiceDetailsCb}/>}
        </>

    return <>
        {getForm()}
    </>
}
