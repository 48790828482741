import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Divider} from "primereact/divider";
import {ClientFirmEmail} from "../../clientFirms/clientFirmMail/ClientFirmMail";
import {IFirmEmail} from "../../../lib/types/types";
import {InvoiceService} from "../../../services/invoiceService";
import {ClientFirmService} from "../../../services/clientFirmService";
import {IDropdownOption} from '@xal3xfx/react-utils/dist/util-service';
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

interface Props {
    clientFirmId: number;
    invoiceGroupId: number;
    doneCb: () => void;
}

const CreateOrChooseFirmEmail: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    const [firmEmails, setFirmEmails] = useState<IDropdownOption[]>();
    const [selectedEmail, setSelectedEmail] = useState<number>();

    const invoiceService = new InvoiceService();
    const clientFirmService = new ClientFirmService();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            getFirmEmails();
        }
    }, []);

    const getFirmEmails = () => {
        clientFirmService.getFirmMails(props.clientFirmId)
            .then(resp => {
                const emailOptions = resp.map((el: IFirmEmail) => {
                    return {
                        key: el.emailAddressId,
                        id: el.emailAddressId,
                        description: el.emailOwner + " - " + el.email
                    }
                })

                setFirmEmails(emailOptions);
            })
    }
    const createdFirmEmailCb = (createdEmail: IFirmEmail) => {
        addEmailToGroup(createdEmail.emailAddressId);
    }

    const addEmailToGroup = (emailId: number) => {
        invoiceService.addEmailToInvoiceGroup(props.invoiceGroupId, emailId)
            .then(resp => {
                props.doneCb()
            }).catch(console.error)
    }

    return <>
        <div className={"flex flex-row w-full"}>
            <div className="w-full md:w-5 flex flex-column align-items-center justify-content-center gap-3">
                <h2>{f({id: "createEmail"})}</h2>
                <ClientFirmEmail firmId={props.clientFirmId} update={false} oneRow={true}
                                 createdCb={createdFirmEmailCb}/>
            </div>

            <div className="w-full md:w-2">
                <Divider layout="vertical" className="hidden md:flex">
                    <b>{f({id: "or"})}</b>
                </Divider>

                <Divider layout="horizontal" className="flex md:hidden" align="center">
                    <b>{f({id: "or"})}</b>
                </Divider>
            </div>

            <div className="w-full md:w-5 flex align-items-center justify-content-start flex-column">
                <h2>{f({id: "chooseEmail"})}</h2>
                <Dropdown className={"w-full mt-auto"} options={firmEmails} optionValue={"id"} showClear={true}
                          optionLabel={"description"} value={selectedEmail}
                          onChange={(e) => setSelectedEmail(e.value)}/>
                <Button disabled={selectedEmail === undefined} className={"w-full mt-2 mb-2"} label={f({id: "save"})} onClick={() => addEmailToGroup(selectedEmail!)}/>
            </div>
        </div>
    </>
};

export default CreateOrChooseFirmEmail
