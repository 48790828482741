/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthRequest {
  username?: string | null;
  password?: string | null;
}

export interface ClientFirmRequest {
  /** @format int32 */
  clientFirmId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  clientFirmName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  bulstat?: string | null;
  /**
   * @minLength 0
   * @maxLength 80
   */
  mol?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  deliveryAddress?: string | null;
  distributorName?: string | null;
  gpsFirmName?: string | null;
  gpsUserNames?: string | null;
  salesPersonName?: string | null;
}

export interface VehicleModel {
  /** @format int32 */
  vehicleModelId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  vehicleModelName?: string | null;
  /** @format int32 */
  vehicleModelYear?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface VehicleManufacturer {
  /** @format int32 */
  vehicleManufacturerId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  vehicleManufacturerName?: string | null;
  manufacturerModels?: VehicleModel[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface VehicleNote {
  /** @format int32 */
  vehicleNoteId?: number;
  note?: string | null;
  vehicle?: Vehicle;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface SimCardOperator {
  /** @format int32 */
  simCardOperatorId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  operatorName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface SimCardNote {
  /** @format int32 */
  simCardNoteId?: number;
  note?: string | null;
  simCard?: SimCard;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface SimCard {
  /** @format int32 */
  simCardId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  simCardNumber?: string | null;
  simCardOperator?: SimCardOperator;
  /**
   * @minLength 0
   * @maxLength 50
   */
  simCardSsid?: string | null;
  simCardCcid?: string | null;
  simCardNotes?: SimCardNote[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface DeviceType {
  /** @format int32 */
  deviceTypeId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  deviceTypeName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface DeviceModel {
  /** @format int32 */
  deviceModelId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  deviceModelName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  deviceManufacturerName?: string | null;
  deviceType?: DeviceType;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface DeviceNote {
  /** @format int32 */
  deviceNoteId?: number;
  note?: string | null;
  device?: Device;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface Device {
  /** @format int32 */
  deviceId?: number;
  /** @format int64 */
  deviceNumber?: number;
  simCard?: SimCard;
  deviceModel?: DeviceModel;
  deviceNotes?: DeviceNote[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface VehicleEuro {
  /** @format int32 */
  vehicleEuroId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  vehicleEuroName: string;
}

/** @format int32 */
export enum VehicleStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface TarifType {
  /** @format int32 */
  tarifTypeId?: number;
  /**
   * @minLength 0
   * @maxLength 70
   */
  tarifTypeName: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface Tarif {
  /** @format int32 */
  tarifId?: number;
  /**
   * @minLength 0
   * @maxLength 100
   */
  tarifName: string;
  tarifType: TarifType;
  /**
   * @minLength 0
   * @maxLength 150
   */
  tarifDescription?: string | null;
  /** @format double */
  januaryPrice: number;
  /** @format double */
  februaryPrice: number;
  /** @format double */
  marchPrice: number;
  /** @format double */
  aprilPrice: number;
  /** @format double */
  mayPrice: number;
  /** @format double */
  junePrice: number;
  /** @format double */
  julyPrice: number;
  /** @format double */
  augustPrice: number;
  /** @format double */
  septemberPrice: number;
  /** @format double */
  octoberPrice: number;
  /** @format double */
  novemberPrice: number;
  /** @format double */
  decemberPrice: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface InvoiceDetails {
  /** @format int32 */
  invoiceDetailsId?: number;
  invoiceDetailsName?: string | null;
  recieverName?: string | null;
  recieverAddress?: string | null;
  recieverIdentityCode?: string | null;
  recieverIdentityCodeZDDS?: string | null;
  recieverMol?: string | null;
  recieverBank?: string | null;
  recieverBic?: string | null;
  recieverIban?: string | null;
  providerName?: string | null;
  providerAddress?: string | null;
  providerIdentityCode?: string | null;
  providerIdentityCodeZDDS?: string | null;
  providerMol?: string | null;
  providerBank?: string | null;
  providerBic?: string | null;
  providerIban?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

/** @format int32 */
export enum InvoiceCycle {
  Value0 = 0,
  Value1 = 1,
}

export interface ClientFirmEmailAddress {
  /** @format int32 */
  clientFirmId?: number;
  clientFirm?: ClientFirm;
  /** @format int32 */
  emailAddressId?: number;
  emailAddress?: EmailAddress;
}

export interface EmailAddress {
  /** @format int32 */
  emailAddressId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  email?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  emailOwner?: string | null;
  clientFirmEmailAddresses?: ClientFirmEmailAddress[] | null;
  invoiceGroupEmailAddress?: InvoiceGroupEmailAddress[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface InvoiceGroupEmailAddress {
  /** @format int32 */
  invoiceGroupId?: number;
  invoiceGroup?: InvoiceGroup;
  /** @format int32 */
  emailAddressId?: number;
  emailAddress?: EmailAddress;
}

export interface InvoiceGroup {
  /** @format int32 */
  invoiceGroupId?: number;
  invoiceGroupName?: string | null;
  vehicles?: Vehicle[] | null;
  /** @format int32 */
  invoiceGroupDurationInMonths?: number;
  clientFirm?: ClientFirm;
  invoiceDetails?: InvoiceDetails;
  invoiceCycle?: InvoiceCycle;
  emailsToSend?: InvoiceGroupEmailAddress[] | null;
}

export interface Vehicle {
  /** @format int32 */
  vehicleId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  vehicleLpn?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  vehicleTable?: string | null;
  gpsImei?: string | null;
  vehicleManufacturer?: VehicleManufacturer;
  vehicleModel?: VehicleModel;
  vehicleNotes?: VehicleNote[] | null;
  /** @format int32 */
  vehicleYear?: number;
  devices?: Device[] | null;
  clientFirm?: ClientFirm;
  vehicleEuro?: VehicleEuro;
  vehicleStatus?: VehicleStatus;
  tarifs?: Tarif[] | null;
  invoiceGroup?: InvoiceGroup;
  invoiceStatus?: boolean;
  /** @format date-time */
  invoiceStartDate?: string;
  tacho?: boolean;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface Phone {
  /** @format int32 */
  phoneId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  phoneNumber: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  ownerName: string;
  clientFirmPhones?: ClientFirmPhone[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface ClientFirmPhone {
  /** @format int32 */
  clientFirmId?: number;
  clientFirm?: ClientFirm;
  /** @format int32 */
  phoneId?: number;
  phone?: Phone;
}

export interface InvoiceAttribute {
  /** @format int32 */
  invoiceAttributeId?: number;
  invoice?: Invoice;
  invoiceAttributeName?: string | null;
  invoiceAttributeUnits?: string | null;
  /** @format double */
  invoiceAttributeCount?: number;
  /** @format double */
  invoiceAttributeSinglePrice?: number;
  /** @format double */
  invoiceAttributeMiddlePrice?: number;
  /** @format double */
  invoiceAttributeDDSPercent?: number;
  /** @format double */
  invoiceAttributeDDSPrice?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface InvoiceNote {
  /** @format int32 */
  invoiceNoteId?: number;
  note?: string | null;
  invoice?: Invoice;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface Invoice {
  /** @format int32 */
  invoiceId?: number;
  /** @format int64 */
  invoiceNumber: number;
  invoiceAttributes?: InvoiceAttribute[] | null;
  paid?: boolean;
  /** @format date-time */
  invoiceDate?: string;
  clientFirm?: ClientFirm;
  invoiceGroup?: InvoiceGroup;
  invoiceNotes?: InvoiceNote[] | null;
  recieverName?: string | null;
  recieverAddress?: string | null;
  recieverIdentityCode?: string | null;
  recieverIdentityCodeZDDS?: string | null;
  recieverMol?: string | null;
  recieverBank?: string | null;
  recieverBic?: string | null;
  recieverIban?: string | null;
  providerName?: string | null;
  providerAddress?: string | null;
  providerIdentityCode?: string | null;
  providerIdentityCodeZDDS?: string | null;
  providerMol?: string | null;
  providerBank?: string | null;
  providerBic?: string | null;
  providerIban?: string | null;
  /** @format double */
  middleCost?: number;
  /** @format double */
  dds?: number;
  /** @format double */
  totalCost?: number;
  totalCostToWord?: string | null;
  /** @format date-time */
  eventDate?: string;
  eventPlace?: string | null;
  paymentMeans?: string | null;
  /** @format date-time */
  dueDate?: string;
  creator?: string | null;
  recieved?: string | null;
  sender?: string | null;
  documentName?: string | null;
  /** @format date-time */
  invoiceStartPeriod?: string;
  /** @format date-time */
  invoiceEndPeriod?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface EmployeePosition {
  /** @format int32 */
  employeePositionId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  employeePositionName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface InternalTaskReply {
  /** @format int32 */
  internalTaskReplyId?: number;
  /** @format date-time */
  replyDateTime?: string;
  reply?: string | null;
  replyByEmployee?: Employee;
  internalTask?: InternalTask;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

/** @format int32 */
export enum InternalTaskStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface InternalTask {
  /** @format int32 */
  internalTaskId?: number;
  /** @format date-time */
  dateTime?: string;
  /** @format date-time */
  dueDateTime?: string;
  tittle?: string | null;
  description?: string | null;
  createdByEmployee?: Employee;
  replies?: InternalTaskReply[] | null;
  assignedEmployees?: InternalTaskAssignedEmployee[] | null;
  open?: boolean;
  /** @format date-time */
  openDateTime?: string;
  status?: InternalTaskStatus;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface InternalTaskAssignedEmployee {
  /** @format int32 */
  internalTaskId?: number;
  internalTask?: InternalTask;
  /** @format int32 */
  employeeId?: number;
  employee?: Employee;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface Employee {
  /** @format int32 */
  employeeId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  employeeName?: string | null;
  userName?: string | null;
  employeePosition?: EmployeePosition;
  assignedTasks?: InternalTaskAssignedEmployee[] | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface ClientFirm {
  /** @format int32 */
  clientFirmId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  clientFirmName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  bulstat?: string | null;
  /**
   * @minLength 0
   * @maxLength 80
   */
  mol?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  deliveryAddress?: string | null;
  vehicles?: Vehicle[] | null;
  phones?: ClientFirmPhone[] | null;
  emails?: ClientFirmEmailAddress[] | null;
  tarifs?: Tarif[] | null;
  invoiceDetails?: InvoiceDetails[] | null;
  invoices?: Invoice[] | null;
  distributor?: Employee;
  gpsFirmName?: string | null;
  gpsUserNames?: string | null;
  /** @format int32 */
  vehiclesCount?: number;
  salesPerson?: Employee;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface ClientFirmDto {
  /** @format int32 */
  clientFirmId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  clientFirmName?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  bulstat?: string | null;
  /**
   * @minLength 0
   * @maxLength 80
   */
  mol?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 150
   */
  deliveryAddress?: string | null;
  distributorName?: string | null;
  gpsFirmName?: string | null;
  gpsUserNames?: string | null;
  /** @format int32 */
  vehiclesCount?: number;
  salesPersonName?: string | null;
}

export interface Options {
  value?: string | null;
  text?: string | null;
}

export interface DhtmlxCombo {
  options?: Options[] | null;
}

export interface PhoneDto {
  /** @format int32 */
  phoneId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  phoneNumber: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  ownerName: string;
}

export interface EmailAddressDto {
  /** @format int32 */
  emailAddressId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  email: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  emailOwner: string;
}

export interface DeviceDto {
  /** @format int32 */
  deviceId?: number;
  /** @format int64 */
  deviceNumber?: number;
  /** @format int32 */
  simCardId?: number | null;
  simCardNumber?: string | null;
  /** @format int32 */
  deviceModelId?: number;
  deviceModelName?: string | null;
  deviceManufacturerName?: string | null;
  /** @format int32 */
  deviceTypeId?: number;
  deviceTypeName?: string | null;
  activeTable?: string | null;
  deviceNotes?: DeviceNote[] | null;
}

export type StringStringValueTuple = object;

export interface VehicleDto {
  /** @format int32 */
  vehicleId?: number;
  vehicleLpn?: string | null;
  vehicleTable?: string | null;
  /** @format int32 */
  vehicleManufacturerId?: number;
  vehicleManufacturerName?: string | null;
  /** @format int32 */
  vehicleModelId?: number;
  vehicleModelName?: string | null;
  vehicleNotes?: VehicleNote[] | null;
  /** @format int32 */
  vehicleYear?: number;
  /** @format int32 */
  devicesCount?: number;
  vehicleStatus?: VehicleStatus;
  deviceDtos?: DeviceDto[] | null;
  /** @format int32 */
  clientFirmId?: number;
  clientFirmName?: string | null;
  /** @format int32 */
  vehicleEuroId?: number;
  vehicleEuroName?: string | null;
  invoiceStatus?: boolean;
  /** @format int32 */
  invoiceGroupId?: number;
  invoiceGroupName?: string | null;
  /** @format date-time */
  invoiceStartDate: string;
  tacho?: boolean;
  gpsAccounts?: StringStringValueTuple[] | null;
}

export interface VehicleShortDto {
  /** @format int32 */
  vehicleId?: number;
  vehicleLpn?: string | null;
  vehicleTable?: string | null;
}

export interface DeviceRequest {
  /** @format int32 */
  deviceId?: number;
  /** @format int64 */
  deviceNumber?: number;
  /** @format int32 */
  deviceModelId?: number;
  /** @format int32 */
  simCardId?: number;
}

export interface DhtmlxGridRow {
  /** @format int32 */
  id?: number;
  bgColor?: string | null;
  style?: string | null;
  userdata?: Record<string, object>;
  data?: string[] | null;
}

export interface DhtmlxGridJson {
  rows?: DhtmlxGridRow[] | null;
}

export interface DeviceTypeRequest {
  typeName?: string | null;
}

export interface DeviceModelRequest {
  /** @format int32 */
  deviceModelId?: number;
  deviceModelName?: string | null;
  deviceManufacturerName?: string | null;
  /** @format int32 */
  deviceTypeId?: number;
}

export interface DeviceNoteRequest {
  note?: string | null;
}

export interface EmployeeRequest {
  /** @format int32 */
  employeeId?: number;
  employeeName?: string | null;
  /** @format int32 */
  employeePositionId?: number;
  userName?: string | null;
}

export interface EmployeeDto {
  /** @format int32 */
  employeeId?: number;
  employeeName?: string | null;
  userName?: string | null;
  /** @format int32 */
  employeePositionId?: number;
  employeePositionName?: string | null;
  assignedTasksIds?: number[] | null;
}

export interface EmployeePositionRequest {
  employeePositionName?: string | null;
}

export interface OtherProgramRequest {
  url?: string | null;
  type?: string | null;
  jsonBody?: string | null;
  parameters?: Record<string, string>;
}

export interface RemoteTokenResponse {
  result?: boolean;
  token?: string | null;
}

export interface GPSProgramResultResponce {
  result?: boolean;
}

export interface GPSFirm {
  firmname?: string | null;
  contactPerson?: string | null;
  gsm?: string | null;
  bulstat?: string | null;
  city?: string | null;
  address?: string | null;
  activity?: string | null;
  /** @format double */
  monthTax?: number;
  /** @format int64 */
  managerId?: number;
  password?: string | null;
  /** @format int64 */
  gps_signal_good?: number;
  /** @format int64 */
  gps_signal_average?: number;
  /** @format int64 */
  gps_signal_bad?: number;
  /** @format int64 */
  gps_signal_poor?: number;
  /** @format int64 */
  gps_signal_trigger?: number;
  attachedToBilling?: boolean;
  /** @format int64 */
  clientFirmId?: number;
  clientFirmName?: string | null;
}

export interface GPSAccount {
  username?: string | null;
  password?: string | null;
  firmname?: string | null;
  lastlogindate?: string | null;
  lastlogintime?: string | null;
  lastlogoutdate?: string | null;
  lastlogouttime?: string | null;
  ip?: string | null;
  host?: string | null;
  paidtodate?: string | null;
  /** @format double */
  timezone?: number;
  /** @format int64 */
  new_pass_generated_date?: number;
  attachedToBilling?: boolean;
  allVehicleAttached?: boolean;
  /** @format int32 */
  totalVehicles?: number;
  /** @format int32 */
  unattachedVehicles?: number;
}

export interface GPSFirmWithAccounts {
  gpsAccounts?: GPSAccount[] | null;
  firmname?: string | null;
  contactPerson?: string | null;
  gsm?: string | null;
  bulstat?: string | null;
  city?: string | null;
  address?: string | null;
  activity?: string | null;
  /** @format double */
  monthTax?: number;
  /** @format int64 */
  managerId?: number;
  password?: string | null;
  /** @format int64 */
  gps_signal_good?: number;
  /** @format int64 */
  gps_signal_average?: number;
  /** @format int64 */
  gps_signal_bad?: number;
  /** @format int64 */
  gps_signal_poor?: number;
  /** @format int64 */
  gps_signal_trigger?: number;
  attachedToBilling?: boolean;
  /** @format int64 */
  clientFirmId?: number;
  clientFirmName?: string | null;
}

export interface GPSAccountVehicle {
  alias?: string | null;
  vehiclE_ID?: string | null;
  username?: string | null;
  /** @format int64 */
  columN_NUMBER?: number;
  onlinE_COLOR?: string | null;
  firmname?: string | null;
  vehicleExist?: boolean;
  /** @format int32 */
  clientFirmId?: number;
  clientFirmName?: string | null;
}

export interface OnlineData {
  id?: string | null;
  imei?: string | null;
  /** @format int32 */
  date?: number;
  /** @format int32 */
  time?: number;
  /** @format double */
  lon?: number;
  /** @format double */
  lat?: number;
  /** @format int32 */
  digital?: number;
  /** @format int32 */
  speed?: number;
  /** @format int32 */
  analog0?: number;
  /** @format int32 */
  analog1?: number;
  /** @format int32 */
  vbat?: number;
  /** @format int32 */
  temperature?: number;
  /** @format int32 */
  general?: number;
  /** @format int32 */
  camera?: number;
  /** @format int32 */
  period?: number;
  /** @format int32 */
  revolution?: number;
  /** @format int64 */
  distance?: number;
  /** @format int64 */
  totalFuel?: number;
  /** @format int64 */
  totalDistance?: number;
  /** @format int64 */
  totalTime?: number;
  /** @format int32 */
  parkingDate?: number;
  /** @format int32 */
  parkingTime?: number;
  serverIP?: string | null;
  geoAdres?: string | null;
  /** @format int32 */
  heading?: number;
  /** @format int32 */
  accuracy?: number;
  /** @format date-time */
  current_time_stamp?: string;
}

export interface CarEntry {
  firma?: string | null;
  sim?: string | null;
  table_name?: string | null;
  imei?: string | null;
  /** @format int32 */
  table_id?: number;
  kind?: string | null;
  reg_nomer?: string | null;
  marka?: string | null;
  montajnik?: string | null;
  note?: string | null;
  data?: string | null;
  /** @format double */
  nap?: number;
  server?: string | null;
  /** @format int32 */
  id?: number;
}

export interface AddCarRegisterWithImeiResponse {
  status?: boolean;
  data?: Record<string, string>;
}

export interface AddGPSVehicleToAccountRequest {
  alias?: string | null;
  vehicleId?: string | null;
  userName?: string | null;
}

export interface RemoveGPSVehicleFromAccountRequest {
  vehicleId?: string | null;
  userName?: string | null;
}

export interface RenameGpsVehicleRequest {
  userName: string;
  vehicleTable: string;
  newVehicleAlias: string;
}

export interface RenameGpsVehicleInAllAccountsRequest {
  newVehicleAlias?: string | null;
  vehicleId?: string | null;
}

export interface RowCarDataRequest {
  tableName?: string | null;
  /** @format date-time */
  startDateTime?: string;
  /** @format date-time */
  endDateTime?: string;
}

export interface RowCarData {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  date?: number;
  /** @format int32 */
  time?: number;
  /** @format double */
  lon?: number;
  /** @format double */
  lat?: number;
  /** @format int32 */
  speed?: number;
  /** @format int32 */
  digital?: number;
  /** @format int32 */
  analog0?: number;
  /** @format int32 */
  analog1?: number;
  /** @format int32 */
  vbat?: number;
  /** @format int32 */
  temperature?: number;
  /** @format int32 */
  general?: number;
  /** @format int32 */
  camera?: number;
  /** @format int32 */
  period?: number;
  /** @format int32 */
  revolution?: number;
  /** @format int32 */
  distance?: number;
  /** @format int64 */
  totalFuel?: number;
  /** @format int64 */
  totalDistance?: number;
  /** @format int64 */
  totalTime?: number;
  /** @format int64 */
  rowID?: number;
  /** @format int32 */
  heading?: number;
  /** @format int32 */
  accuracy?: number;
}

export interface GPSVehiclesWithFirm {
  alias?: string | null;
  vehiclE_ID?: string | null;
  username?: string | null;
  gpS_FIRMNAME?: string | null;
  /** @format int32 */
  date?: number;
  /** @format int32 */
  time?: number;
  /** @format date-time */
  lasT_SIGNAL_DATE_TIME?: string | null;
}

export interface AllCarData {
  alias?: string | null;
  vehiclE_ID?: string | null;
  username?: string | null;
  password?: string | null;
  firmname?: string | null;
  freeze?: string | null;
  lasT_ONLINE?: string | null;
  serveR_IP?: string | null;
  imei?: string | null;
  fuel?: string | null;
  sonda?: string | null;
  maX_SONDA?: string | null;
  temperature?: string | null;
  oboroti?: string | null;
  temperaturE2?: string | null;
  bypass?: string | null;
  drivers?: string | null;
  sondA2?: string | null;
  maX_SONDA2?: string | null;
  kanshina?: string | null;
  datetimE_ADDED?: string | null;
  tolL_LPN?: string | null;
  /** @format int32 */
  clientFirmId?: number | null;
  lpnExistInVehicles: boolean
}

export interface GpsCommandRequest {
  imei?: string | null;
  commandText?: string | null;
}

export interface GpsCommand {
  /** @format int64 */
  id?: number;
  imei?: string | null;
  /** @format date-time */
  date_time?: string;
  command_text?: string | null;
  responce?: string | null;
  /** @format int32 */
  responced?: number;
  send_user?: string | null;
}

export interface SmsCommandRequest {
  commandText?: string | null;
  gsmNumber?: string | null;
}

export interface SmsCommand {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  commandDateTime?: string;
  commandText?: string | null;
  /** @format int64 */
  gsmNumber?: number;
  /** @format int32 */
  sent?: number;
  /** @format date-time */
  responseDateTime?: string | null;
  response?: string | null;
  /** @format int32 */
  responsed?: number;
  sendUser?: string | null;
}

export interface RfidCardDto {
  /** @format int32 */
  rfidCardId?: number;
  /** @format int64 */
  decimalNumber?: number;
  octNumber?: string | null;
  configNumber?: string | null;
  driverName?: string | null;
  gpsFirmName?: string | null;
  /** @format int32 */
  rfidSubGroupId?: number;
  rfidSubGroupName?: string | null;
  /** @format int32 */
  rfidGroupId?: number;
  rfidGroupName?: string | null;
}

export interface RfidSubGroupDto {
  /** @format int32 */
  rfidSubGroupId?: number;
  rfidSubGroupName?: string | null;
  /** @format int32 */
  rfidGroupId?: number;
}

export interface RfidGroupDto {
  /** @format int32 */
  rfidGroupId?: number;
  rfidGroupName?: string | null;
  rfidSubGroups?: RfidSubGroupDto[] | null;
  /** @format int64 */
  clientFirmId?: number;
  clientFirmName?: string | null;
}

export interface InternalTaskReplyDto {
  /** @format int32 */
  internalTaskReplyId?: number;
  reply?: string | null;
  /** @format date-time */
  replyDateTime?: string;
  /** @format int32 */
  replyByEmployeeId?: number;
  replyByEmployeeName?: string | null;
  /** @format int32 */
  internalTaskId?: number;
}

export interface InternalTaskDto {
  /** @format int32 */
  internalTaskId?: number;
  /** @format date-time */
  dateTime?: string;
  /** @format date-time */
  dueDateTime?: string;
  tittle?: string | null;
  description?: string | null;
  /** @format int32 */
  createdByEmployeeId?: number;
  employeeName?: string | null;
  replies?: InternalTaskReplyDto[] | null;
  assignedEmployees?: EmployeeDto[] | null;
  open?: boolean;
  /** @format date-time */
  openDateTime?: string;
  status?: InternalTaskStatus;
  statusName?: string | null;
}

export interface InternalTaskRequest {
  /** @format int32 */
  internalTaskId?: number;
  /** @format date-time */
  dueDateTime: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  tittle: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  description: string;
  assignedEmployeesIds?: number[] | null;
}

export interface ChangeStatusRequest {
  /** @format int32 */
  statusId?: number;
  /** @format int32 */
  internalTaskId?: number;
}

export interface AssignEmployeeRequest {
  /** @format int32 */
  internalTaskId: number;
  /** @format int32 */
  employeeId: number;
}

export interface InternalTaskReplyRequest {
  reply: string;
  /** @format int32 */
  internalTaskId: number;
}

export interface InvoicesForPeriodRequest {
  /** @format date-time */
  startDateTime?: string;
  /** @format date-time */
  endDateTime?: string;
}

export interface GenerateTestInvoicesRequest {
  /** @format int32 */
  clientFrimId?: number;
  /** @format date-time */
  startDate?: string;
}

export interface InvoicesFilterByDateRequest {
  /** @format int32 */
  clientFirmId?: number;
  /** @format date-time */
  startDateTime?: string;
  /** @format date-time */
  endDateTime?: string;
}

export interface InvoiceGroupDto {
  /** @format int32 */
  invoiceGroupId?: number;
  invoiceGroupName?: string | null;
  vehicleIds?: number[] | null;
  /** @format int32 */
  vehiclesCount?: number;
  /** @format int32 */
  invoiceGroupDurationInMonths?: number;
  /** @format int32 */
  clientFirmId?: number;
  /** @format int32 */
  invoiceDetailsId?: number;
  invoiceDetailsName?: string | null;
  invoiceCyrcle?: InvoiceCycle;
  emailsToSend?: EmailAddressDto[] | null;
  emailsToSendIds?: number[] | null;
}

export interface InvoiceGroupEmailRequest {
  /** @format int32 */
  invoiceGroupId?: number;
  /** @format int32 */
  emailAddressId?: number;
}

export interface InvoiceGroupModifyRequest {
  /** @format int32 */
  invoiceGroupId?: number;
  vehicleIds?: number[] | null;
}

export interface InvoiceNoteRequest {
  note?: string | null;
}

export interface InvoicePayRequest {
  /** @format int32 */
  invoiceId?: number;
  payStatus?: boolean;
}

export interface InvoiceSettings {
  invoiceAutoGenerate?: boolean;
  /** @format int32 */
  invoiceGeneratePeriod?: number;
}

export interface SimCardDto {
  /** @format int32 */
  simCardId?: number;
  number?: string | null;
  operatorName?: string | null;
  simCardSsid?: string | null;
  simCardCcid?: string | null;
  simCardNotes?: SimCardNote[] | null;
}

export interface SimCardRequest {
  /** @format int32 */
  simCardId?: number;
  number?: string | null;
  /** @format int32 */
  operatorId?: number;
  ssid?: string | null;
  ccid?: string | null;
}

export interface SimCardOperatorCreateRequest {
  name?: string | null;
}

export interface SimCardNoteRequest {
  note?: string | null;
}

export interface TollDebugModel {
  lpn?: string | null;
  tollTime?: string | null;
  nipoTime?: string | null;
  timeDiff?: string | null;
  barcode?: string | null;
}

export interface TrailRequest {
  panNumber?: string | null;
  lpnNumber?: string | null;
  /** @format int32 */
  start?: number;
  /** @format int32 */
  end?: number;
  includeLogs?: boolean;
}

export interface TrailEntry {
  panNumber?: string | null;
  lpnNumber?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  dateTime?: string;
  /** @format int32 */
  speed?: number;
  /** @format int32 */
  accuracy?: number;
  /** @format double */
  heading?: number;
  /** @format int32 */
  axlesCount?: number | null;
  /** @format int32 */
  weight?: number | null;
  partitionKey?: string | null;
  rowKey?: string | null;
  /** @format int64 */
  timestamp?: number;
  eTag?: string | null;
  /** @format date-time */
  dateReported?: string | null;
  referenceId?: string | null;
}

export interface TrailResponse {
  trailEntries?: TrailEntry[] | null;
}

export interface LogRequest {
  lpnNumber?: string | null;
  /** @format int32 */
  start?: number;
  /** @format int32 */
  end?: number;
}

export interface LogEntry {
  pan?: string | null;
  lpn?: string | null;
  /** @format int32 */
  timestamp?: number;
  /** @format int32 */
  dateReported?: number;
  referenceId?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format double */
  heading?: number;
  /** @format int32 */
  speed?: number;
  /** @format int32 */
  horizontalAccuracy?: number;
  /** @format int32 */
  trackerAxles?: number;
  /** @format int32 */
  trailerAxles?: number;
  /** @format int32 */
  maxWeight?: number;
  warnings?: object | null;
}

export interface LogResponse {
  logEntries?: LogEntry[] | null;
}

export interface CompareRequest {
  /** @format int32 */
  compareRequestId?: number;
  lpn?: string | null;
  barcode?: string | null;
  /** @format date-time */
  date?: string;
  /** @format int32 */
  retryCount?: number;
  finished?: boolean;
}

export interface CompareEntry {
  /** @format int32 */
  compareEntryId?: number;
  lpnNumber?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format date-time */
  dateTime?: string;
  /** @format int64 */
  timestamp?: number;
  /** @format int32 */
  speed?: number;
  /** @format int32 */
  accuracy?: number;
  /** @format double */
  heading?: number;
}

export interface CompareResponse {
  /** @format int32 */
  compareResponseId?: number;
  compareRequest?: CompareRequest;
  lpnNumber?: string | null;
  /** @format date-time */
  checkedDate?: string;
  /** @format date-time */
  trailStartDateTime?: string;
  /** @format date-time */
  trailEndDateTime?: string;
  /** @format date-time */
  logStartDateTime?: string;
  /** @format date-time */
  logEndDateTime?: string;
  /** @format int32 */
  trailSize?: number;
  /** @format int32 */
  logSize?: number;
  /** @format int32 */
  diff?: number;
  compareEntries?: CompareEntry[] | null;
}

export interface CompareMultipleRequest {
  vehicles?: CompareRequest[] | null;
}

export interface AccountUnit {
  plateOriginal?: string | null;
  /** @format date-time */
  lastPositionDateTime?: string | null;
  equipmentId?: string | null;
  /** @format int32 */
  lastPositionSpeed?: number | null;
}

export interface UserPermission {
  /** @format int32 */
  userPermissionId?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  permissionName?: string | null;
}

export interface User {
  /** @format int32 */
  userId?: number;
  /**
   * @minLength 0
   * @maxLength 30
   */
  username?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  digest?: string | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  salt?: string | null;
  permissions?: UserPermission[] | null;
  /**
   * @minLength 0
   * @maxLength 100
   */
  lastChanged?: string | null;
  /**
   * @minLength 0
   * @maxLength 30
   */
  createdBy?: string | null;
  /** @format date-time */
  createdDate?: string;
  /**
   * @minLength 0
   * @maxLength 30
   */
  modifiedByUser?: string | null;
  /** @format date-time */
  modfiedDate?: string;
}

export interface UserCreateRequest {
  username?: string | null;
  password?: string | null;
}

export interface UserPermissionsRequest {
  /** @format int32 */
  userId?: number;
  permissionNames?: string[] | null;
}

export interface UserPermissionDhtmlxGridRow {
  /** @format int32 */
  userId?: number;
  username?: string | null;
  permissionName?: string | null;
  status?: boolean;
}

export interface VehicleRequest {
  /** @format int32 */
  vehicleId?: number;
  vehicleLpn?: string | null;
  vehicleTable?: string | null;
  /** @format int32 */
  vehicleManufacturerId?: number;
  /** @format int32 */
  vehicleModelId?: number;
  vehicleNotes?: VehicleNote[] | null;
  /** @format int32 */
  vehicleYear?: number;
  vehicleStatus?: VehicleStatus;
  devicesIds?: number[] | null;
  /** @format int32 */
  clientFirmId?: number;
  clientFirmName?: string | null;
  /** @format int32 */
  vehicleEuroId?: number;
  vehicleEuroName?: string | null;
  invoiceStatus?: boolean;
  /** @format date-time */
  invoiceStartDate?: string;
  tacho?: boolean;
}

export interface VehicleModelRequest {
  vehicleModelName?: string | null;
  /** @format int32 */
  vehicleModelManufacturerId?: number;
  /** @format int32 */
  vehicleModelYear?: number;
}

export interface VehicleManufacturerRequest {
  vehicleManufacturerName?: string | null;
}

export interface AssignTarifRequest {
  vehicleIds?: number[] | null;
  tarifIds?: number[] | null;
}

export interface ChangeVehicleInvoiceDateRequest {
  /** @format int32 */
  vehicleId?: number;
  /** @format date-time */
  invoiceStartDate?: string;
}

export interface ChangeVehicleStatusRequest {
  /** @format int32 */
  vehicleId?: number;
  vehicleStatus?: VehicleStatus;
}

export interface VehicleNoteRequest {
  note?: string | null;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios;
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Billing API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name LoginCreate
     * @request POST:/auth/login
     */
    loginCreate: (data: AuthRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name LogoutCreate
     * @request POST:/auth/logout
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: "POST",
        ...params,
      }),
  };
  clientfirms = {
    /**
     * No description
     *
     * @tags ClientFirms
     * @name ClientfirmsCreate
     * @request POST:/clientfirms
     */
    clientfirmsCreate: (data: ClientFirmRequest, params: RequestParams = {}) =>
      this.request<ClientFirm, any>({
        path: `/clientfirms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name ClientfirmsList
     * @request GET:/clientfirms
     */
    clientfirmsList: (params: RequestParams = {}) =>
      this.request<ClientFirmDto[], any>({
        path: `/clientfirms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name ClientfirmsDelete
     * @request DELETE:/clientfirms/{id}
     */
    clientfirmsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/clientfirms/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name ClientfirmsDetail
     * @request GET:/clientfirms/{id}
     */
    clientfirmsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ClientFirmDto, any>({
        path: `/clientfirms/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name DhtmlxcomboList
     * @request GET:/clientfirms/dhtmlxcombo
     */
    dhtmlxcomboList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/clientfirms/dhtmlxcombo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name PhonesCreate
     * @request POST:/clientfirms/{id}/phones
     */
    phonesCreate: (id: number, data: PhoneDto, params: RequestParams = {}) =>
      this.request<PhoneDto[], any>({
        path: `/clientfirms/${id}/phones`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name PhonesDelete
     * @request DELETE:/clientfirms/{id}/phones
     */
    phonesDelete: (id: number, data: PhoneDto, params: RequestParams = {}) =>
      this.request<PhoneDto[], any>({
        path: `/clientfirms/${id}/phones`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name PhonesDetail
     * @request GET:/clientfirms/{id}/phones
     */
    phonesDetail: (id: number, params: RequestParams = {}) =>
      this.request<PhoneDto[], any>({
        path: `/clientfirms/${id}/phones`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name EmailsDetail
     * @request GET:/clientfirms/{id}/emails
     */
    emailsDetail: (id: number, params: RequestParams = {}) =>
      this.request<EmailAddressDto[], any>({
        path: `/clientfirms/${id}/emails`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name EmailsCreate
     * @request POST:/clientfirms/{id}/emails
     */
    emailsCreate: (id: number, data: EmailAddressDto, params: RequestParams = {}) =>
      this.request<EmailAddressDto[], any>({
        path: `/clientfirms/${id}/emails`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name EmailsDelete
     * @request DELETE:/clientfirms/{id}/emails
     */
    emailsDelete: (id: number, data: EmailAddressDto, params: RequestParams = {}) =>
      this.request<EmailAddressDto[], any>({
        path: `/clientfirms/${id}/emails`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesDetail
     * @request GET:/clientfirms/{id}/vehicles
     */
    vehiclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesCreate
     * @request POST:/clientfirms/{id}/vehicles
     */
    vehiclesCreate: (id: number, data: number[] | null, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesDelete
     * @request DELETE:/clientfirms/{id}/vehicles
     */
    vehiclesDelete: (id: number, data: number[] | null, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesNoinvoicegroupDetail
     * @request GET:/clientfirms/{id}/vehicles/noinvoicegroup
     */
    vehiclesNoinvoicegroupDetail: (id: number, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles/noinvoicegroup`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name InvoicegroupVehiclesDetail
     * @request GET:/clientfirms/{id}/invoicegroup/{invoiceGroupId}/vehicles
     */
    invoicegroupVehiclesDetail: (id: number, invoiceGroupId: number, params: RequestParams = {}) =>
      this.request<VehicleShortDto[], any>({
        path: `/clientfirms/${id}/invoicegroup/${invoiceGroupId}/vehicles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesCreate2
     * @request POST:/clientfirms/{id}/vehicles/{vehicleId}
     * @originalName vehiclesCreate
     * @duplicate
     */
    vehiclesCreate2: (id: number, vehicleId: number, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles/${vehicleId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name VehiclesDelete2
     * @request DELETE:/clientfirms/{id}/vehicles/{vehicleId}
     * @originalName vehiclesDelete
     * @duplicate
     */
    vehiclesDelete2: (id: number, vehicleId: number, params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/clientfirms/${id}/vehicles/${vehicleId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name TarifsDetail
     * @request GET:/clientfirms/{id}/tarifs
     */
    tarifsDetail: (id: number, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/clientfirms/${id}/tarifs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name TarifsCreate
     * @request POST:/clientfirms/{id}/tarifs
     */
    tarifsCreate: (id: number, data: Tarif, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/clientfirms/${id}/tarifs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name TarifsTypesList
     * @request GET:/clientfirms/tarifs/types
     */
    tarifsTypesList: (params: RequestParams = {}) =>
      this.request<TarifType[], any>({
        path: `/clientfirms/tarifs/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name TarifsDelete
     * @request DELETE:/clientfirms/{id}/tarifs/{tarifId}
     */
    tarifsDelete: (id: number, tarifId: number, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/clientfirms/${id}/tarifs/${tarifId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name InvoicedetailsDetail
     * @request GET:/clientfirms/{clientFirmId}/invoicedetails
     */
    invoicedetailsDetail: (clientFirmId: number, params: RequestParams = {}) =>
      this.request<InvoiceDetails[], any>({
        path: `/clientfirms/${clientFirmId}/invoicedetails`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name InvoicedetailsCreate
     * @request POST:/clientfirms/{clientFirmId}/invoicedetails
     */
    invoicedetailsCreate: (clientFirmId: number, data: InvoiceDetails, params: RequestParams = {}) =>
      this.request<InvoiceDetails[], any>({
        path: `/clientfirms/${clientFirmId}/invoicedetails`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientFirms
     * @name InvoicedetailsDelete
     * @request DELETE:/clientfirms/{clientFirmId}/invoicedetails/{invoicedetailsid}
     */
    invoicedetailsDelete: (
      clientFirmId: number,
      invoiceDetailsId: number,
      invoicedetailsid: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/clientfirms/${clientFirmId}/invoicedetails/${invoicedetailsid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  devices = {
    /**
     * No description
     *
     * @tags Device
     * @name DevicesCreate
     * @request POST:/devices
     */
    devicesCreate: (data: DeviceRequest, params: RequestParams = {}) =>
      this.request<Device, any>({
        path: `/devices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name AddmultipleCreate
     * @request POST:/devices/addmultiple
     */
    addmultipleCreate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<Record<string, boolean>, any>({
        path: `/devices/addmultiple`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicesDetail
     * @request GET:/devices/{activeTables}
     */
    devicesDetail: (activeTables: boolean, params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/devices/${activeTables}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name FreeList
     * @request GET:/devices/free
     */
    freeList: (params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/devices/free`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name VehicleDetail
     * @request GET:/devices/vehicle/{vehicleId}
     */
    vehicleDetail: (vehicleId: number, params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/devices/vehicle/${vehicleId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DhtmlxgridList
     * @request GET:/devices/dhtmlxgrid
     */
    dhtmlxgridList: (params: RequestParams = {}) =>
      this.request<DhtmlxGridJson, any>({
        path: `/devices/dhtmlxgrid`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name ModelscomboList
     * @request GET:/devices/modelscombo
     */
    modelscomboList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/devices/modelscombo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name SimcardscomboList
     * @request GET:/devices/simcardscombo
     */
    simcardscomboList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/devices/simcardscombo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicesDelete
     * @request DELETE:/devices/{id}
     */
    devicesDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/devices/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name TypeList
     * @request GET:/devices/type
     */
    typeList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/devices/type`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name TypeCreate
     * @request POST:/devices/type
     */
    typeCreate: (data: DeviceTypeRequest, params: RequestParams = {}) =>
      this.request<DeviceType, any>({
        path: `/devices/type`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name ModelCreate
     * @request POST:/devices/model
     */
    modelCreate: (data: DeviceModelRequest, params: RequestParams = {}) =>
      this.request<DeviceModel, any>({
        path: `/devices/model`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicenoteCreate
     * @request POST:/devices/{deviceid}/devicenote
     */
    devicenoteCreate: (deviceid: number, data: DeviceNoteRequest, params: RequestParams = {}) =>
      this.request<DeviceNote[], any>({
        path: `/devices/${deviceid}/devicenote`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicenoteDelete
     * @request DELETE:/devices/{deviceid}/devicenote/{devicenoteid}
     */
    devicenoteDelete: (deviceid: number, devicenoteid: number, params: RequestParams = {}) =>
      this.request<DeviceNote[], any>({
        path: `/devices/${deviceid}/devicenote/${devicenoteid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  employee = {
    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeCreate
     * @request POST:/employee
     */
    employeeCreate: (data: EmployeeRequest, params: RequestParams = {}) =>
      this.request<Employee, any>({
        path: `/employee`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeList
     * @request GET:/employee
     */
    employeeList: (params: RequestParams = {}) =>
      this.request<EmployeeDto[], any>({
        path: `/employee`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name EmployeeDelete
     * @request DELETE:/employee/{id}
     */
    employeeDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/employee/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name PositionsCreate
     * @request POST:/employee/positions
     */
    positionsCreate: (data: EmployeePositionRequest, params: RequestParams = {}) =>
      this.request<EmployeePosition, any>({
        path: `/employee/positions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name PositionsList
     * @request GET:/employee/positions
     */
    positionsList: (params: RequestParams = {}) =>
      this.request<EmployeePosition[], any>({
        path: `/employee/positions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name PositionsDelete
     * @request DELETE:/employee/positions/{id}
     */
    positionsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/employee/positions/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  gpsprogram = {
    /**
     * No description
     *
     * @tags GPSProgram
     * @name IgwebCreate
     * @summary Requests to IG-WEB
     * @request POST:/gpsprogram/igweb
     */
    igwebCreate: (data: OtherProgramRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/gpsprogram/igweb`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name RemotetokenList
     * @summary Gets remote token
     * @request GET:/gpsprogram/remotetoken
     */
    remotetokenList: (params: RequestParams = {}) =>
      this.request<RemoteTokenResponse, any>({
        path: `/gpsprogram/remotetoken`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesChangefirmCreate
     * @summary change vehicle firm
     * @request POST:/gpsprogram/vehicles/{cartable}/changefirm/{newgpsfirmname}
     */
    vehiclesChangefirmCreate: (cartable: string | null, newgpsfirmname: string | null, params: RequestParams = {}) =>
      this.request<GPSProgramResultResponce, any>({
        path: `/gpsprogram/vehicles/${cartable}/changefirm/${newgpsfirmname}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsVehiclesCreate
     * @summary Register new car to gps program
     * @request POST:/gpsprogram/firms/{firmName}/vehicles/{cartable}
     */
    firmsVehiclesCreate: (firmName: string | null, cartable: string | null, params: RequestParams = {}) =>
      this.request<GPSProgramResultResponce, any>({
        path: `/gpsprogram/firms/${firmName}/vehicles/${cartable}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsVehiclesDelete
     * @summary delete car from firm in gps program
     * @request DELETE:/gpsprogram/firms/{firmName}/vehicles/{cartable}
     */
    firmsVehiclesDelete: (firmName: string | null, cartable: string | null, params: RequestParams = {}) =>
      this.request<GPSProgramResultResponce, any>({
        path: `/gpsprogram/firms/${firmName}/vehicles/${cartable}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsList
     * @request GET:/gpsprogram/firms
     */
    firmsList: (params: RequestParams = {}) =>
      this.request<GPSFirm[], any>({
        path: `/gpsprogram/firms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsFreeList
     * @request GET:/gpsprogram/firms/free
     */
    firmsFreeList: (params: RequestParams = {}) =>
      this.request<GPSFirm[], any>({
        path: `/gpsprogram/firms/free`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsAccountsDetail
     * @request GET:/gpsprogram/firms/{firmname}/accounts
     */
    firmsAccountsDetail: (firmname: string | null, params: RequestParams = {}) =>
      this.request<GPSAccount[], any>({
        path: `/gpsprogram/firms/${firmname}/accounts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name FirmsAccountsList
     * @request GET:/gpsprogram/firms/accounts
     */
    firmsAccountsList: (params: RequestParams = {}) =>
      this.request<GPSFirmWithAccounts[], any>({
        path: `/gpsprogram/firms/accounts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name AccountsFreezeCreate
     * @summary Freeze user account
     * @request POST:/gpsprogram/accounts/{username}/freeze
     */
    accountsFreezeCreate: (username: string | null, params: RequestParams = {}) =>
      this.request<GPSAccount, any>({
        path: `/gpsprogram/accounts/${username}/freeze`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * @description in body put only date in format "2022-06-13"
     *
     * @tags GPSProgram
     * @name AccountsUnfreezeCreate
     * @summary Unfreeze user account
     * @request POST:/gpsprogram/accounts/{username}/unfreeze
     */
    accountsUnfreezeCreate: (username: string | null, data: string, params: RequestParams = {}) =>
      this.request<GPSAccount, any>({
        path: `/gpsprogram/accounts/${username}/unfreeze`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description in body put only date in format "2022-06-13"
     *
     * @tags GPSProgram
     * @name AccountsChangeaccountpasswordCreate
     * @summary Change account password except admin
     * @request POST:/gpsprogram/accounts/{username}/changeaccountpassword
     */
    accountsChangeaccountpasswordCreate: (username: string | null, data: string | null, params: RequestParams = {}) =>
      this.request<GPSAccount, any>({
        path: `/gpsprogram/accounts/${username}/changeaccountpassword`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name AccountsVehiclesDetail
     * @request GET:/gpsprogram/accounts/{username}/vehicles
     */
    accountsVehiclesDetail: (username: string | null, params: RequestParams = {}) =>
      this.request<GPSAccountVehicle[], any>({
        path: `/gpsprogram/accounts/${username}/vehicles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name AccountsDetail
     * @request GET:/gpsprogram/accounts/{username}
     */
    accountsDetail: (username: string | null, params: RequestParams = {}) =>
      this.request<GPSAccount, any>({
        path: `/gpsprogram/accounts/${username}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Also return current IMEI!
     *
     * @tags GPSProgram
     * @name OnlinedataDetail
     * @summary If carTable is missing, returns 1000 records ordered by dateTime desc
     * @request GET:/gpsprogram/onlinedata/{carTable}
     */
    onlinedataDetail: (carTable: string | null, params: RequestParams = {}) =>
      this.request<OnlineData[], any>({
        path: `/gpsprogram/onlinedata/${carTable}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description If carTable = null, returns all records!
     *
     * @tags GPSProgram
     * @name CarregisterDetail
     * @summary If carTable = null, returns all records
     * @request GET:/gpsprogram/carregister/{carTable}
     */
    carregisterDetail: (carTable: string | null, params: RequestParams = {}) =>
      this.request<CarEntry[], any>({
        path: `/gpsprogram/carregister/${carTable}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Add car register with list of IMEI
     *
     * @tags GPSProgram
     * @name CarregisterCreate
     * @summary Add car register with list of IMEI
     * @request POST:/gpsprogram/carregister
     */
    carregisterCreate: (data: string[] | null, params: RequestParams = {}) =>
      this.request<AddCarRegisterWithImeiResponse, any>({
        path: `/gpsprogram/carregister`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Remove car register with IMEI
     *
     * @tags GPSProgram
     * @name CarregisterDelete
     * @summary Remove car register with IMEI
     * @request DELETE:/gpsprogram/carregister/{rowid}
     */
    carregisterDelete: (rowid: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsprogram/carregister/${rowid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * @description Change table with IMEI
     *
     * @tags GPSProgram
     * @name CarregisterTableUpdate
     * @summary Change table with IMEI
     * @request PUT:/gpsprogram/carregister/{imei}/table/{newTable}
     */
    carregisterTableUpdate: (imei: string | null, newTable: string | null, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsprogram/carregister/${imei}/table/${newTable}`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * @description Existing tables
     *
     * @tags GPSProgram
     * @name TableregisterList
     * @summary Existing tables
     * @request GET:/gpsprogram/tableregister
     */
    tableregisterList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/gpsprogram/tableregister`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesCreate
     * @summary Add vehicle to account
     * @request POST:/gpsprogram/vehicles
     */
    vehiclesCreate: (data: AddGPSVehicleToAccountRequest, params: RequestParams = {}) =>
      this.request<GPSProgramResultResponce, any>({
        path: `/gpsprogram/vehicles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesDelete
     * @summary Remove vehicle from account
     * @request DELETE:/gpsprogram/vehicles
     */
    vehiclesDelete: (data: RemoveGPSVehicleFromAccountRequest, params: RequestParams = {}) =>
      this.request<GPSProgramResultResponce, any>({
        path: `/gpsprogram/vehicles`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesChangevehiclealiasCreate
     * @request POST:/gpsprogram/vehicles/changevehiclealias
     */
    vehiclesChangevehiclealiasCreate: (data: RenameGpsVehicleRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsprogram/vehicles/changevehiclealias`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesChangevehiclealiasinallaccountsCreate
     * @request POST:/gpsprogram/vehicles/changevehiclealiasinallaccounts
     */
    vehiclesChangevehiclealiasinallaccountsCreate: (
      data: RenameGpsVehicleInAllAccountsRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/gpsprogram/vehicles/changevehiclealiasinallaccounts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name RowdataCreate
     * @request POST:/gpsprogram/rowdata
     */
    rowdataCreate: (data: RowCarDataRequest, params: RequestParams = {}) =>
      this.request<RowCarData[], any>({
        path: `/gpsprogram/rowdata`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesSearchDetail
     * @summary Search in alias or table to find vehicle in all accounts
     * @request GET:/gpsprogram/vehicles/search/{part}
     */
    vehiclesSearchDetail: (part: string | null, params: RequestParams = {}) =>
      this.request<GPSAccountVehicle[], any>({
        path: `/gpsprogram/vehicles/search/${part}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name VehiclesNotmappedList
     * @summary Search in alias or table to find vehicle in all accounts
     * @request GET:/gpsprogram/vehicles/notmapped
     */
    vehiclesNotmappedList: (params: RequestParams = {}) =>
      this.request<GPSVehiclesWithFirm[], any>({
        path: `/gpsprogram/vehicles/notmapped`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name GetallcardataDetail
     * @summary Get all vehicle data from all programs. Return default 100 rows
     * @request GET:/gpsprogram/getallcardata/{rows}/{refresh}
     */
    getallcardataDetail: (rows: number | null, refresh: boolean | null, params: RequestParams = {}) =>
      this.request<AllCarData[], any>({
        path: `/gpsprogram/getallcardata/${rows}/${refresh}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name GpscommandCreate
     * @summary Gps command to device
     * @request POST:/gpsprogram/gpscommand
     */
    gpscommandCreate: (data: GpsCommandRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsprogram/gpscommand`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name GpscommandDetail
     * @summary Get gps commands for device
     * @request GET:/gpsprogram/gpscommand/{IMEI}
     */
    gpscommandDetail: (iMei: string | null, imei: string, params: RequestParams = {}) =>
      this.request<GpsCommand[], any>({
        path: `/gpsprogram/gpscommand/${imei}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name SmscommandCreate
     * @request POST:/gpsprogram/smscommand
     */
    smscommandCreate: (data: SmsCommandRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsprogram/smscommand`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GPSProgram
     * @name SmscommandDetail
     * @request GET:/gpsprogram/smscommand/{gsm_number}
     */
    smscommandDetail: (gsmNumber: string | null, params: RequestParams = {}) =>
      this.request<SmsCommand[], any>({
        path: `/gpsprogram/smscommand/${gsmNumber}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  gpsrfidcards = {
    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GpsrfidcardsList
     * @request GET:/gpsrfidcards
     */
    gpsrfidcardsList: (params: RequestParams = {}) =>
      this.request<RfidCardDto[], any>({
        path: `/gpsrfidcards`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GpsrfidcardsCreate
     * @request POST:/gpsrfidcards
     */
    gpsrfidcardsCreate: (data: RfidCardDto, params: RequestParams = {}) =>
      this.request<RfidCardDto, any>({
        path: `/gpsrfidcards`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsList
     * @request GET:/gpsrfidcards/subgroups
     */
    subgroupsList: (params: RequestParams = {}) =>
      this.request<RfidSubGroupDto[], any>({
        path: `/gpsrfidcards/subgroups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsCreate
     * @request POST:/gpsrfidcards/subgroups
     */
    subgroupsCreate: (data: RfidSubGroupDto, params: RequestParams = {}) =>
      this.request<RfidSubGroupDto, any>({
        path: `/gpsrfidcards/subgroups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsCardsDetail
     * @request GET:/gpsrfidcards/subgroups/{id}/cards
     */
    subgroupsCardsDetail: (id: number, params: RequestParams = {}) =>
      this.request<RfidCardDto[], any>({
        path: `/gpsrfidcards/subgroups/${id}/cards`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsList
     * @request GET:/gpsrfidcards/groups
     */
    groupsList: (params: RequestParams = {}) =>
      this.request<RfidGroupDto[], any>({
        path: `/gpsrfidcards/groups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsCreate
     * @request POST:/gpsrfidcards/groups
     */
    groupsCreate: (data: RfidGroupDto, params: RequestParams = {}) =>
      this.request<RfidGroupDto, any>({
        path: `/gpsrfidcards/groups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsSubgroupsDetail
     * @request GET:/gpsrfidcards/groups/{id}/subgroups
     */
    groupsSubgroupsDetail: (id: number, params: RequestParams = {}) =>
      this.request<RfidSubGroupDto[], any>({
        path: `/gpsrfidcards/groups/${id}/subgroups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsCardsCreate
     * @request POST:/gpsrfidcards/subgroups/{subGroupId}/cards/{cardId}
     */
    subgroupsCardsCreate: (subGroupId: number, cardId: number, params: RequestParams = {}) =>
      this.request<RfidCardDto[], any>({
        path: `/gpsrfidcards/subgroups/${subGroupId}/cards/${cardId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsCardsDelete
     * @request DELETE:/gpsrfidcards/subgroups/{subGroupId}/cards/{cardId}
     */
    subgroupsCardsDelete: (subGroupId: number, cardId: number, params: RequestParams = {}) =>
      this.request<RfidCardDto[], any>({
        path: `/gpsrfidcards/subgroups/${subGroupId}/cards/${cardId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsSubgroupsCreate
     * @request POST:/gpsrfidcards/groups/{groupId}/subgroups/{subGroupId}
     */
    groupsSubgroupsCreate: (groupId: number, subGroupId: number, params: RequestParams = {}) =>
      this.request<RfidSubGroupDto[], any>({
        path: `/gpsrfidcards/groups/${groupId}/subgroups/${subGroupId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsSubgroupsDelete
     * @request DELETE:/gpsrfidcards/groups/{groupId}/subgroups/{subGroupId}
     */
    groupsSubgroupsDelete: (groupId: number, subGroupId: number, params: RequestParams = {}) =>
      this.request<RfidSubGroupDto[], any>({
        path: `/gpsrfidcards/groups/${groupId}/subgroups/${subGroupId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GpsrfidcardsDelete
     * @request DELETE:/gpsrfidcards/{id}
     */
    gpsrfidcardsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsrfidcards/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name SubgroupsDelete
     * @request DELETE:/gpsrfidcards/subgroups/{id}
     */
    subgroupsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsrfidcards/subgroups/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GpsRfidCards
     * @name GroupsDelete
     * @request DELETE:/gpsrfidcards/groups/{id}
     */
    groupsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/gpsrfidcards/groups/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  internaltasks = {
    /**
     * No description
     *
     * @tags InternalTask
     * @name InternaltasksList
     * @request GET:/internaltasks
     */
    internaltasksList: (params: RequestParams = {}) =>
      this.request<InternalTaskDto[], any>({
        path: `/internaltasks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name InternaltasksCreate
     * @request POST:/internaltasks
     */
    internaltasksCreate: (data: InternalTaskRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name InternaltasksDetail
     * @request GET:/internaltasks/{internalTaskId}
     */
    internaltasksDetail: (internalTaskId: number, params: RequestParams = {}) =>
      this.request<InternalTaskDto, any>({
        path: `/internaltasks/${internalTaskId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name OwninternaltasksList
     * @request GET:/internaltasks/owninternaltasks
     */
    owninternaltasksList: (params: RequestParams = {}) =>
      this.request<InternalTaskDto[], any>({
        path: `/internaltasks/owninternaltasks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name StatusesList
     * @request GET:/internaltasks/statuses
     */
    statusesList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/statuses`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name StatusesCreate
     * @request POST:/internaltasks/statuses
     */
    statusesCreate: (data: ChangeStatusRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/statuses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name OpenCreate
     * @request POST:/internaltasks/open/{internalTaskId}
     */
    openCreate: (internalTaskId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/open/${internalTaskId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name AssignCreate
     * @request POST:/internaltasks/assign
     */
    assignCreate: (data: AssignEmployeeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/assign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name UnassignCreate
     * @request POST:/internaltasks/unassign
     */
    unassignCreate: (data: AssignEmployeeRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/unassign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InternalTask
     * @name RepliesCreate
     * @request POST:/internaltasks/replies
     */
    repliesCreate: (data: InternalTaskReplyRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internaltasks/replies`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  invoices = {
    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicesList
     * @request GET:/invoices
     */
    invoicesList: (params: RequestParams = {}) =>
      this.request<Invoice[], any>({
        path: `/invoices`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name PeriodCreate
     * @request POST:/invoices/period
     */
    periodCreate: (data: InvoicesForPeriodRequest, params: RequestParams = {}) =>
      this.request<Invoice[], any>({
        path: `/invoices/period`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name GeneratetestCreate
     * @request POST:/invoices/generatetest
     */
    generatetestCreate: (data: GenerateTestInvoicesRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/invoices/generatetest`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicesDelete
     * @request DELETE:/invoices/{invoiceId}
     */
    invoicesDelete: (invoiceId: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/invoices/${invoiceId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name ClientfirmDetail
     * @request GET:/invoices/clientfirm/{id}
     */
    clientfirmDetail: (id: number, params: RequestParams = {}) =>
      this.request<Invoice[], any>({
        path: `/invoices/clientfirm/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name FilterList
     * @request GET:/invoices/filter
     */
    filterList: (data: InvoicesFilterByDateRequest, params: RequestParams = {}) =>
      this.request<Invoice[], any>({
        path: `/invoices/filter`,
        method: "GET",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name DownloadDetail
     * @request GET:/invoices/download/{id}
     */
    downloadDetail: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invoices/download/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsList
     * @request GET:/invoices/invoicegroups
     */
    invoicegroupsList: (params: RequestParams = {}) =>
      this.request<InvoiceGroupDto[], any>({
        path: `/invoices/invoicegroups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsCreate
     * @request POST:/invoices/invoicegroups
     */
    invoicegroupsCreate: (data: InvoiceGroupDto, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto, any>({
        path: `/invoices/invoicegroups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsEmailDetail
     * @request GET:/invoices/invoicegroups/email/{invoiceGroupId}
     */
    invoicegroupsEmailDetail: (invoiceGroupId: number, params: RequestParams = {}) =>
      this.request<EmailAddressDto[], any>({
        path: `/invoices/invoicegroups/email/${invoiceGroupId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsEmailCreate
     * @request POST:/invoices/invoicegroups/email
     */
    invoicegroupsEmailCreate: (data: InvoiceGroupEmailRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/invoices/invoicegroups/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsEmailDelete
     * @request DELETE:/invoices/invoicegroups/email
     */
    invoicegroupsEmailDelete: (data: InvoiceGroupEmailRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/invoices/invoicegroups/email`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsInvoicedetailsDetail
     * @request GET:/invoices/invoicegroups/invoicedetails/{invoiceDetailsId}
     */
    invoicegroupsInvoicedetailsDetail: (invoiceDetailsId: number, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto[], any>({
        path: `/invoices/invoicegroups/invoicedetails/${invoiceDetailsId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsClientfirmsDetail
     * @request GET:/invoices/invoicegroups/clientfirms/{clientfirmid}
     */
    invoicegroupsClientfirmsDetail: (clientfirmid: number, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto[], any>({
        path: `/invoices/invoicegroups/clientfirms/${clientfirmid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsVehiclesDetail
     * @request GET:/invoices/invoicegroups/vehicles/{vehicleid}
     */
    invoicegroupsVehiclesDetail: (vehicleid: number, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto[], any>({
        path: `/invoices/invoicegroups/vehicles/${vehicleid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsDelete
     * @request DELETE:/invoices/invoicegroups/{id}
     */
    invoicegroupsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/invoices/invoicegroups/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsVehiclesCreate
     * @request POST:/invoices/invoicegroups/vehicles
     */
    invoicegroupsVehiclesCreate: (data: InvoiceGroupModifyRequest, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto, any>({
        path: `/invoices/invoicegroups/vehicles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicegroupsVehiclesDelete
     * @request DELETE:/invoices/invoicegroups/vehicles
     */
    invoicegroupsVehiclesDelete: (data: InvoiceGroupModifyRequest, params: RequestParams = {}) =>
      this.request<InvoiceGroupDto, any>({
        path: `/invoices/invoicegroups/vehicles`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicenoteDetail
     * @request GET:/invoices/{invoiceid}/invoicenote
     */
    invoicenoteDetail: (invoiceid: number, params: RequestParams = {}) =>
      this.request<InvoiceNote[], any>({
        path: `/invoices/${invoiceid}/invoicenote`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicenoteCreate
     * @request POST:/invoices/{invoiceid}/invoicenote
     */
    invoicenoteCreate: (invoiceid: number, data: InvoiceNoteRequest, params: RequestParams = {}) =>
      this.request<InvoiceNote[], any>({
        path: `/invoices/${invoiceid}/invoicenote`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoicenoteDelete
     * @request DELETE:/invoices/{invoiceid}/invoicenote/{invoicenoteid}
     */
    invoicenoteDelete: (invoiceid: number, invoicenoteid: number, params: RequestParams = {}) =>
      this.request<InvoiceNote[], any>({
        path: `/invoices/${invoiceid}/invoicenote/${invoicenoteid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name PaystatusCreate
     * @request POST:/invoices/paystatus
     */
    paystatusCreate: (data: InvoicePayRequest, params: RequestParams = {}) =>
      this.request<Invoice, any>({
        path: `/invoices/paystatus`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name SettingsCreate
     * @request POST:/invoices/settings
     */
    settingsCreate: (data: InvoiceSettings, params: RequestParams = {}) =>
      this.request<InvoiceSettings, any>({
        path: `/invoices/settings`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name SettingsList
     * @request GET:/invoices/settings
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<InvoiceSettings, any>({
        path: `/invoices/settings`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  simcards = {
    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardsList
     * @request GET:/simcards
     */
    simcardsList: (params: RequestParams = {}) =>
      this.request<SimCardDto[], any>({
        path: `/simcards`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardsCreate
     * @request POST:/simcards
     */
    simcardsCreate: (data: SimCardRequest, params: RequestParams = {}) =>
      this.request<SimCard, any>({
        path: `/simcards`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name DhtmlxgridList
     * @request GET:/simcards/dhtmlxgrid
     */
    dhtmlxgridList: (params: RequestParams = {}) =>
      this.request<DhtmlxGridJson, any>({
        path: `/simcards/dhtmlxgrid`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardsDetail
     * @request GET:/simcards/{id}
     */
    simcardsDetail: (id: number, params: RequestParams = {}) =>
      this.request<SimCard, any>({
        path: `/simcards/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardsDelete
     * @request DELETE:/simcards/{id}
     */
    simcardsDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/simcards/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name OperatorsList
     * @request GET:/simcards/operators
     */
    operatorsList: (params: RequestParams = {}) =>
      this.request<SimCardOperator[], any>({
        path: `/simcards/operators`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name OperatorsCreate
     * @request POST:/simcards/operators
     */
    operatorsCreate: (data: SimCardOperatorCreateRequest, params: RequestParams = {}) =>
      this.request<SimCardOperator, any>({
        path: `/simcards/operators`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name OperatorsComboList
     * @request GET:/simcards/operators/combo
     */
    operatorsComboList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/simcards/operators/combo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardnoteCreate
     * @request POST:/simcards/{simcardid}/simcardnote
     */
    simcardnoteCreate: (simcardid: number, data: SimCardNoteRequest, params: RequestParams = {}) =>
      this.request<SimCardNote[], any>({
        path: `/simcards/${simcardid}/simcardnote`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SimCard
     * @name SimcardnoteDelete
     * @request DELETE:/simcards/{simcardid}/simcardnote/{simcardnoteid}
     */
    simcardnoteDelete: (simcardid: number, simcardnoteid: number, params: RequestParams = {}) =>
      this.request<SimCardNote[], any>({
        path: `/simcards/${simcardid}/simcardnote/${simcardnoteid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  toll = {
    /**
     * No description
     *
     * @tags Toll
     * @name ExchangestatusList
     * @request GET:/toll/exchangestatus
     */
    exchangestatusList: (params: RequestParams = {}) =>
      this.request<TollDebugModel[], any>({
        path: `/toll/exchangestatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name TrailCreate
     * @request POST:/toll/trail
     */
    trailCreate: (data: TrailRequest, params: RequestParams = {}) =>
      this.request<TrailResponse, any>({
        path: `/toll/trail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name PostToll
     * @request POST:/toll/log
     */
    postToll: (data: LogRequest, params: RequestParams = {}) =>
      this.request<LogResponse, any>({
        path: `/toll/log`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name CompareCreate
     * @request POST:/toll/compare
     */
    compareCreate: (data: CompareRequest, params: RequestParams = {}) =>
      this.request<CompareResponse, any>({
        path: `/toll/compare`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name ComparerequestsCreate
     * @request POST:/toll/comparerequests
     */
    comparerequestsCreate: (data: CompareRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/toll/comparerequests`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name ComparerequestsList
     * @request GET:/toll/comparerequests
     */
    comparerequestsList: (params: RequestParams = {}) =>
      this.request<CompareRequest[], any>({
        path: `/toll/comparerequests`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name ComparerequestsDetail
     * @request GET:/toll/comparerequests/{requestId}
     */
    comparerequestsDetail: (requestId: number, params: RequestParams = {}) =>
      this.request<CompareResponse, any>({
        path: `/toll/comparerequests/${requestId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name MultiplecompareCreate
     * @request POST:/toll/multiplecompare
     */
    multiplecompareCreate: (data: CompareMultipleRequest, params: RequestParams = {}) =>
      this.request<CompareResponse[], any>({
        path: `/toll/multiplecompare`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Toll
     * @name AccountunitsList
     * @request GET:/toll/accountunits
     */
    accountunitsList: (params: RequestParams = {}) =>
      this.request<AccountUnit[], any>({
        path: `/toll/accountunits`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersList
     * @request GET:/users
     */
    usersList: (params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/users`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersCreate
     * @request POST:/users
     */
    usersCreate: (data: UserCreateRequest, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CheckIfAuthenticatedList
     * @request GET:/users/checkIfAuthenticated
     */
    checkIfAuthenticatedList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/checkIfAuthenticated`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name TestDetail
     * @request GET:/users/test/{count}
     */
    testDetail: (count: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/users/test/${count}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDetail
     * @request GET:/users/{id}
     */
    usersDetail: (id: number, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDelete
     * @request DELETE:/users/{id}
     */
    usersDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/users/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PermissionsList
     * @request GET:/users/permissions
     */
    permissionsList: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/users/permissions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PermissionsCreate
     * @request POST:/users/permissions
     */
    permissionsCreate: (data: UserPermissionsRequest, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/permissions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PermissionsDelete
     * @request DELETE:/users/permissions
     */
    permissionsDelete: (data: UserPermissionsRequest, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/permissions`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PermissionsDetail
     * @request GET:/users/permissions/{id}
     */
    permissionsDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserPermissionDhtmlxGridRow[], any>({
        path: `/users/permissions/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  vehicles = {
    /**
     * No description
     *
     * @tags Vehicle
     * @name VehiclesCreate
     * @request POST:/vehicles
     */
    vehiclesCreate: (data: VehicleRequest, params: RequestParams = {}) =>
      this.request<Vehicle, any>({
        path: `/vehicles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name VehiclesList
     * @request GET:/vehicles
     */
    vehiclesList: (params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/vehicles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name VehiclesDelete
     * @request DELETE:/vehicles/{id}
     */
    vehiclesDelete: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name DevicesDetail
     * @request GET:/vehicles/{vehicleId}/devices
     */
    devicesDetail: (vehicleId: number, params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/vehicles/${vehicleId}/devices`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name DevicesCreate
     * @request POST:/vehicles/{vehicleId}/devices
     */
    devicesCreate: (vehicleId: number, data: number[] | null, params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/vehicles/${vehicleId}/devices`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name DevicesDelete
     * @request DELETE:/vehicles/{vehicleId}/devices
     */
    devicesDelete: (vehicleId: number, data: number[] | null, params: RequestParams = {}) =>
      this.request<DeviceDto[], any>({
        path: `/vehicles/${vehicleId}/devices`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TestList
     * @request GET:/vehicles/test
     */
    testList: (params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/vehicles/test`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name FreeList
     * @request GET:/vehicles/free
     */
    freeList: (params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/vehicles/free`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name NotarifsList
     * @request GET:/vehicles/notarifs
     */
    notarifsList: (params: RequestParams = {}) =>
      this.request<VehicleDto[], any>({
        path: `/vehicles/notarifs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name ModelsList
     * @request GET:/vehicles/models
     */
    modelsList: (params: RequestParams = {}) =>
      this.request<VehicleModel[], any>({
        path: `/vehicles/models`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name ModelsCreate
     * @request POST:/vehicles/models
     */
    modelsCreate: (data: VehicleModelRequest, params: RequestParams = {}) =>
      this.request<VehicleModel, any>({
        path: `/vehicles/models`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name ManufacturersList
     * @request GET:/vehicles/manufacturers
     */
    manufacturersList: (params: RequestParams = {}) =>
      this.request<VehicleManufacturer[], any>({
        path: `/vehicles/manufacturers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name ManufacturersCreate
     * @request POST:/vehicles/manufacturers
     */
    manufacturersCreate: (data: VehicleManufacturerRequest, params: RequestParams = {}) =>
      this.request<VehicleManufacturer, any>({
        path: `/vehicles/manufacturers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name ManufacturersDetail
     * @request GET:/vehicles/manufacturers/{id}
     */
    manufacturersDetail: (id: number, params: RequestParams = {}) =>
      this.request<VehicleManufacturer, any>({
        path: `/vehicles/manufacturers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name EurosList
     * @request GET:/vehicles/euros
     */
    eurosList: (params: RequestParams = {}) =>
      this.request<VehicleEuro[], any>({
        path: `/vehicles/euros`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsDetail
     * @request GET:/vehicles/{id}/tarifs
     */
    tarifsDetail: (id: number, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/vehicles/${id}/tarifs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsCreate
     * @request POST:/vehicles/{id}/tarifs
     */
    tarifsCreate: (id: number, data: Tarif, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/vehicles/${id}/tarifs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsTypesList
     * @request GET:/vehicles/tarifs/types
     */
    tarifsTypesList: (params: RequestParams = {}) =>
      this.request<TarifType[], any>({
        path: `/vehicles/tarifs/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsAssignCreate
     * @request POST:/vehicles/tarifs/assign
     */
    tarifsAssignCreate: (data: AssignTarifRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/tarifs/assign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsDeassignCreate
     * @request POST:/vehicles/tarifs/deassign
     */
    tarifsDeassignCreate: (data: AssignTarifRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/tarifs/deassign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsRemoveCreate
     * @request POST:/vehicles/tarifs/remove
     */
    tarifsRemoveCreate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/tarifs/remove`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name TarifsDelete
     * @request DELETE:/vehicles/{id}/tarifs/{tarifId}
     */
    tarifsDelete: (id: number, tarifId: number, params: RequestParams = {}) =>
      this.request<Tarif[], any>({
        path: `/vehicles/${id}/tarifs/${tarifId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name DhtmlxcomboList
     * @request GET:/vehicles/dhtmlxcombo
     */
    dhtmlxcomboList: (params: RequestParams = {}) =>
      this.request<DhtmlxCombo, any>({
        path: `/vehicles/dhtmlxcombo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name InvoicedateCreate
     * @request POST:/vehicles/invoicedate
     */
    invoicedateCreate: (data: ChangeVehicleInvoiceDateRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/invoicedate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name StatusCreate
     * @request POST:/vehicles/status
     */
    statusCreate: (data: ChangeVehicleStatusRequest, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/vehicles/status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name VehiclenoteCreate
     * @request POST:/vehicles/{vehicleid}/vehiclenote
     */
    vehiclenoteCreate: (vehicleid: number, data: VehicleNoteRequest, params: RequestParams = {}) =>
      this.request<VehicleNote[], any>({
        path: `/vehicles/${vehicleid}/vehiclenote`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vehicle
     * @name VehiclenoteDelete
     * @request DELETE:/vehicles/{vehicleid}/vehiclenote/{vehiclenoteid}
     */
    vehiclenoteDelete: (vehicleid: number, vehiclenoteid: number, params: RequestParams = {}) =>
      this.request<VehicleNote[], any>({
        path: `/vehicles/${vehicleid}/vehiclenote/${vehiclenoteid}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
}
