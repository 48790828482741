import * as yup from 'yup';
import {ICreateInvoiceDetails} from "../../../lib/types/types";


export const validationSchema: yup.ObjectSchema<Omit<ICreateInvoiceDetails, "invoiceDetailsId" | "providerLabel" | "receiverLabel" | "invoiceDetailsName">> = yup.object({
    recieverName: yup.string().required(),
    providerName: yup.string().required(),
    recieverAddress: yup.string().required(),
    providerAddress: yup.string().required(),
    recieverIdentityCode: yup.string().required(),
    providerIdentityCode: yup.string().required(),
    recieverIdentityCodeZDDS: yup.string().required(),
    providerIdentityCodeZDDS: yup.string().required(),
    recieverMol: yup.string().required(),
    providerMol: yup.string().required(),
    recieverBank: yup.string().optional(),
    providerBank: yup.string().optional(),
    recieverBic: yup.string().optional(),
    providerBic: yup.string().optional(),
    recieverIban: yup.string().optional(),
    providerIban: yup.string().optional(),
})
