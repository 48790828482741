import {FormElementValues} from "@xal3xfx/react-utils";

const protocolActionName: FormElementValues<'text'> = {
    type: "text",
    label: 'protocolActionName',
    props: {},
}

const deviceTypeId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'deviceType',
    props: {virtualScrollerOptions: {itemSize: 30}},
    options: []
}

export const formElements = {protocolActionName, deviceTypeId}
