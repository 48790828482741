import {DateRange, IDevice, IRawDropdownOption, IVehicle} from "../lib/types/types";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {Dropdown} from "primereact/dropdown";
import moment from "moment/moment";
import * as React from "react";
import {AllCarData} from "./backend-api";

export class UtilService {
    static generateDropdownFromData<T>(gridData: T[], columnName: keyof T, f: any) {
        const valuesSet = new Set();
        gridData.forEach((el: T) => {
            valuesSet.add(el[columnName]);
        });

        const dropdownOptions = Array.from(valuesSet).map((el, index) => {
            return {
                value: el,
                label: el,
                key: index
            }
        })
        return (options: any) => {
            return <Dropdown showClear value={options.value} options={dropdownOptions} placeholder={f({id: 'choose'})}
                             onChange={(e) => options.filterApplyCallback(e.value)} style={{textAlign: "left"}}/>;
        }
    }

    static generateDropdownOptionsFromData<T>(gridData: T[], valueColumn: keyof T, labelColumn: keyof T) {
        return gridData.reduce((acc: any, el) => {
            if (acc.some((option: any) => option.value === el[valueColumn])) {
                return acc;
            }
            return [...acc, {
                value: el[valueColumn],
                label: el[labelColumn],
                key: el[valueColumn]
            }];
        }, []);
    }

    static generateDropdownOptionsNewFormatFromData<T>(gridData: T[], valueColumn: keyof T, labelColumn: keyof T) {
        return gridData.reduce((acc: any, el) => {
            if (acc.some((option: any) => option.value === el[valueColumn])) {
                return acc;
            }
            return [...acc, {
                id: el[valueColumn],
                description: el[labelColumn],
                key: el[valueColumn]
            }];
        }, []);
    }


    static formatDropdownData(dropdownData: IRawDropdownOption[]) {
        return dropdownData.map(el => {
            return {
                key: +el.value,
                value: +el.value,
                label: el.text
            }
        })
    }

    static formatDropdownDataNewForm(dropdownData: IRawDropdownOption[]): IDropdownOption[] {
        return dropdownData.map(el => {
            return {
                key: +el.value,
                description: el.text,
                id: +el.value
            }
        })
    }

    static generateDropdownOptionsFromArray(data: string[]): IDropdownOption[] {
        return data.map((el: string) => {
            return {
                key: el,
                id: el,
                description: el
            }
        })
    }

    static addEmptyRow = (data: any): any => {
        if (data.length === 0) return data;
        const addRecursively = (firstRow: any): any => {
            return Object.keys(firstRow).reduce((acc, key) => {
                if (typeof firstRow[key] === "object") {
                    return {...acc, [key]: addRecursively(firstRow[key])};
                } else {
                    return {...acc, [key]: ""};
                }
            }, {});
        }
        const firstRow = data[0];
        const result = addRecursively(firstRow);

        return [...data, result];

    }

    static yesNoOptions: IDropdownOption[] = [
        {
            id: true,
            key: 0,
            description: 'Да'
        },
        {
            id: false,
            key: 1,
            description: 'Не'
        }
    ]

    static parseDimentievTime = (nqmamDumi: number | string) => {

        let timeAsString = nqmamDumi.toString();
        const iterations = 6 - timeAsString.length;
        for (let i = 0; i < iterations; i++) {
            timeAsString = "0" + timeAsString;
        }

        const hours = timeAsString.substring(0, 2);
        const minutes = timeAsString.substring(2, 4);
        const seconds = timeAsString.substring(4, 6);

        return `${hours}:${minutes}:${seconds}`;
    }

    static parseDimentievDate = (nqmamDumi: number | string) => {
        const dateAsString = "20" + nqmamDumi.toString();
        const year = dateAsString.substring(0, 4);
        const month = dateAsString.substring(4, 6);
        const date = dateAsString.substring(6, 8);

        return `${year}-${month}-${date}`;
    }

    static parseShortDateString = (shortDate: string) => {

        if (shortDate) {
            try {
                const x = shortDate.match(/.{2}/g);
                if (x === null) return;
                const year = parseInt(`20${x[0]}`);
                const month = parseInt(x[1]);
                const day = parseInt(x[2]);

                const formattedDate = `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
                return formattedDate;
            } catch (err) {
                console.error(err);
            }
        }
    }

    static parseTimeString = (timeString: string) => {
        if (timeString) {
            try {
                const x = timeString.match(/.{2}/g);
                if (x === null) return;
                const hours = parseInt(x[0]);
                const minutes = parseInt(x[1]);
                const seconds = parseInt(x[2]);
                //const date = new Date (year,month,day,hours,minutes)
                const formattedTime = `${hours >= 10 ? hours : '0' + hours}:${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
                return formattedTime;
            } catch
                (err) {
            }
        }
    }

    static parseNikiDateTime(dateTime: string | undefined) {
        if (dateTime == undefined)
            return "";

        const tokens = dateTime.split("T");
        const time = tokens[1].split('.')[0];
        return tokens[0] + " " + time;
    }

    static crc8(data: string) {
        const maxim = {
            init: 0x00,
            xorOut: 0x00,
            refOut: true,
            refIn: true,
            table: Uint8Array.from([
                0x00, 0x5E, 0xBC, 0xE2, 0x61, 0x3F,
                0xDD, 0x83, 0xC2, 0x9C, 0x7E, 0x20,
                0xA3, 0xFD, 0x1F, 0x41, 0x9D, 0xC3,
                0x21, 0x7F, 0xFC, 0xA2, 0x40, 0x1E,
                0x5F, 0x01, 0xE3, 0xBD, 0x3E, 0x60,
                0x82, 0xDC, 0x23, 0x7D, 0x9F, 0xC1,
                0x42, 0x1C, 0xFE, 0xA0, 0xE1, 0xBF,
                0x5D, 0x03, 0x80, 0xDE, 0x3C, 0x62,
                0xBE, 0xE0, 0x02, 0x5C, 0xDF, 0x81,
                0x63, 0x3D, 0x7C, 0x22, 0xC0, 0x9E,
                0x1D, 0x43, 0xA1, 0xFF, 0x46, 0x18,
                0xFA, 0xA4, 0x27, 0x79, 0x9B, 0xC5,
                0x84, 0xDA, 0x38, 0x66, 0xE5, 0xBB,
                0x59, 0x07, 0xDB, 0x85, 0x67, 0x39,
                0xBA, 0xE4, 0x06, 0x58, 0x19, 0x47,
                0xA5, 0xFB, 0x78, 0x26, 0xC4, 0x9A,
                0x65, 0x3B, 0xD9, 0x87, 0x04, 0x5A,
                0xB8, 0xE6, 0xA7, 0xF9, 0x1B, 0x45,
                0xC6, 0x98, 0x7A, 0x24, 0xF8, 0xA6,
                0x44, 0x1A, 0x99, 0xC7, 0x25, 0x7B,
                0x3A, 0x64, 0x86, 0xD8, 0x5B, 0x05,
                0xE7, 0xB9, 0x8C, 0xD2, 0x30, 0x6E,
                0xED, 0xB3, 0x51, 0x0F, 0x4E, 0x10,
                0xF2, 0xAC, 0x2F, 0x71, 0x93, 0xCD,
                0x11, 0x4F, 0xAD, 0xF3, 0x70, 0x2E,
                0xCC, 0x92, 0xD3, 0x8D, 0x6F, 0x31,
                0xB2, 0xEC, 0x0E, 0x50, 0xAF, 0xF1,
                0x13, 0x4D, 0xCE, 0x90, 0x72, 0x2C,
                0x6D, 0x33, 0xD1, 0x8F, 0x0C, 0x52,
                0xB0, 0xEE, 0x32, 0x6C, 0x8E, 0xD0,
                0x53, 0x0D, 0xEF, 0xB1, 0xF0, 0xAE,
                0x4C, 0x12, 0x91, 0xCF, 0x2D, 0x73,
                0xCA, 0x94, 0x76, 0x28, 0xAB, 0xF5,
                0x17, 0x49, 0x08, 0x56, 0xB4, 0xEA,
                0x69, 0x37, 0xD5, 0x8B, 0x57, 0x09,
                0xEB, 0xB5, 0x36, 0x68, 0x8A, 0xD4,
                0x95, 0xCB, 0x29, 0x77, 0xF4, 0xAA,
                0x48, 0x16, 0xE9, 0xB7, 0x55, 0x0B,
                0x88, 0xD6, 0x34, 0x6A, 0x2B, 0x75,
                0x97, 0xC9, 0x4A, 0x14, 0xF6, 0xA8,
                0x74, 0x2A, 0xC8, 0x96, 0x15, 0x4B,
                0xA9, 0xF7, 0xB6, 0xE8, 0x0A, 0x54,
                0xD7, 0x89, 0x6B, 0x35
            ])
        };

        for (var bytes = [], c = 0; c < data.length; c += 2)
            bytes.push(parseInt(data.substr(c, 2), 16));

        const {
            init,
            xorOut,
            table
        } = maxim;
        let crc = init;

        for (const b of bytes)
            crc = table[crc ^ b];

        return (('0' + ((crc ^ xorOut) & 0xFFFF).toString(16)).slice(-2)).toUpperCase();
    }

    static getLastGpsOnlineTemplate = (rowData: { lastGpsOnline: string | null } | { lasT_ONLINE: string | null }) => {
        let key: "lastGpsOnline" | "lasT_ONLINE" = "lastGpsOnline";
        if (rowData.hasOwnProperty('lastGpsOnline')) {
            key = "lastGpsOnline";
        } else key = "lasT_ONLINE";
        //@ts-ignore
        const timeSinceLastOnline = moment.duration(moment().diff(moment(rowData[key])));
        let bgColor = "";
        const firstDate = moment('0001-01-01T00:00:00');
        let isInvalid = false;
        //@ts-ignore
        if (moment(rowData[key]).isSameOrBefore(firstDate)) {
            bgColor = "#f208f2";
            isInvalid = true;
        } else if (timeSinceLastOnline.asMinutes() < 15) {
            // bgColor = "rgba(39, 164, 39, 0.77)"
            bgColor = "var(--greenColor)"
        } else if (timeSinceLastOnline.asHours() < 24) {
            bgColor = "var(--blueColor)";
        } else if (timeSinceLastOnline.asDays() < 30) {
            bgColor = "var(--30Days)";
        } else if (timeSinceLastOnline.asDays() < 180) {
            bgColor = "var(--orangeColor)"
        } else if (timeSinceLastOnline.asDays() < 180) {
            bgColor = "var(--pinkColor)"
        } else if (timeSinceLastOnline.asDays() >= 1000) {
            bgColor = "var(--redColor)"
        } else bgColor = "#f208f2";
        return <span style={{color: bgColor}}>
            {/*@ts-ignore*/}
            {isInvalid ? "Invalid Date" : moment(rowData[key]).format("DD-MM-YYYY HH:mm:ss")}
        </span>
    }


// Initialize lastOnlineGpsMap with static moments to avoid re-calculating on every access
    static lastOnlineGpsMap: Record<string, DateRange> = {
        "1": {notBefore: moment().subtract(15, "minutes"), notAfter: moment()},
        "2": {notBefore: moment().subtract(24, "hours"), notAfter: moment().subtract(15, "minutes")},
        "3": {notBefore: moment().subtract(30, "days"), notAfter: moment().subtract(24, "hours")},
        "4": {notBefore: moment().subtract(180, "days"), notAfter: moment().subtract(30, "days")},
        "5": {notBefore: moment().subtract(1000, "days"), notAfter: moment().subtract(181, "days")},
        "6": {notBefore: null, notAfter: moment().subtract(1001, "days")},
        "7": {notBefore: null, notAfter: null} // Invalid dates
    };

    static handleLastGpsOnlineFilter = (rowData: IDevice | IVehicle, filterValue: any): boolean => {
        if (filterValue.length === 0) return true;

        return filterValue.reduce((acc: boolean, el: any) => {
            const {notBefore, notAfter} = UtilService.lastOnlineGpsMap[el];

            // Check if the current record has an invalid date
            if (!rowData.lastGpsOnline) {
                return acc || (notBefore === null && notAfter === null);
            }

            const lastGpsOnlineMoment = moment(rowData.lastGpsOnline);
            if (!lastGpsOnlineMoment.isValid()) {
                return acc || (notBefore === null && notAfter === null);
            }

            let current = false;

            if (notBefore && notAfter) {
                current = lastGpsOnlineMoment.isBetween(notBefore, notAfter);
            } else if (notBefore) {
                current = lastGpsOnlineMoment.isAfter(notBefore);
            } else if (notAfter) {
                current = lastGpsOnlineMoment.isBefore(notAfter);
            }

            return acc || current;
        }, false);
    }

    static handleLastGpsOnlineFilterUnassociated(rowData: AllCarData, filterValue: any) {
        const filter = filterValue as string[];
        if (filter.length === 0) return true;
        const firstDate = moment('0001-01-01T00:00:00');

        return filter.reduce((acc, el) => {
            const notBefore = UtilService.lastOnlineGpsMap[el as keyof typeof UtilService.lastOnlineGpsMap].notBefore;
            const notAfter = UtilService.lastOnlineGpsMap[el as keyof typeof UtilService.lastOnlineGpsMap].notAfter;
            let current = false;

            if (!notBefore && !notAfter) return acc || moment(rowData.lasT_ONLINE).isSame(firstDate);
            //If anything else is selected and the current row has invalid date (null) -> return false
            if (!rowData.lasT_ONLINE) return acc || false

            const lastGpsOnlineMoment = moment(rowData.lasT_ONLINE);
            if (!lastGpsOnlineMoment.isValid()) {
                return acc || (notBefore === null && notAfter === null);
            }

            if (notBefore && notAfter) {
                current = lastGpsOnlineMoment.isBetween(notBefore, notAfter);
            } else if (notBefore) {
                current = lastGpsOnlineMoment.isAfter(notBefore);
            } else if (notAfter) {
                current = lastGpsOnlineMoment.isBefore(notAfter);
            }

            return acc || current
        }, false)

    }

    static protocolTypeOptions = [
        {id: 0, description: "Приемо – предавателен", key: 0},
        {id: 1, description: "Констативен", key: 1}
    ]

    static getVehicleStatuses = (f: any) => {
        return {
            '0': f({id: "active"}),
            '1': f({id: "inactive"}),
            '2': f({id: "activeWithoutTax"}),
            '3': f({id: "frozenWithTax"}),
            '4': f({id: "disassembled"}),
            '5': f({id: "installed"}),
            '6': f({id: "forInstallment"})
        }
    }

    static arrayBufferToBlob = (buffer: ArrayBuffer) => {
        return new Blob([buffer], { type: 'application/pdf' });
    }
}
