import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef, FormEvent} from 'react';
import {INote} from "../../lib/types/types";
import {useToast} from "../../lib/hooks/useToast";
import moment from "moment/moment";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ReactiveTable} from "react-ive-tables";

interface Props {
    id: number;
    notes: INote[];
    handleCreate: (id: number, note: string) => Promise<INote[]>
}


const Notes : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const { showToast } = useToast();

    const [note, setNote] = useState<string>();
    const [tableData, setTableData] = useState(props.notes);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const getColumnTemplate = () => {
        return {
            createdDate: (rowData: INote) => moment(rowData.createdDate).format("DD-MM-YYYY HH:mm:ss")
        }
    }

    const handleSubmitForm = (e: FormEvent) => {
        e.preventDefault();

        if(!note) return;

        props.handleCreate(props.id, note)
            .then(resp => {
                if(resp) {
                    showToast("success", f({ id: "done" }), f({ id: "noteCreatedSuccessfully" }));
                    setNote("");
                    console.log("RESP IS: ", resp);
                    setTableData(resp);
                }
            })

    }

    return <>
        <form className={"flex flex-wrap justify-content-center w-full mt-3 gap-3"} onSubmit={handleSubmitForm}>
            <InputText placeholder={f({id: "note"})} value={note} onChange={(e) => setNote(e.target.value)}/>
            <Button label={f({id: "createNew"})}/>
        </form>

        <ReactiveTable columnOrder={['note', 'createdBy', 'createdDate']} data={tableData} columnTemplate={getColumnTemplate()} columnStyle={{note: {header: {}, body: {wordWrap: "break-word", maxWidth: "250px"}}}}/>

    </>
};

export default Notes
