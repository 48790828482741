import {Calendar} from 'primereact/calendar'
import {ListBox} from 'primereact/listbox'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl';

interface Props {
    onDateChange: (dates: Date[]) => void;
    filterDate?: Date | Date[];
}

export const PeriodFilter: React.FC<Props> = (props) => {
    const [filterDate, setFilterDate] = useState<Date | Date[] | undefined>(props.filterDate);
    const [selectedListItem, setSelectedListItem] = useState(null);
    const { formatMessage: f } = useIntl();

    const dateOptions = [
        { value: "today",label: 'За днес', key: 'NY' },
        { value: "yesterday",label: 'От вчера', key: 'RM' },
        { value: "monday",label: 'От понеделник', key: 'LDN' },
        { value: "lastSevenDays",label: 'Последните 7 дни', key: 'IST' },
        { value: "month",label: 'За месеца', key: 'PRS' }
    ];

    useEffect(() => {
        let newDates = [new Date(), new Date()];
        if(selectedListItem === 'today') {
            newDates = [new Date(), new Date()];
        }else if(selectedListItem === 'yesterday') {
            newDates = [new Date(new Date().getTime() - 60*60*24*1000), new Date()];
        }else if(selectedListItem === 'monday') {
            newDates = [new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 6) % 7)), new Date()];
        }else if(selectedListItem === 'lastSevenDays'){
            newDates = [new Date(new Date().getTime() - 60*60*24*1000*7), new Date()];
        }else if(selectedListItem === 'month') {
            newDates = [new Date(new Date().setDate(1)), new Date()];
        }else if(!selectedListItem) return;
        setFilterDate(newDates);
    }, [selectedListItem]);

    useEffect(() => {
        //@ts-ignore
        if(filterDate && filterDate.length !== undefined && filterDate.length === 2) {
            //@ts-ignore
            props.onDateChange(filterDate)
        }
    }, [filterDate])

    const setDate = (e:any) => {
        setFilterDate(e.value);
        setSelectedListItem(null);
    }

    return <>

        <div className={"flex flex-row w-full"}>
            <ListBox value={selectedListItem} options={dateOptions} onChange={(e) => setSelectedListItem(e.value)}
                style={{ width: '15rem' }} listStyle={{ maxHeight: '250px' }}/>
            {/*@ts-ignore*/}
            <Calendar key={"newImei"} value={filterDate} inline onChange={setDate} dateFormat="yy-mm-dd" selectionMode="range" maxDate={new Date()}/>


        </div>
    </>
}
