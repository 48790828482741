import React from 'react';
import {useIntl} from "react-intl";
import {Button} from "primereact/button";
import {useDialog} from "../../hooks/useDialog";

interface Props {
    content: any
}

export const HomeTab: React.FC<Props> = (props) => {
    const { formatMessage : f } = useIntl();
    const {showDialog} = useDialog();


    return <>
        {props.content}
        <iframe width="100%" height="90%" src="https://miro.com/app/live-embed/uXjVKIo-C7E=/?moveToViewport=-2812,-2113,4217,2227&embedId=877826059153" frameBorder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowFullScreen></iframe>
    </>
}
