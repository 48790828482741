import {FormElementValues} from "@xal3xfx/react-utils";

const email: FormElementValues<'text'> = {
    type: "text",
    label: 'email',
    props: {},
}

const emailOwner: FormElementValues<'text'> = {
    type: "text",
    label: 'emailOwner',
    props: {},
}


export const formElements = {
    email,emailOwner
}
