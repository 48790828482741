import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import classes from "../../lib/styles/login/Login.module.css";
import {useFormik} from "formik";
import {Toast} from 'primereact/toast';
import {useIntl} from "react-intl";
import {ILoginFormData, ILoginFormDataErrors} from "../../lib/types/types";
import {AuthService} from "../../services/authService";
import {Password} from "primereact/password";
// @ts-ignore
import {classNames} from "primereact/utils";
import {useUserInfo} from "../../lib/hooks/useUserInfo"
import {PermissionContext} from "../../lib/context/PermissionsContext";
import {FloatLabel} from "primereact/floatlabel";
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";

export const Login = () => {
    const [formData, setFormData] = useState({});
    const wrongPasswordToast = useRef<any>(null);
    const {setPermissions} = useContext(PermissionContext);

    const {updateUserInfo} = useUserInfo();

    const {formatMessage: f} = useIntl();
    const authService = new AuthService();

    const initialValues: ILoginFormData = {
        username: "",
        password: ""
    }

    const formik = useFormik({
        initialValues,
        validate: (data) => {
            let errors: ILoginFormDataErrors = {};
            if (!data.username) {
                errors.username = "*Задължително поле";
            }

            if (!data.password) {
                errors.password = "*Задължително поле";
            }
            return errors;
        },
        onSubmit: (values: { username: string, password: string }) => {
            setFormData(values);

            authService.login(values).then(username => {
                updateUserInfo({authenticated: true, username});
                authService.checkIfAuthenticated().then((data) => {
                    setPermissions(data.permissions)
                }).catch(err => {

                });
            }).catch(err => {
                console.error(err);
                showWrongPasswordToast();
            });
        }
    });

    const showWrongPasswordToast = () => {
        if (wrongPasswordToast.current) {
            wrongPasswordToast.current.clear();
            wrongPasswordToast.current.show([
                {severity: 'error', detail: 'Грешно потребителско име или парола'}
            ]);
        }
    }

    const isFormFieldValid = (name: keyof ILoginFormData): boolean => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name: keyof ILoginFormData) => {
        return isFormFieldValid(name) &&
            <small className="p-error" style={{float: "left"}}>{formik.errors[name]}</small>;
    };

    return (
        <>
            <Toast position={"top-center"} ref={wrongPasswordToast}/>

            <div className={`${classes.loginWrapper} col-12`}>
                <Card
                    className={`col-10 sm:col-8 md:col-6 lg:col-4 xl:col-3`}
                    title="IG - Billing"
                    style={{textAlign: "center"}}
                >

                    <form onSubmit={formik.handleSubmit} className="w-full">

                        <div className="p-field mt-3">
                            <FloatLabel>
                                <IconField iconPosition="left">
                                    <InputIcon className="pi pi-user"> </InputIcon>
                                <InputText id="username" name="username" autoComplete={"username"}
                                           value={formik.values.username} onChange={formik.handleChange} autoFocus
                                           className={"w-full " + classNames({'p-error': isFormFieldValid('username')})}/>
                                </IconField>
                                <label htmlFor="username" style={{marginLeft: "1.5rem"}}
                                       className={classNames({'p-error': isFormFieldValid('username')})}>{f({id: "username"})}</label>
                                {getFormErrorMessage('username')}
                            </FloatLabel>
                        </div>
                        <div className="p-field mt-6 w-full">
                            <FloatLabel>
                                <Password feedback={false} autoComplete={"current-password"} toggleMask id="password"
                                          name="password" value={formik.values.password} onChange={formik.handleChange}
                                          className={"w-full " + classNames({'p-error': isFormFieldValid('password')})}
                                          pt={{
                                              showIcon: {tabIndex: "-1"},
                                              hideIcon: {tabIndex: "-1"},
                                              input: {style: {width: "100%"}}
                                          }}
                                />
                                <label htmlFor="password"
                                       className={classNames({'p-error': isFormFieldValid('password')})}>{f({id: "password"})}</label>

                            {getFormErrorMessage('password')}
                            </FloatLabel>
                        </div>

                        <Button
                            className={'mt-4 w-full'}
                            label={f({id: "login"})}
                            type={"submit"}
                            style={{marginTop: "10px"}}
                            iconPos="right"
                            icon="fas fa-sign-in-alt"
                        />
                    </form>
                </Card>
            </div>
        </>
    );
};

