import * as React from 'react';
import {useEffect, useRef} from 'react';

interface Props {
    pdfString: string
}

const PdfPreview : React.FC<Props> = props => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
        <object data={props.pdfString} type="application/pdf" width="100%" height="100%" style={{height: "2000px"}}>
            <p>Could not load PDF!</p>
        </object>
    </>
};

export default PdfPreview
