import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {InvoiceGroup, InvoiceGroupEmail} from "../../../lib/types/types";
import {HeaderButton, ReactiveTable} from "react-ive-tables";
import {Button} from "primereact/button";
import {InvoiceService} from "../../../services/invoiceService";
import {useConfirmDialog} from "../../../lib/hooks/useConfirmDialog";
import {useToast} from "../../../lib/hooks/useToast";
import CreateOrChooseFirmEmail from "./CreateOrChooseFirmEmail";

interface Props {
    selectedInvoiceGroup: InvoiceGroup;
}

const InvoiceGroupEmails: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    const invoiceService = new InvoiceService();
    const {showToast} = useToast();
    const {showConfirmDialog} = useConfirmDialog();

    const [showCreateOrChooseEmail, setShowCreateOrChooseEmail] = useState<boolean>(false);
    const [emails, setEmails] = useState<InvoiceGroupEmail[]>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if(props.selectedInvoiceGroup && props.selectedInvoiceGroup.invoiceGroupId) {
            fetchInvoiceGroupEmails();
        }
    }, [props.selectedInvoiceGroup])

    const getColumnTemplate = () => {
        return {
            delete: (rowData: InvoiceGroupEmail) => <Button severity={"danger"} icon={"pi pi-trash"}
                                                            onClick={() => confirmDeleteEmail(rowData)}/>
        }
    }

    const confirmDeleteEmail = (email: InvoiceGroupEmail) => {
        showConfirmDialog({body: f({id: 'confirmDeleteEmailAddress'}, {emailAddress: email.email})}).then((answer) => {
            if (answer) {
                invoiceService.deleteEmailFromInvoiceGroup(props.selectedInvoiceGroup.invoiceGroupId, email.emailAddressId).then(() => {
                    showToast("success", f({id: "done"}), f({id: "invoiceGroupEmailDeleted"}));
                    fetchInvoiceGroupEmails();
                }).catch()
            } else {
            }
        })
    }

    const showCreateClientFirm = () => {
        setShowCreateOrChooseEmail(true);
    }

    const headerButtons: HeaderButton[] = [
        {
            icon: 'pi pi-plus',
            className: 'mr-2',
            onClick: () => showCreateClientFirm(),
            tooltipLabel: f({id: 'add'})
        }
    ]

    const fetchInvoiceGroupEmails = () => {
        if(props.selectedInvoiceGroup) {
            invoiceService.getInvoiceGroupEmails(props.selectedInvoiceGroup.invoiceGroupId)
                .then(resp => {
                    setEmails(resp)
                }).catch(console.error)
        }

    }

    const doneCb = () => {
        setShowCreateOrChooseEmail(false);
        fetchInvoiceGroupEmails();
    }

    return <>
        {showCreateOrChooseEmail ?
            <>
                <Button label={f({id: "back"})} icon={"pi pi-arrow-left"} onClick={() => setShowCreateOrChooseEmail(false)}/>
                <CreateOrChooseFirmEmail clientFirmId={props.selectedInvoiceGroup.clientFirmId} invoiceGroupId={props.selectedInvoiceGroup.invoiceGroupId} doneCb={doneCb}/>
            </>
            :
            <>
                {
                    emails && emails.length > 0 ?

                        <ReactiveTable columnOrder={['email', 'emailOwner', 'delete']} showHeader={true}
                                       ignoreFilters={["delete"]} headerButtons={headerButtons}
                                       data={emails}
                                       columnTemplate={getColumnTemplate()}/>
                        :
                        <h2>{f({id: "noEmails"})}</h2>
                }
            </>
        }


    </>
};

export default InvoiceGroupEmails
