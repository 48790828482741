import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {InvoiceGroup, ITariff, IVehicle, VehicleInvoiceGroupTable} from "../../../../lib/types/types";
import {InvoiceService} from "../../../../services/invoiceService";
import {ReactiveTable} from "react-ive-tables";
import {CreateInvoiceGroup} from "../../../invoices/createInvoiceGroup/CreateInvocieGroup";
import {useDialog} from "../../../../lib/hooks/useDialog";
import {Card} from "primereact/card";
import {ClientFirmService} from "../../../../services/clientFirmService";
import {Button} from "primereact/button";
import {useConfirmDialog} from "../../../../lib/hooks/useConfirmDialog";
import {useToast} from "../../../../lib/hooks/useToast";
import {TariffsForFirm} from "../../../tariffs/TariffsForFirm";
import {TariffsForVehicle} from "../../../tariffs/TariffsForVehicle";
import {VehicleService} from "../../../../services/vehicleService";
import {TariffMultipleAssign} from "../../../tariffs/TariffMultipleAssign";
import {AssignComponent} from "../../../../lib/templates/AssignComponent";
import FirmVehicles from "./FirmVehicles";
import FirmInvoiceGroups from "./FirmInvoiceGroups";
import PreviewInvoiceGroup from "../../../invoices/createInvoiceGroup/PreviewInvoiceGroup";

interface Props {
    clientFirmId: number;
    clientFirmName: string;
}

const Invoicing: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const invoiceService = new InvoiceService();
    const vehicleService = new VehicleService();
    const clientFirmService = new ClientFirmService();
    const {showDialog, hideDialog} = useDialog();
    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();

    const [selectedVehicle, setSelectedVehicle] = useState<VehicleInvoiceGroupTable>()
    const [vehicleTableData, setVehicleTableData] = useState<IVehicle[]>([]);
    const [selectedVehicleDto, setSelectedVehicleDto] = useState<IVehicle>()
    const [selectedInvoiceGroup, setSelectedInvoiceGroup] = useState<InvoiceGroup>();
    const [clientFirmTariffsData, setClientFirmTariffsData] = useState<ITariff[]>([]);
    const [vehiclesForInvoiceGroup, setVehiclesForInvoiceGroup] = useState<VehicleInvoiceGroupTable[]>()
    const [vehiclesWithoutInvoiceGroup, setVehiclesWithoutInvoiceGroup] = useState<VehicleInvoiceGroupTable[]>();

    const [refreshTariffs, setRefreshTariffs] = useState<number>();
    const [refreshFirmVehiclesVar, setRefreshFirmVehiclesVar] = useState<number>();
    const [refreshInvoiceGroupsVar, setRefreshInvoiceGroupsVar] = useState<number>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        refreshInvoiceGroups();
        getAllTariffsForFirm();
        getClientFirmVehicles();
    }, [])

    useEffect(() => {
        if (!selectedInvoiceGroup) return;
        setSelectedVehicle(undefined);
        setSelectedVehicleDto(undefined)
        loadVehiclesForInvoiceGroup();

    }, [selectedInvoiceGroup])

    useEffect(() => {
        if (!selectedVehicle) return;
        vehicleService.getVehicleById(selectedVehicle.vehicleId).then(setSelectedVehicleDto)
    }, [selectedVehicle])

    useEffect(() => {
        if (!vehiclesWithoutInvoiceGroup || !vehiclesWithoutInvoiceGroup || !selectedInvoiceGroup) return;
        showDialog({
            newContent: <AssignComponent
                leftData={vehiclesWithoutInvoiceGroup!}
                rightData={vehiclesForInvoiceGroup!}
                selectionKey={"vehicleLpn"}
                submitCb={assignVehicles}
                leftTableTitle={f({id: "vehiclesWithoutGroup"})}
                rightTableString={f({id: "invoiceGroupVehicles"})}
                columnOrder={["vehicleLpn", "vehicleTable"]}
            />,
            heading: f({id: "administrateVehiclesForInvoiceGroup"}, {groupName: selectedInvoiceGroup.invoiceGroupName}),
            onHideCallback: () => setVehiclesWithoutInvoiceGroup(undefined)
        })
    }, [vehiclesForInvoiceGroup, vehiclesWithoutInvoiceGroup, selectedInvoiceGroup])

    const loadVehiclesForInvoiceGroup = () => {
        if (!selectedInvoiceGroup) return;
        clientFirmService.getVehiclesForInvoiceGroupAndClient(props.clientFirmId, selectedInvoiceGroup.invoiceGroupId)
            .then(resp => {
                setVehiclesForInvoiceGroup(resp)

            })
    }

    const loadFreeVehicles = () => {
        if (!selectedInvoiceGroup) return;
        clientFirmService.getAllVehiclesForGroupWithoutInvoiceGroup(props.clientFirmId)
            .then(resp => {
                setVehiclesWithoutInvoiceGroup(resp)

            })
    }

    const getAllTariffsForFirm = () => clientFirmService.getAllTariffsForFirm(props.clientFirmId).then(setClientFirmTariffsData);
    const getClientFirmVehicles = () => clientFirmService.getClientFirmVehicles(props.clientFirmId!).then(setVehicleTableData);

    const refreshInvoiceGroups = () => {
        setRefreshInvoiceGroupsVar(Date.now());
    }

    const showAddInvoiceGroup = () => {
        if (props.clientFirmId && props.clientFirmName)
            showDialog({
                newContent: <CreateInvoiceGroup clientFirmId={props.clientFirmId}
                                                clientFirmName={props.clientFirmName}/>,
                heading: f({id: "invoiceGroupsForFirm"}, {firmName: props.clientFirmName}),
                onHideCallback: refreshInvoiceGroups
            });
    }

    const removeVehicleFromGroup = () => {
        if (!selectedInvoiceGroup || !selectedVehicle) return;
        showConfirmDialog({
            body: f({id: "confirmRemoveVehicleFromInvoiceGroup"}, {
                invoiceGroupName: selectedInvoiceGroup?.invoiceGroupName,
                vehicleLpn: selectedVehicle.vehicleLpn
            })
        }).then(answer => {
            if (answer) {
                invoiceService.deleteVehiclesFromInvoiceGroup({
                    invoiceGroupId: selectedInvoiceGroup?.invoiceGroupId,
                    vehicleIds: [selectedVehicle?.vehicleId]
                })
                    .then(resp => {
                        if (resp) {
                            loadVehiclesForInvoiceGroup();
                            refreshFirmVehicles();
                            showToast("success", f({id: "done"}), f({id: "removeFromInvoiceGroupSuccess"}));
                        }
                    })
            }
        })
    }


    const deleteInvoiceGroup = () => {
        if (!selectedInvoiceGroup)
            return;

        showConfirmDialog({body: f({id: "confirmDeleteInvoiceGroup"}, {invoiceGroupName: selectedInvoiceGroup?.invoiceGroupName})}).then(answer => {
            if (answer) {
                invoiceService.deleteInvoiceGroup(selectedInvoiceGroup?.invoiceGroupId!).then(() => {
                    refreshInvoiceGroups();
                    setSelectedInvoiceGroup(undefined);
                    showToast("success", f({id: "done"}), f({id: "invoiceGroupDeletedSuccessfully"}));
                });

            }
        })
    }

    const markIncludeInvoiceNumbers = () => {
        if (!selectedInvoiceGroup)
            return;

        showConfirmDialog({body: f({id: "confirmIncludeInvoiceNumbers"}, {invoiceGroupName: selectedInvoiceGroup?.invoiceGroupName, warningMessage: <b>{f({id: "onlyOnePerFirm"})}</b>})}).then(answer => {
            if (answer) {
                invoiceService.markInvoiceGroupToIncludeInvoiceNumbers(selectedInvoiceGroup?.invoiceGroupId!).then(() => {
                    refreshInvoiceGroups();
                    setSelectedInvoiceGroup(undefined);
                    showToast("success", f({id: "done"}), f({id: "invoiceGroupMarkedToIncludeNumbersSuccessfully"}));
                });

            }
        })
    }

    const previewInvoiceGroup = () => {
        if(!selectedInvoiceGroup) return;

        showDialog({
            newContent: <PreviewInvoiceGroup clientFirmName={props.clientFirmName} selectedInvoiceGroup={selectedInvoiceGroup}/>,
            heading: <h3>{f({id: "previewInvoiceGroup"})}</h3>
        })
    }



    const multipleAssignCb = () => {
        setRefreshTariffs(Date.now());
        refreshFirmVehicles();
        getClientFirmVehicles();
    }

    const showMultipleAssign = () => {
        showDialog({
            newContent: <TariffMultipleAssign vehiclesData={vehicleTableData}
                                              clientFirmTariffsData={clientFirmTariffsData}
                                              hideMultipleAssign={hideDialog}
                                              callback={multipleAssignCb}/>,
            heading: <h3>Multiple Assign</h3>
        })
    }

    const showAddVehicles = () => {
        if (!selectedInvoiceGroup || !selectedInvoiceGroup.invoiceGroupId) return;
        loadVehiclesForInvoiceGroup();
        loadFreeVehicles();
    }

    const assignVehicles = (newVehiclesWithoutInvoiceGroup: VehicleInvoiceGroupTable[], newGroupVehicles: VehicleInvoiceGroupTable[]) => {
        const newGroupVehicleIds = newGroupVehicles.map(el => el.vehicleId);
        const vehiclesToDeleteIds = vehiclesForInvoiceGroup?.filter(el => !newGroupVehicleIds.includes(el.vehicleId)).map(el => el.vehicleId);

        const vehiclesToAddIds = newGroupVehicleIds.filter(el => !vehiclesForInvoiceGroup!.map(el => el.vehicleId).includes(el));

        if (vehiclesToAddIds && vehiclesToAddIds.length > 0) {
            invoiceService.addVehiclesToInvoiceGroup({
                invoiceGroupId: selectedInvoiceGroup?.invoiceGroupId!,
                vehicleIds: vehiclesToAddIds
            })
                .then(resp => {
                    if (resp) {
                        loadVehiclesForInvoiceGroup();
                        loadFreeVehicles();
                        refreshFirmVehicles();
                    }

                }).catch();
        }

        if (vehiclesToDeleteIds && vehiclesToDeleteIds.length > 0) {
            invoiceService.deleteVehiclesFromInvoiceGroup({
                invoiceGroupId: selectedInvoiceGroup?.invoiceGroupId!,
                vehicleIds: vehiclesToDeleteIds
            })
                .then(resp => {
                    if (resp) {
                        loadVehiclesForInvoiceGroup();
                        loadFreeVehicles();
                        refreshFirmVehicles();
                    }
                }).catch();
        }
    }

    const refreshFirmVehicles = () => {
        setRefreshFirmVehiclesVar(Date.now());
    }

    return <>
        <div className={"mt-6 grid flex align-items-stretch"}>

            <FirmVehicles clientFirmId={props.clientFirmId} refreshTable={refreshFirmVehiclesVar} selectVehicle={setSelectedVehicle}/>

            <div className={"col-12 md:col-8 md:pr-3 flex"}>
                <Card className={"border-round-3xl flex flex-column col-12 shadow-6"}>
                    <div className={"col-12 flex flex-row align-items-center flex-wrap"}>
                        <h2>{f({id: "invoiceGroups"})}</h2>
                        {selectedInvoiceGroup &&
                            <>
                                <div className={"ml-auto flex gap-2 mr-2"}>
                                    <Button className={"p-button-danger"} label={f({id: "deleteGroup"})}
                                            icon={"pi pi-trash"} iconPos={"right"}
                                            onClick={deleteInvoiceGroup}/>
                                </div>

                                <div className={"flex gap-2 mr-2"}>
                                    <Button className={"p-button-warning"}
                                            label={f({id: selectedInvoiceGroup.includeInvoiceCounts ? "makeGroupNotIncludeInvoiceNumbers" : "makeGroupIncludeInvoiceNumbers"})}
                                            icon={"fal fa-table"} iconPos={"right"}
                                            onClick={markIncludeInvoiceNumbers}/>
                                </div>

                                <div className={"flex gap-2 mr-2"}>
                                    <Button label={f({id: "preview"})}
                                            icon={"fal fa-magnifying-glass"} iconPos={"right"}
                                            onClick={previewInvoiceGroup}/>
                                </div>
                            </>


                        }
                        <Button className={selectedInvoiceGroup ? "" : "ml-auto"} label={f({id: "createGroup"})}
                                icon={"pi pi-plus"} iconPos={"right"}
                                onClick={showAddInvoiceGroup}/>
                    </div>
                    <FirmInvoiceGroups clientFirmId={props.clientFirmId} onSelect={setSelectedInvoiceGroup} refreshTable={refreshInvoiceGroupsVar}/>
                </Card>
            </div>

            <div className={"col-12 md:col-4 md:pl-3 mt-3 md:mt-0 flex"}>
                <Card className={"border-round-3xl flex flex-column col-12 shadow-6"}>
                    {selectedInvoiceGroup ?
                        <>
                            <div className={"col-12 flex flex-row align-items-center grid"}>
                                <h2 className={"col-12 xl:col-6"}>{f({id: "vehiclesForInvoiceGroup"}, {name: selectedInvoiceGroup.invoiceGroupName})}</h2>
                                <div className={"ml-auto flex col-12 xl:col-6 grid"}>
                                    <Button className={"col-6"} label={f({id: "administrate"})} icon={"pi pi-car"}
                                            iconPos={"right"}
                                            onClick={showAddVehicles}/>
                                    {selectedVehicle &&
                                        <Button className={"p-button-danger ml-2 col-5"} label={f({id: "delete"})}
                                                icon={"pi pi-trash"} iconPos={"right"}
                                                onClick={removeVehicleFromGroup}/>
                                    }
                                </div>

                            </div>
                            <ReactiveTable
                                data={vehiclesForInvoiceGroup}
                                columnOrder={['vehicleLpn', 'vehicleTable']}
                                ignoreFilters={['operations']}
                                selectionKey={'vehicleLpn'}
                                selectionMode={"single"}
                                setSelected={setSelectedVehicle}
                            />
                        </>
                        :
                        <h2>{f({id: "noSelectedInvoiceGroup"})}</h2>
                    }
                </Card>
            </div>

            <div className={"col-12"}>
                <Card className={"border-round-3xl shadow-6"}>
                    <h2>{f({id: "tariffs"})}</h2>
                    <div className="p-datatable-wrapper mt-3 p-shadow-4" style={{borderRadius: '1rem'}}>
                        {props.clientFirmId &&
                            <TariffsForFirm showMultipleAssign={showMultipleAssign}
                                            clientFirmName={props.clientFirmName!}
                                            clientFirmId={props.clientFirmId} clientFirmTariffs={clientFirmTariffsData}
                                            tariffCreatedOrUpdatedCallback={getAllTariffsForFirm}/>}
                        {(selectedVehicle && selectedVehicleDto) ?
                            <TariffsForVehicle selectedVehicle={selectedVehicleDto} firmTariffs={clientFirmTariffsData}
                                               refresh={refreshTariffs}
                                               clientFirmId={props.clientFirmId}/>
                            :
                            <h2>{f({id: "noVehicleSelected"})}</h2>
                        }
                    </div>
                </Card>
            </div>
        </div>
    </>
};

export default Invoicing
