import PrimeReact, { addLocale, locale } from "primereact/api";
import { UtilService } from "@xal3xfx/react-utils";
import * as Yup from "yup";

export const configureApp = (f: any) => {
    // PrimeReact.ripple = true;
    UtilService.setIntlFormatter(f);
    UtilService.setPrimeflexVersion(3);
    UtilService.setOptionValue('id');
    UtilService.setOptionLabel('description')
    locale("bg");

    Yup.setLocale({
        mixed: {
            required: (params) => f({ id: params.path.split('.').at(-1) }) + ' ' + f({id: 'isRequired'})
        },
        string: {
            length: (params) => f({ id: params.path.split('.').at(-1) }) + ' ' + f({id: 'shouldContainAtLeast'}) + ' ' + params.length + ' ' + f({id: 'symbols'}),
            email: (params) => f({ id: params.path.split('.').at(-1) }) + ' ' + f({id: 'emailShouldBeValid'}),
        },
        number: {
            positive: (params) => f({ id: params.path.split('.').at(-1) }) + ' '+ f({id: 'shouldBePositive'}),
            max: (params) => `${f({id: params.path})} ${f({id: "cantBeMoreThan"})} ${params.max}`,
            min: (params) => `${f({id: params.path})} ${f({id: "cantBeLessThan"})} ${params.min}`,
        },
    })

    addLocale('bg', {
        firstDayOfWeek: 1,
        dayNames: ["Неделя", "Понеделник", "Вторник", "Сряда", "Четвъртък", "Петък", "Събота"],
        dayNamesShort: ["Нд", "Пн", "Вт", "Ср", "Чет", "Пт", "Сб"],
        dayNamesMin: ["Н", "П", "В", "С", "Ч", "П", "С"],
        monthNames: ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"],
        monthNamesShort: ["Ян", "Фев", "Март", "Апр", "Май", "Юни", "Юли", "Авг", "Сеп", "Окт", "Ноем", "Дек"],
        today: 'Днес',
        clear: 'Изчисти',
        dateFormat: 'dd-mm-yy',
        weekHeader: 'Сед'
    });
}
